import GeoServer from '../../helpers/geoserver/GeoServer';

// initial state
export const state = () => ({
    allDrivers: [],
});

// getters
export const getters = {
    getDriver: state => id => {
        //console.log('Looking for ', id, state.allDrivers.length);
        return state.allDrivers.find(driver => driver.id == id);
    },
};

// actions
export const actions = {
    async getAllDrivers({ commit }) {
        const drivers = await GeoServer.getDrivers();
        const MaxNum = 1000;
        const coms = drivers.slice(0, MaxNum);
        commit('setDrivers', coms);
    },
    async getDriversCash({ commit, getters }, driversID) {
        const dev = getters.getDriver(driversID);
        if (!dev) {
            const devDB = await GeoServer.getDriver(driversID);
            commit('addDrivers', [devDB]);
            return devDB;
        }
        return dev;
    },
    async createDriver({ commit }, newDriverObj) {
        const driver = await GeoServer.createDriver(newDriverObj);
        commit('addDrivers', [driver]);
    },
    async updateDriverAsync({ commit }, updateDriverObj) {
        const driver = await GeoServer.updateDriver(
            updateDriverObj.id,
            updateDriverObj,
        );
        commit('updateDriver', [driver, updateDriverObj.id]);
    },
    async deleteDriverAsync({ commit }, deleteDriverObj) {
        const driver = await GeoServer.deleteDriver(
            deleteDriverObj.id,
            deleteDriverObj,
        );
        commit('deleteDriver', [driver, deleteDriverObj.id]);
    },
};

// mutations
export const mutations = {
    setDrivers(state, drivers) {
        state.allDrivers = drivers;
    },
    addDrivers(state, drivers) {
        drivers.forEach(g => {
            state.allDrivers.push(g);
        });
    },
    updateDriver(state, args) {
        const idx = state.allDrivers.findIndex(driver => driver.id == args[1]);
        if (idx != -1) {
            state.allDrivers[idx] = args[0];
        } else {
            state.allDrivers.push(args[0]);
        }
    },
    deleteDriver(state, args) {
        const idx = state.allDrivers.findIndex(driver => driver.id == args[1]);
        if (idx != -1) {
            state.allDrivers.splice(idx, 1);
        }
    },
};

// export default {
//   namespaced: true,
//   state,
//   getters,
//   actions,
//   mutations
// }
