import axios from '../api/index';
import moment from 'moment';

export default {
    /* GeoFences */
    async getGeoFences() {
        const res = await axios.get(
            `${process.env.VUE_APP_GEO_SERVER}geofences`,
        );

        // console.log(res.data)
        return res.data;
    },
    async getGeoFence(GeoFenceId) {
        const res = await axios.get(
            `${process.env.VUE_APP_GEO_SERVER}geofences/${GeoFenceId}`,
        );

        // console.log(res.data)
        return res.data;
    },
    async deleteGeoFence(GeoFenceId, GeoFenceObj) {
        let res = await axios.delete(
            process.env.VUE_APP_GEO_SERVER + `geofences/${GeoFenceId}`,
            GeoFenceObj,
        );
        return res.data;
    },
    async updateGeoFence(GeoFenceId, GeoFenceObj) {
        const res = await axios.put(
            `${process.env.VUE_APP_GEO_SERVER}geofences/${GeoFenceId}`,
            GeoFenceObj,
        );

        // console.log(res.data)
        return res.data;
    },
    async createGeoFence(GeoFenceObj) {
        GeoFenceObj.type = 'geofence';
        const res = await axios.post(
            `${process.env.VUE_APP_GEO_SERVER}geofences`,
            GeoFenceObj,
        );

        // console.log(res.data)
        return res.data;
    },
    async getLinkedGeoFences(DeviceId) {
        const res = await axios.get(
            `${
                process.env.VUE_APP_GEO_SERVER
            }geofences?_dc=${1}&deviceId=${DeviceId}`,
        ); // TODO NW: what is dc????
        return res.data;
    },
    /* Devices */
    async getDevices() {
        const res = await axios.get(`${process.env.VUE_APP_GEO_SERVER}devices`);

        // console.log(res.data)
        return res.data;
    },
    async getDevice(DeviceId) {
        if (DeviceId) {
            const res = await axios.get(
                `${process.env.VUE_APP_GEO_SERVER}devices/${DeviceId}`,
            );

            // console.log(res.data)
            return res.data;
        } else {
            return;
        }
    },
    async getPosition(positionId) {
        const res = await axios.get(
            `${process.env.VUE_APP_GEO_SERVER}positions/${positionId}`,
        );

        // console.log(res.data)
        return res.data;
    },
    async getZoomDevices(Radios_KM, Latitude, longitude, forceNoSum = '0') {
        if (isNaN(Radios_KM)) {
            Radios_KM = 15;
        }
        if (isNaN(Latitude)) {
            Latitude = 0;
        }
        if (isNaN(longitude)) {
            longitude = 0;
        }
        let res = await axios.get(
            process.env.VUE_APP_GEO_SERVER +
                `mapzoom/zoom/${Radios_KM}/${Latitude}/${longitude}?forceNoSum=${forceNoSum}`,
        );

        // console.log(res.data)
        return res.data;
    },
    async updateDevice(DeviceId, DeviceObj) {
        const res = await axios.put(
            `${process.env.VUE_APP_GEO_SERVER}devices/${DeviceId}`,
            DeviceObj,
        );

        // console.log(res.data)
        return res.data;
    },
    async deleteDevice(DeviceId, DeviceObj) {
        const res = await axios.delete(
            `${process.env.VUE_APP_GEO_SERVER}devices/${DeviceId}`,
            DeviceObj,
        );

        // console.log(res.data)
        return res.data;
    },
    async createDevice(DeviceObj) {
        DeviceObj.type = 'device';
        const res = await axios.post(
            `${process.env.VUE_APP_GEO_SERVER}devices`,
            DeviceObj,
        );

        // console.log(res.data)
        return res.data;
    },
    async getDeviceById(DeviceId) {
        const res = await axios.get(
            `${process.env.VUE_APP_GEO_SERVER}devices/${DeviceId}`,
        );

        // console.log(res.data)
        return res.data;
    },
    async getEventById(DeviceId) {
        const res = await axios.get(
            `${process.env.VUE_APP_GEO_SERVER}eventIos/${DeviceId}`,
        );

        // console.log(res.data)
        return res.data;
    },
    async findDevice(search) {
        let res = await axios.get(
            process.env.VUE_APP_GEO_SERVER +
                'devices/search?search=' +
                encodeURI(search),
        );

        // console.log(res.data)
        return res.data;
    },
    async sendCommand(DeviceId, CommandId, messageObj) {
        const res = await axios.post(
            `${process.env.VUE_APP_GEO_SERVER}devices/sendCommand/${DeviceId}/${CommandId}`,
            messageObj
        );
        return res.data;
    },
    async getDevicePermissions() {
        const res = await axios.get(
            `${process.env.VUE_APP_GEO_SERVER}permissions/`,
        );
        //console.log('getDevicePermissions', res);
        return res.data;
    },
    async linkGeoFenceToDevice(DeviceId, GeoFenceId) {
        const request = { deviceId: DeviceId, geofenceId: GeoFenceId };
        const res = await axios.post(
            `${process.env.VUE_APP_GEO_SERVER}permissions/`,
            request,
        );
        return res.data;
    },
    async unlinkGeoFenceFromDevice(DeviceId, GeoFenceId) {
        const request = {
            data: { deviceId: DeviceId, geofenceId: GeoFenceId },
        };
        const res = await axios.delete(
            `${process.env.VUE_APP_GEO_SERVER}permissions/`,
            request,
        );
        return res.data;
    },
    /* Commands */
    async getCommands() {
        const res = await axios.get(
            `${process.env.VUE_APP_GEO_SERVER}commands`,
        );

        return res.data;
    },
    async getCommandById(id) {
        const res = await axios.get(
            `${process.env.VUE_APP_GEO_SERVER}commands/${id}`,
        );

        return res.data;
    },
    async deleteCommand(CommandId, CommandObj) {
        const res = await axios.delete(
            `${process.env.VUE_APP_GEO_SERVER}commands/${CommandId}`,
            CommandObj,
        );

        return res.data;
    },
    async updateCommand(CommandId, CommandObj) {
        const res = await axios.put(
            `${process.env.VUE_APP_GEO_SERVER}commands/${CommandId}`,
            CommandObj,
        );

        // console.log(res.data)
        return res.data;
    },
    async createCommand(CommandObj) {
        CommandObj.type = 'command';
        const res = await axios.post(
            `${process.env.VUE_APP_GEO_SERVER}commands`,
            CommandObj,
        );

        // console.log(res.data)
        return res.data;
    },

    /* Events */
    async getEvents() {
        const res = await axios.get(
            `${process.env.VUE_APP_GEO_SERVER}eventIos`,
        );
        //console.log('List of all events', res.data)
        return res.data;
    },
    async deleteEvent(EventId, EventObj) {
        const res = await axios.delete(
            `${process.env.VUE_APP_GEO_SERVER}eventIos/${EventId}`,
            EventObj,
        );

        return res.data;
    },
    async updateEvent(EventId, EventObj) {
        const res = await axios.put(
            `${process.env.VUE_APP_GEO_SERVER}eventIos/${EventId}`,
            EventObj,
        );

        // console.log(res.data)
        return res.data;
    },
    async createEvent(EventObj) {
        EventObj.type = 'event';
        const res = await axios.post(
            `${process.env.VUE_APP_GEO_SERVER}eventIos`,
            EventObj,
        );

        return res.data;
    },
    /* Drivers */
    async getDrivers() {
        let res = await axios.get(process.env.VUE_APP_GEO_SERVER + 'drivers');

        //console.log(res.data)
        return res.data;
    },
    async deleteDriver(DriverId, DriverObj) {
        const res = await axios.delete(
            `${process.env.VUE_APP_GEO_SERVER}drivers/${DriverId}`,
            DriverObj,
        );

        return res.data;
    },
    async updateDriver(DriverId, DriverObj) {
        const res = await axios.put(
            `${process.env.VUE_APP_GEO_SERVER}drivers/${DriverId}`,
            DriverObj,
        );

        // console.log(res.data)
        return res.data;
    },
    async createDriver(DriverObj) {
        DriverObj.type = 'driver';
        const res = await axios.post(
            `${process.env.VUE_APP_GEO_SERVER}drivers`,
            DriverObj,
        );

        // console.log(res.data)
        return res.data;
    },

    /* Positions */
    async getPositions() {
        let res = await axios.get(process.env.VUE_APP_GEO_SERVER + 'positions');

        ///console.log(res.data)
        return res.data;
    },
    /* DeviceTrips */
    async getDeviceTrips(DeviceID, FromDate, ToDate) {
        let query = {
            deviceid: DeviceID,
            fromdate: moment(FromDate).format('YYYY-MM-DD'),
            todate: moment(ToDate).format('YYYY-MM-DD'),
        };
        let res = await axios.get(
            process.env.VUE_APP_GEO_SERVER + `deviceTrips/trip`,
            { params: query },
        );

        return res.data;
    },

    /* eventActions */
    async getEventActions() {
        let res = await axios.get(
            process.env.VUE_APP_GEO_SERVER + 'eventActions',
        );
        //console.log(res.data)
        return res.data;
    },
    async getEventActionById(id) {
        let res = await axios.get(
            `${process.env.VUE_APP_GEO_SERVER}eventActions/${id}`,
        );
        //console.log(res.data)
        return res.data;
    },
    async deleteEventAction(EventActionId, EventActionObj) {
        const res = await axios.delete(
            `${process.env.VUE_APP_GEO_SERVER}eventActions/${EventActionId}`,
            EventActionObj,
        );

        return res.data;
    },
    async updateEventAction(EventActionId, EventActionObj) {
        const res = await axios.put(
            `${process.env.VUE_APP_GEO_SERVER}eventActions/${EventActionId}`,
            EventActionObj,
        );

        // console.log(res.data)
        return res.data;
    },
    async createEventAction(EventActionObj) {
        EventActionObj.type = 'eventAction';
        const res = await axios.post(
            `${process.env.VUE_APP_GEO_SERVER}eventActions`,
            EventActionObj,
        );

        // console.log(res.data)
        return res.data;
    },
    async getAddress(positionId) {
        let res = await axios.get(
            process.env.VUE_APP_GEO_SERVER + `geocoding/position/${positionId}`,
        );

        // console.log(res.data)
        return res.data;
    },
    // async createUserPosition(userId,latitude,longitude,entityId="00000000-0000-0000-0000-000000000000",entityType="",battery="") {
    //     let data = {};
    //     let params = {userId,latitude,longitude,entityId,entityType,battery};
    //     params.deviceTime= new Date();
    //     let res = await axios.post(process.env.VUE_APP_GEO_SERVER + `usertraking`,
    //                                 data,
    //                                 { params: params});

    //     // console.log(res.data)
    //     return res.data;
    // },
    /* DeviceTrips */
    async getUserTrips(userId, FromDate, ToDate) {
        let query = {
            userId: userId,
            fromdate: moment(FromDate).format('YYYY-MM-DD'),
            todate: moment(ToDate).format('YYYY-MM-DD'),
        };
        let res = await axios.get(
            process.env.VUE_APP_GEO_SERVER + `usertraking/trip`,
            { params: query },
        );

        return res.data;
    },
    // async getDevicePoints(DeviceID,FromDate,ToDate) {
    //   //deviceid=d895bc93-cfdd-40bd-b8fd-054226820f63&fromdate=2022-04-11%2012:00:00&todate=2022-04-12%2000:00:00
    //   let query = {
    //     "deviceid":DeviceID,
    //     "fromdate":moment(FromDate).format("yyyy-mm-dd"),
    //     "todate":moment(ToDate).format("yyyy-mm-dd")
    //   }
    //   let res = await axios.get(process.env.VUE_APP_GEO_SERVER + "deviceTrips/trip",query);
    //   //console.log(res.data)
    //   return res.data;
    // },

    // async storeDevice({ commit }, DeviceId) {
    //   commit('setDeviceIsCreating', true);
    //   await axios.post(`${process.env.VUE_APP_GEO_SERVER}devices`, DeviceId)
    //     .then(res => {
    //       commit('saveNewDevice', res.data.data);
    //       commit('setDeviceIsCreating', false);
    //     }).catch(err => {
    //       console.log('error', err);
    //       commit('setDeviceIsCreating', false);
    //     });
    // },
    // async updateDevice({ commit }, DeviceId) {
    //   commit('setDeviceIsUpdating', true);
    //   commit('setDeviceIsUpdating', true);
    //   await axios.post(`${process.env.VUE_APP_GEO_SERVER}${DeviceId}?_method=PUT`, DeviceId)
    //     .then(res => {
    //       commit('saveUpdatedDevice', res.data.data);
    //       commit('setDeviceIsUpdating', false);
    //     }).catch(err => {
    //       console.log('error', err);
    //       commit('setDeviceIsUpdating', false);
    //     });
    // },

    //   updateDeviceDetails({ commit }, e) {
    //   commit('setDeviceDetails', e);
    //   },
    // async deleteDevice({ commit }, DeviceId) {

    //   await axios.delete(`${process.env.VUE_APP_GEO_SERVER}${DeviceId}`)
    //     .then(res => {
    //       commit('setDeleteDevice', res.data.data.DeviceId);
    //       commit('setDeviceIsDeleting', false);
    //     }).catch(err => {
    //       console.log('error', err);
    //       commit('setDeviceIsDeleting', false);
    //     });
    // },
    // // mutations

    //    setDevice: (state, DeviceId) => {
    //    state.DeviceId = DeviceId
    //   },

    //   saveNewDevice: (state, DeviceId) => {
    //     state.DeviceId.unshift(DeviceId)
    //     state.createdData = DeviceId;
    //   },
    //   setDeviceIsCreating(state, isCreating) {
    //     state.isCreating = isCreating
    //   },

    //   setDeviceIsUpdating(state, isUpdating) {
    //     state.isUpdating = isUpdating
    //   },

    //   setDeviceIsDeleting(state, isDeleting) {
    //     state.isDeleting = isDeleting
    //   },
};
