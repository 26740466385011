export const state = {
    type: null,
    message: null,
};

export const mutations = {
    reportComplete(state, message) {
        state.type = message.success ? 'success' : 'error';
        state.message = message;
    }, 
    clear(state) {
        state.type = null;
        state.message = null;
    } 
};

export const actions = {
    reportComplete({ commit }, message) {
        commit('reportComplete', message);
    }, 
    clear({ commit }) {
        commit('clear');
    } 
};
