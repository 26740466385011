import GeoServer from '../../helpers/geoserver/GeoServer';
import { geoDeviceService } from '../../services/geo.device.service';
import { geoAssetService } from '../../services/geo.asset.service';
import {
    cleanPositionsData,
} from '../../helpers/mapHelpers';

const TRACKING_MAP_SHOW_GEO_FENCE = 'tracking_map_show_geo_fence';

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

// initial state
export const state = () => ({
    all: [],
    selected: '',
    selectedAsset: null,
    deviceDetails: null,
    selectedAssetDeviceType: 'Primary',
    showGeoFence: false,
    assetTripsReports: {},
    positions: {
        meta: null,
        data: [],
    },
    assetDetails: {
        show: false,
        tabs: [
            { id: 1, title: 'Asset Details', active: true },
            { id: 2, title: 'Alerts', active: false },
            { id: 3, title: 'Events', active: false },
            { id: 4, title: 'Trips', active: false },
            { id: 5, title: 'Info', active: false },
            { id: 6, title: 'Crash', active: false },
            { id: 7, title: 'Edit', active: false },
            { id: 8, title: 'Share', active: false },
        ],
    },
    directions: {
        waypoints: [],
        showDetails: false,
    },
});

// getters
export const getters = {
    getDevice: state => id => {
        return state.all.find(device => device.Id == id);
    },
    getDevicesCount: state => () => {
        // console.log("Looking for ",id,state.all.length)
        return state.all.length;
    },

    getSelectedDevice: state => () => {
        // console.log("Looking for ",id,state.all.length)
        return state.selected;
    },
    getDevicePosition: state => id => {
        var idx = state.all.findIndex(x => x.Id == id);
        if (idx != -1) {
            return state.all[idx].coords;
        }
        return [];
    },
    getAssetInfo: (state) => (type) => {
        return Object.values(state.selectedAsset.devices).find(device => device.devicetypename === type);
    },
    getAssetDevice: (state) => () => {
        if (!state.selectedAsset || !state.selectedAsset.devices) return null;
        let type = state.selectedAssetDeviceType === 'Beacon' ? 'Bluetooth Transponder' : state.selectedAssetDeviceType;
        return Object.values(state.selectedAsset.devices).find(device => {
            return device.devicetypename === type && device.latitude !== null && device.longitude !== null;
        });
    },
    getCurrentAssetDetailsTab: (state) => () => {
        return state.assetDetails.tabs.find(tab => tab.active === true);
    },
};

// actions
export const actions = {
    async setSelectedDevice({ state, commit, dispatch }, assetInfoId) {
        // clear any directions waypoints
        commit('setDirections', { waypoints: [] });

        commit('setSelectedDevice', assetInfoId);
        const selectedAssetDetails = state.all.find(x => x.Id === assetInfoId);
        if (selectedAssetDetails && selectedAssetDetails.devices) {
            const devices = Object.values(selectedAssetDetails.devices);
            if (devices.length > 0 && devices[0].uniqueId) {
                commit('setSelectedAsset', selectedAssetDetails);
                return selectedAssetDetails;
            }
        }

        await dispatch('getAssetDevices', assetInfoId);
        dispatch('fetchAssetTripsReports', { assetId: assetInfoId, showZeros: true });
    },
    async getAssetDevices({ commit }, assetId) {
        const devices = await geoDeviceService.getAssetDevices(assetId);
        commit('setAssetDevicesDetails', [assetId, devices.data.assetDevices, devices.data.assets]);
    },
    async getAllDevices({ commit }) {
        const devices = await geoDeviceService.getAllDevices();
        const newDeviceData = devices.data.geoDevices.map(x => {
            x.name = x.registrationNumber + " - " + x.make;
            x.devices = {};
            if (x.latitude && x.longitude) {
                x.coords = [x.latitude, x.longitude];
            } else {
                x.coords = [];
            }
            return x;
        });
        commit('setDevices', newDeviceData);
        return newDeviceData;

    },
    haveDevices({ getters }) {
        return getters.getDevicesCount() > 0 ? true : false;
    },
    getDevicePosition({ getters }, id) {
        return getters.getDevicePosition(id);
    },
    getSelectedDevice({ getters }) {
        return getters.getSelectedDevice();
    },
    getDevice({ getters }, deviceId) {
        return getters.getDevice(deviceId);
    },
    async fetchAssetTripsReports({ state, commit }, payload) {
        // if data with same payload was cached, return cached data, otherwise pull from api.
        const { assetId, startDate, endDate, showZeros, page, size, sortField, sortOrder } = payload;
        const cached = state.assetTripsReports[assetId];
        if (!cached || (JSON.stringify(payload) !== JSON.stringify(cached.payload))) {
            const { data } = await geoAssetService.getAssetTripReports(assetId, startDate, endDate, showZeros, page, size, sortField, sortOrder);
            if (data.data.trips.length > 0) {
                commit('setAssetTripsReports', { assetId, payload, result: data });
            }
            return data;
        } else {
            return cached.result;
        }
    },
    async fetchAssetTrips({ state, commit }, payload) {
        const { data } = await geoAssetService.getAssetTrips(payload);
        return data;
    },
    async fetchAssetPositions({ commit }, payload) {
        const { deviceId, startDate, endDate, page, size, sortField, sortOrder, search } = payload;
        const { data } = await geoDeviceService.getDeviceFullPositionReport(deviceId, startDate, endDate, page, size, sortField, sortOrder, search);
        const positions = cleanPositionsData(data.data.position);
        commit('setAssetPositions', { meta: data.meta, data: positions });
    },
    async fetchDeviceDetails({ commit }, deviceId) {
        const { data } = await geoDeviceService.getDeviceById(deviceId);
        commit('setDeviceDetails', data.device);
    },
};

// mutations
export const mutations = {
    setDevices(state, devices) {
        state.all = devices;
    },
    setAssetDevicesDetails(state, aAssetDevice) {

        if (aAssetDevice[1].length == 0) {
            return;
        }
        const idx = state.all.findIndex(x => x.Id == aAssetDevice[0]);
        const AssetDetails = state.all[idx]
        if (AssetDetails && !AssetDetails.devices) {
            AssetDetails.devices = {};
        }
        if (AssetDetails) {
            for (let index = 0; index < aAssetDevice[1].length; index++) {
                const device = aAssetDevice[1][index];
                if (AssetDetails.devices[device.deviceId]) {
                    AssetDetails.devices[device.deviceId] = Object.assign(AssetDetails.devices[device.deviceId], device);
                } else {
                    AssetDetails.devices[device.deviceId] = device;
                }
                AssetDetails.devices[device.deviceId].coords = [device.latitude, device.longitude];
                if (device.attributes) {
                    AssetDetails.devices[device.deviceId].telemetry = device.attributes
                        .map(x => {
                            if (x.v) {
                                return { "value": x.v, "type": x.t }
                            }
                            return x;
                        });
                } else {
                    AssetDetails.devices[device.deviceId].telemetry = [];
                }

            }
            state.all[idx].features = aAssetDevice[2].features;
            state.all[idx].photos = aAssetDevice[2].photos;
            state.all[idx].engineHours = aAssetDevice[2].engineHours;
            state.all[idx].lastOdometer = aAssetDevice[2].lastOdometer;

            state.selectedAsset = AssetDetails;
        }
    },
    updateDevicePosition(state, args) {
        if (state.all.length > 0) {
            let idx = state.all.findIndex(device => device.Id == args[0]);

            if (idx != -1) {
                if (state.all[idx].devices[args[1].id]) {
                    state.all[idx].devices[args[1].id] = Object.assign(state.all[idx].devices[args[1].id], args[1]);
                } else {
                    state.all[idx].devices[args[1].id] = Object.assign({ name: "", uniqueId: "" }, args[1]);
                }
            } else {
                debugger;
            }
        } else {
            console.error("No Data in state");
        }
    },
    updateAssetPosition(state, args) {
        if (state.all.length > 0) {
            //console.log("Update DB with ",args[0]);
            let idx = state.all.findIndex(device => device.Id === args[0]);

            if (idx !== -1) {
                if (args[1].coords) state.all[idx].coords = args[1].coords;
                if (args[1].status) state.all[idx].status = args[1].status;
                if (args[1].lastUpdate)
                    state.all[idx].lastUpdate = args[1].lastUpdate;
                if (args[1].deviceTime)
                    state.all[idx].deviceTime = args[1].deviceTime;
                if (args[1].telemetry) {
                    state.all[idx].telemetry = args[1].telemetry;
                }
                if (args[1].address) {
                    state.all[idx].address = args[1].address;
                }
                if (state.all[idx].devices[args[1].id]) {
                    state.all[idx].devices[args[1].id] = Object.assign(state.all[idx].devices[args[1].id], args[1]);
                } else {
                    state.all[idx].devices[args[1].id] = Object.assign({ name: "", uniqueId: "" }, args[1]);
                }
            } else {
                debugger;
            }
        } else {
            console.error("No Data in state");
        }
    },
    setSelectedDevice(state, assetInfoId) {
        // set selected asset id
        state.selected = assetInfoId;

    },
    setShowGeoFence(state, show) {
        state.showGeoFence = show;
        localStorage.setItem(TRACKING_MAP_SHOW_GEO_FENCE, JSON.stringify({ "geoFence": show }));
    },
    getShowGeoFence(state) {
        let show = localStorage.getItem(TRACKING_MAP_SHOW_GEO_FENCE);
        if (show) {
            try {
                let data = JSON.parse(show);
                state.showGeoFence = data.geoFence;
            } catch (e) {
                state.showGeoFence = true;
                localStorage.setItem(TRACKING_MAP_SHOW_GEO_FENCE, JSON.stringify({ "geoFence": true }));
            }
        } else {
            state.showGeoFence = true;
            localStorage.setItem(TRACKING_MAP_SHOW_GEO_FENCE, JSON.stringify({ "geoFence": true }));
        }
    },
    setShowAssetDetails(state, show) {
        state.assetDetails.show = show;
    },
    setAssetTripsReports(state, data) {
        const { assetId, payload, result } = data;
        state.assetTripsReports[assetId] = {
            payload,
            result,
        };
    },
    setAssetInfo(state, info) {
        state.assetInfo.sim = 'N/A';  // TODO: field to added to api
        state.assetInfo.status = info.status;
        state.assetInfo.imei = info.imei;
    },
    setAssetPositions(state, positions) {
        state.positions = positions;
    },
    setAssetDetailsShow(state, show) {
        state.assetDetails.show = show;
    },
    setAssetDetailsTab(state, tabId) {
        state.assetDetails.tabs = state.assetDetails.tabs.map(tab => {
            if (tab.id === tabId) {
                return {
                    ...tab,
                    active: true,
                }
            } else {
                return {
                    ...tab,
                    active: false,
                }
            }
        });
    },
    setSelectedAssetDeviceType(state, type) {
        state.selectedAssetDeviceType = type;
        // await dispatch('fetchDeviceDetails', 'add8e8a7-7e31-430f-be7e-b0e8d32f354a');
    },
    setSelectedAsset(state, asset) {
        state.selectedAsset = asset;
    },
    setDeviceDetails(state, details) {
        state.deviceDetails = details;
    },
    setDirections(state, directions) {
        state.directions = directions;
    }
};
