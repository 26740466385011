export function getDeviceAttribute(attributes, type) {
    if (!attributes) return null;
    let attribute = attributes.find(atr => atr.type == type || atr.t == type);
    if (!attribute) {
        return null;
    }

    return attribute.value ? attribute.value : attribute.v;
}
export function cleanPositionsData(positions) {
    let lastIgnition = null;
    return positions.map((x, index, arr) => {
        x.Geo = x.latitude + ',' + x.longitude;
        x.event = 'Position Report';
        if (lastIgnition != getDeviceAttribute(x.attributes, 'ignition')) {
            if (getDeviceAttribute(x.attributes) != null) {
                lastIgnition = getDeviceAttribute(x.attributes, 'ignition');
            }
            if (getDeviceAttribute(x.attributes, 'ignition') == '1') {
                x.event = 'Ignition On';
            } else {
                x.event = 'Ignition Off';
            }
        }
        if (getDeviceAttribute(x.attributes, 'alarm')) {
            x.event = 'Alarm ' + getDeviceAttribute(x.attributes, 'alarm');
        }
        x.odometer = getDeviceAttribute(x.attributes, 'odometer');
        x.Satellites = getDeviceAttribute(x.attributes, 'sat');
        return x;
    });
}
export function isViolation(value) {
    const violations = [
        'accident',
        'crash',
        'gpsAntennaCut',
        'hardAcceleration',
        'hardBraking',
        'hardCornering',
    ];

    return violations.includes(value);
}
export function isAlert(value) {
    const alerts = [
        'geofenceEnter',
        'geofenceExit',
        'overspeed',
    ];
    return alerts.includes(value);
}
