import PouchDB from 'pouchdb';
import _ from 'lodash';
// import PouchDBUpsert from 'pouchdb-upsert';
// import PouchDBFind from 'pouchdb-find';

// PouchDB.plugin(PouchDBUpsert);
// PouchDB.plugin(PouchDBFind);

let databaseId;

var userDB, globalsDB, authDB;
var syncing = {};

//TODO: Temporarily Disabled, renable to get it working when couchDB is on HTTPS via load balancers
if (
    process.env.VUE_APP_COUCHDB_URL &&
    process.env.VUE_APP_COUCHDB_USERNAME &&
    process.env.VUE_APP_COUCHDB_PASSWORD
) {
    const remoteGlobalsDB = new PouchDB(
        `https://${process.env.VUE_APP_COUCHDB_USERNAME}:${process.env.VUE_APP_COUCHDB_PASSWORD}@${process.env.VUE_APP_COUCHDB_URL}/${process.env.VUE_APP_USERDB_PREFIX}_globals`,
    );
    globalsDB = new PouchDB(process.env.VUE_APP_USERDB_PREFIX + '_globals');

    /************************************************************************************ */
    //This can be removed on future releases past version 1.0.0
    //Clean Up old DB's
    // let databaseId = 'globals';
    // try {
    //     let oldUserDb = new PouchDB(`dev_${databaseId}`);
    //     oldUserDb.destroy();
    // }
    // catch (error) {
    // }
    // try {
    //     let oldUserDb = new PouchDB(`qa_${databaseId}`);
    //     oldUserDb.destroy();
    // }
    // catch (error) {
    // }
    // try {
    //     let oldUserDb = new PouchDB(`prod_${databaseId}`);
    //     oldUserDb.destroy();
    // }
    // catch (error) {
    // }

    // databaseId = 'auth';
    // try {
    //     let oldUserDb = new PouchDB(`dev_${databaseId}`);
    //     oldUserDb.destroy();
    // }
    // catch (error) {
    // }
    // try {
    //     let oldUserDb = new PouchDB(`qa_${databaseId}`);
    //     oldUserDb.destroy();
    // }
    // catch (error) {
    // }
    // try {
    //     let oldUserDb = new PouchDB(`prod_${databaseId}`);
    //     oldUserDb.destroy();
    // }
    // catch (error) {
    // }

    /************************************************************************************ */

    //start the sync
    syncDB(globalsDB, remoteGlobalsDB);
    //resetAuth();

    refreshUserDb();
}

authDB = new PouchDB(process.env.VUE_APP_USERDB_PREFIX + '_auth');

async function resetAuth() {
    authDB.destroy().then(() => {
        console.log('***** POUCHDB Init: destroyed and re-creating authDB')
        authDB = new PouchDB(process.env.VUE_APP_USERDB_PREFIX + '_auth');
    })
}

async function syncDB(localDB, remoteDB, resetTimeout = 360000) {
    const user = JSON.parse(localStorage.getItem('user')) || { Id: '' };


    let found = (_.findIndex(user.roles, { name: 'Team Leader' }) > -1 ||
        _.findIndex(user.roles, { name: 'Loan Officer' }) > -1 ||
        _.findIndex(user.roles, { name: 'Branch Manager' }) > -1 ||
        _.findIndex(user.roles, { name: 'Regional Manager' }) > -1)

    if (navigator.onLine && !syncing[localDB.name] && found) {
        syncing[localDB.name] = true;
        localDB
            .sync(remoteDB)
            .on('complete', info => {
                console.log(
                    '***** POUCHDB Complete: Handling syncing complete',
                    localDB.name,
                );
                console.dir(info);
                //Sync Completed, reset for next attempt
                setTimeout(function () {
                    syncDB(localDB, remoteDB);
                }, resetTimeout);
                syncing[localDB.name] = false;
                console.log(
                    '***** POUCHDB reset for',
                    resetTimeout,
                    'on',
                    localDB.name,
                );
            })
            .on('change', info => {
                console.log(
                    '***** POUCHDB Change: Handling syncing change',
                    localDB.name,
                );
                console.dir(info);
            })
            .on('paused', info => {
                console.log(
                    '***** POUCHDB Paused: Handling syncing pause',
                    localDB.name,
                );
                console.dir(info);
            })
            .on('active', info => {
                console.log(
                    '***** POUCHDB Active: Handling syncing resumption',
                    localDB.name,
                );
                console.dir(info);
            })
            .on('error', err => {
                console.log(
                    '***** POUCHDB Error: Handling syncing error',
                    localDB.name,
                );
                console.dir(err);
                //Error occurred, reset for next attempt
                setTimeout(function () {
                    syncDB(localDB, remoteDB);
                }, 1000 * 60); //wait 1 minute and try again
                syncing[localDB.name] = false;
            })
            .on('denied', err => {
                console.log(
                    '***** POUCHDB Denied: Handling syncing denied',
                    localDB.name,
                );
                console.dir(err);
                syncing[localDB.name] = false;
            });
    } else {
        //wait 15 minutes and try again
        if (!syncing[localDB.name] && found) {
            setTimeout(function () {
                syncDB(localDB, remoteDB);
            }, 1000 * 16 * 15);
            syncing[localDB.name] = false;
        }
        else {
            console.log(
                '***** POUCHDB No Synch Required for this User Role.',
                localDB.name,
            );
        }
    }
}

async function refreshUserDb() {
    const user = JSON.parse(localStorage.getItem('user')) || { Id: '' };


    //console.log('***** POUCHDB Init: begin setup for userDB', user.Id)
    if (user && user.Id != '') {
        databaseId = user.Id || '';
        const databaseName = `user_${process.env.VUE_APP_USERDB_PREFIX}_${databaseId}`;



        if (
            databaseId !== '' &&
            process.env.VUE_APP_COUCHDB_URL &&
            process.env.VUE_APP_COUCHDB_USERNAME &&
            process.env.VUE_APP_COUCHDB_PASSWORD
        ) {
            const remoteDB = new PouchDB(
                `https://${process.env.VUE_APP_COUCHDB_USERNAME}:${process.env.VUE_APP_COUCHDB_PASSWORD}@${process.env.VUE_APP_COUCHDB_URL}/${databaseName}`,
            );
            try {
                let oldUserDb = new PouchDB(`user_dev_${databaseId}`);
                oldUserDb.destroy();
            }
            catch (error) {
                console.log('***** POUCHDB Init: no old userDB to destroy', error)
            }
            try {
                let oldUserDb = new PouchDB(`user_qa_${databaseId}`);
                oldUserDb.destroy();
            }
            catch (error) {
                console.log('***** POUCHDB Init: no old userDB to destroy', error)
            }
            try {
                let oldUserDb = new PouchDB(`user_prod_${databaseId}`);
                oldUserDb.destroy();
            }
            catch (error) {
                console.log('***** POUCHDB Init: no old userDB to destroy', error)
            }

            userDB = new PouchDB(databaseName);
            //DESTROY OLD USER DATABASE
            syncDB(userDB, remoteDB);
            return userDB;
        }
    }
    return undefined;
}

export default { userDB, globalsDB, authDB, refreshUserDb };
