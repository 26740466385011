import axios from '../helpers/api';
import db from '@/services/pouchDb';
import _ from 'lodash';
import helper from '../helpers/custom/index';
import { v4 as uuidv4 } from 'uuid';
import { DateTime } from "luxon";

const { version } = require('./../../package.json');
const user = JSON.parse(localStorage.getItem('user'));

export const geoAssetService = {
    getAllAssets,
    getAssetDeviceUsageReport,
    getAssetTamperReport,
    getAssetById,
    getAssetGroups,
    getAssetGroup,
    getDeviceTypes,
    getAssetDeviceList,
    updateAsset,
    insertAsset,
    getAssetDetailsById,
    getAssetTrips,
    getAssetTripReports,
    getHotSpotReport,
    getAssetStopReports,
    getAssetEngineReports,
    getPositions,
    updateAssetGroup,
    insertAssetGroup,
    deleteAssetGroup,
    getAssetCategories,
    getAssetCategory,
    updateAssetCategory,
    insertAssetCategory,
    deleteAssetCategory,
    getAssetAlerts,
    getAssetEvents,
    updateAssetOdometer,
    updateAssetEngineHours,
    updateAssetMapIcon,
    getAssetCrashes,
    getAssetShareLinks,
    insertAssetShareLink,
    updateAssetShareLink
};

async function updateAsset(id, asset_infoRow, asset_devicesArray) {
    const data = Object.assign(asset_infoRow, { asset_devices: asset_devicesArray })
    if (navigator.onLine) {
        const res = await axios.put(`api/geo/asset/${id}`, data);
        return res.data;
    } else {
        try {
            if (!db.userDB || _.isEmpty(db.userDB)) {
                db.refreshUserDb();
            }

            const doc = await db.userDB.get(id);
            await db.userDB.put(
                {
                    _id: id,
                    _rev: doc._rev,
                    asset_info: data,
                },
                { force: true },
            );

            let changeId = uuidv4();
            await db.globalsDB.put({
                _id: changeId,
                change: {
                    version: version,
                    entityId: id,
                    userId: helper.getUserId(),
                    entityType: 'asset_info',
                    dateModified: DateTime.now().toISO(),
                    changeType: 'UPDATE',
                },
            });

            return {
                data: {},
                result: {
                    success: true,
                    message: 'Asset updated successfully',
                },
            };
        } catch (error) {
            //console.log("update Activity error ", error);
        }
    }
}
async function insertAsset(asset_infoRow, asset_devicesArray) {
    const data = Object.assign(asset_infoRow, { asset_devices: asset_devicesArray })
    data['Id'] = helper.checkId(data.Id) ? data.Id : uuidv4();
    if (navigator.onLine) {

        const res = await axios.post('api/geo/asset/', data);
        return res.data;
    } else {
        try {
            if (!db.userDB || _.isEmpty(db.userDB)) {
                db.refreshUserDb();
            }
            await db.userDB.put({
                _id: data.Id,
                asset_info: data,
            });
            let activity_global = uuidv4();
            await db.globalsDB.put({
                _id: activity_global,
                change: {
                    version: version,
                    entityId: data.Id,
                    userId: helper.getUserId(),
                    entityType: 'asset_info',
                    dateModified: DateTime.now().toISO(),
                    changeType: 'INSERT',
                },
            });

            let masterId = uuidv4();
            let master_status = {
                Id: masterId,
                itemId: data.Id,
                itemType: 'asset_info',
                dateCreated: DateTime.now(),
                status: data.status || 'PENDING',
            };

            await db.userDB.put({
                _id: masterId,
                status: master_status,
            });
            return { data: { activity: { Id: data.Id } } };
        } catch (error) {
            console.log(error);
        }
    }
}
async function getAllAssets(page, size, sortField = 'vehicle.registrationNumber', sortOrder, search, startDate = null, endDate = null, assetStatus = 'ALL', assetGroup = null, assetCategory = null) {
    page = page || 1;
    size = size || 10000;
    sortField = sortField || 'dateCreated';
    sortOrder = sortOrder || 'DESC';
    search = search || '';
    search = search.toUpperCase().trim();
    assetStatus = assetStatus || '';
    assetGroup = assetGroup || null;
    assetCategory = assetCategory || null;

    if (navigator.onLine) {

        const res = await axios.get(
            `api/geo/asset/?page=${page}&size=${size}&sortField=${sortField}&sortOrder=${sortOrder}&search=${search}&startDate=${startDate}&endDate=${endDate}&assetStatus=${assetStatus}&assetGroup=${assetGroup}&assetCategory=${assetCategory}`
        );
        return res.data;
    } else {
        //ToDo

    }
}

async function getAllAssetInfo() {
    if (navigator.onLine) {
        const res = await axios.get(`api/geo/asset/info`);
        return res.data;
    } else {
        // TODO
    }
}

//Should use existing function
// async function getAssets() {
//     if (navigator.onLine) {
//         const res = await axios.get(
//             `api/geo/asset/report/assets`
//         );
//         return res.data;
//     } else {
//         //ToDo

//     }
// }

async function getAssetShareLinks(token) {
    const res = await axios(`/api/geo/assets/share-links/${token}`);
    return res.data
}

async function updateAssetShareLink(expireDate, assetInfoId) {
    const data = { expireDate: expireDate, assetInfoId: assetInfoId }
    const res = await axios.put(`api/geo/asset/share-links`, data);
    return res.data;
}
async function insertAssetShareLink(assetShareId, expireDate, status) {
    const data = { expireDate: expireDate, assetShareId: assetShareId, status: status }
    const res = await axios.post('api/geo/asset/share-links', data);
    return res.data;
}

async function getAssetById(id) {
    if (navigator.onLine) {

        const res = await axios(`/api/geo/assets/${id}`);
        return res.data
    } else {
        if (!db.userDB || _.isEmpty(db.userDB)) {
            db.userDB = await db.refreshUserDb();
        }
        const res = await db.userDB.allDocs
    }

}

async function getAssetDetailsById(id) {
    if (navigator.onLine) {

        const res = await axios(`/api/geo/assets/details/${id}`);
        return res.data
    } else {
        if (!db.userDB || _.isEmpty(db.userDB)) {
            db.userDB = await db.refreshUserDb();
        }
        const res = await db.userDB.allDocs
    }

}
async function getAssetDeviceList(assetInfoId) {
    if (navigator.onLine) {

        const res = await axios(`/api/geo/assets/devices/${assetInfoId}`);
        return res.data
    } else {
        //ToDo
    }

}
/////////////////////////////////////////////////////////////////////////////
/// Asset Groups
/////////////////////////////////////////////////////////////////////////////
async function getAssetGroups() {

    if (navigator.onLine) {

        const res = await axios.get(
            `api/geo/asset-group`
        );
        return res.data;
    } else {
        //ToDo

    }
}
async function getAssetGroup(Id) {

    if (navigator.onLine) {

        const res = await axios.get(
            `api/geo/asset-group/${Id}`
        );
        return res.data;
    } else {
        //ToDo

    }
}

/**
 * Description
 * @param {UUID} id
 * @param {String} Name
 * @param {Array} roleId - List of roles
 * @returns {data}
 */
async function updateAssetGroup(id, Name, roleId) {
    if (navigator.onLine) {
        const body = { name: Name, roleId: roleId }
        const res = await axios.put(
            `api/geo/asset-group/${id}`, body
        );
        return res.data;
    } else {
        //ToDo

    }
}
/**
 * Description
 * @param {UUID} id
 * @param {String} Name
 * @param {Array} roleId - List of roles
 * @returns {any}
 */
async function insertAssetGroup(Name, roleId) {
    if (navigator.onLine) {
        const body = { name: Name, roleId: roleId }
        const res = await axios.post(
            `api/geo/asset-group/`, body
        );
        return res.data;
    } else {
        //ToDo

    }
}
/**
 * Description
 * @param {UUID} id
 * @returns {any}
 */
async function deleteAssetGroup(id) {
    if (navigator.onLine) {

        const res = await axios.delete(
            `api/geo/asset-group/${id}`
        );
        return res.data;
    } else {
        //ToDo

    }
}
/////////////////////////////////////////////////////////////////////////////
/// Asset Category
/////////////////////////////////////////////////////////////////////////////
async function getAssetCategories() {

    if (navigator.onLine) {

        const res = await axios.get(
            `api/geo/asset-category`
        );
        return res.data;
    } else {
        //ToDo

    }
}
async function getAssetCategory(id) {

    if (navigator.onLine) {

        const res = await axios.get(
            `api/geo/asset-category/${id}`
        );
        return res.data;
    } else {
        //ToDo

    }
}
/**
 * Description
 * @param {UUID} id
 * @param {String} Name
 * @returns {data}
 */
async function updateAssetCategory(id, Name) {
    if (navigator.onLine) {
        const body = { name: Name }
        const res = await axios.put(
            `api/geo/asset-category/${id}`, body
        );
        return res.data;
    } else {
        //ToDo

    }
}
/**
 * Description
 * @param {UUID} id
 * @param {String} Name
 * @returns {any}
 */
async function insertAssetCategory(id, Name) {
    id = helper.checkId(id) ? id : uuidv4()
    if (navigator.onLine) {
        const body = { id, name: Name }
        const res = await axios.post(
            `api/geo/asset-category/`, body
        );
        debugger;
        return res.data;
    } else {
        //ToDo

    }
}
/**
 * Description
 * @param {UUID} id
 * @returns {any}
 */
async function deleteAssetCategory(id) {
    if (navigator.onLine) {

        const res = await axios.delete(
            `api/geo/asset-category/${id}`
        );
        return res.data;
    } else {
        //ToDo

    }
}
async function getDeviceTypes() {

    if (navigator.onLine) {

        const res = await axios.get(
            `api/geo/asset/device-type`
        );
        return res.data;
    } else {
        //ToDo

    }
}

async function getAssetTrips(assetTripsInfo) {
    if (navigator.onLine) {
        try {
            const res = await axios.post(`/api/geo/asset/trips`, assetTripsInfo);
            return res.data
        } catch (error) {
            return error;
        }
    } else {
        //ToDo
    }
}

async function getAssetDeviceUsageReport(startDate, endDate, page, size, sortField, sortOrder, search) {
    page = page || 1;
    size = size || 10000;
    sortField = sortField || 'dateCreated';
    sortOrder = sortOrder || 'DESC';
    search = search || '';
    search = search.toUpperCase().trim();


    if (navigator.onLine) {

        let res = await axios.get(
            `/api/geo/asset/reports/device-usage?page=${page}&size=${size}&sortField=${sortField}&sortOrder=${sortOrder}&search=${search}&startDate=${startDate}&endDate=${endDate}`
        );
        return res.data;
    }

    return false;

}

async function getAssetTamperReport(type, search = null, startDate, endDate, page, size, sortField = 'startDate', sortOrder = 'DESC') {

    page = page || 1;
    size = size || 10000;
    sortField = sortField || 'CreateDate';
    sortOrder = sortOrder || 'DESC';
    startDate = startDate || DateTime.now().plus({ days: -1 }).toISO();
    endDate = endDate || DateTime.now().plus({ days: 7 }).toISO();


    if (navigator.onLine) {
        try {
            const res = await axios.get(`/api/geo/notification-event/reports/tamper?type=${type}&search=${search}&startDate=${startDate}&endDate=${endDate}&page=${page}&size=${size}&sortField=${sortField}&sortOrder=${sortOrder}`);
            return res;
        } catch (error) {

            return error;
        }
    } else {
        //ToDo
    }
}

async function getHotSpotReport(assetId, assetCategoryId, assetGroupId, startDate, endDate, page, size, sortField = 'startDate', sortOrder = 'DESC') {
    assetId = assetId || null;
    page = page || 1;
    size = size || 10000;
    sortField = sortField || 'startDate';
    sortOrder = sortOrder || 'DESC';
    startDate = startDate || DateTime.now().plus({ days: -1 }).toISO();
    endDate = endDate || DateTime.now().plus({ days: 7 }).toISO();

    if (navigator.onLine) {
        try {
            const res = await axios.get(`/api/geo/asset/reports/hotspots?assetId=${assetId}&assetCategoryId=${assetCategoryId}&assetGroupId=${assetGroupId}&startDate=${startDate}&endDate=${endDate}&page=${page}&size=${size}&sortField=${sortField}&sortOrder=${sortOrder}`);
            return res;
        } catch (error) {
            return error;
        }
    } else {
        //ToDo
    }
}

async function getAssetTripReports(assetId, startDate, endDate, showZeros, page, size, sortField = 'startDate', sortOrder = 'DESC') {
    assetId = assetId || null;
    page = page || 1;
    size = size || 10000;
    sortField = sortField || 'startDate';
    sortOrder = sortOrder || 'DESC';
    startDate = startDate || DateTime.now().plus({ days: -1 }).toISO();
    endDate = endDate || DateTime.now().plus({ days: 7 }).toISO();
    showZeros = showZeros == true ? 0 : 1

    if (navigator.onLine) {
        try {
            const res = await axios.get(`/api/geo/asset/reports/trips?assetId=${assetId}&startDate=${startDate}&endDate=${endDate}&zeros=${showZeros}&page=${page}&size=${size}&sortField=${sortField}&sortOrder=${sortOrder}`);
            return res;
        } catch (error) {
            return error;
        }
    } else {
        //ToDo
    }
}


async function getAssetStopReports(assetId, startDate, endDate, showZeros, page, size, sortField = 'startDate', sortOrder = 'DESC') {
    page = page || 1;
    size = size || 10000;
    sortField = sortField || 'startDate';
    sortOrder = sortOrder || 'ASC';
    startDate = startDate || DateTime.now().plus({ days: -1 }).toISO();
    endDate = endDate || DateTime.now().plus({ days: 7 }).toISO();
    showZeros = showZeros == true ? 0 : 1

    if (navigator.onLine) {
        try {
            const res = await axios.get(`/api/geo/asset/reports/stops?assetId=${assetId}&startDate=${startDate}&endDate=${endDate}&zeros=${showZeros}&page=${page}&size=${size}&sortField=${sortField}&sortOrder=${sortOrder}`);
            return res;
        } catch (error) {
            console.debug(error);
            return error;
        }
    } else {
        //ToDo
    }
}
async function getAssetEngineReports(assetId, startDate, endDate, showZeros, page, size, sortField = 'startDate', sortOrder = 'DESC') {
    page = page || 1;
    size = size || 10000;
    sortField = sortField || 'startDate';
    sortOrder = sortOrder || 'ASC';
    startDate = startDate || DateTime.now().plus({ days: -1 }).toISO();
    endDate = endDate || DateTime.now().plus({ days: 7 }).toISO();
    showZeros = showZeros == true ? 0 : 1

    if (navigator.onLine) {
        try {
            const res = await axios.get(`/api/geo/asset/reports/engine?assetId=${assetId}&startDate=${startDate}&endDate=${endDate}&zeros=${showZeros}&page=${page}&size=${size}&sortField=${sortField}&sortOrder=${sortOrder}`);
            return res;
        } catch (error) {
            return error;
        }
    } else {
        //ToDo
    }
}

async function getPositions(deviceId, startDate, endDate) {
    let info = { deviceId: deviceId, startDate: startDate, endDate: endDate };
    if (navigator.onLine) {
        try {
            const res = await axios.post(`/api/geo/asset/device/positions`, info);
            return res;
        } catch (error) {
            console.debug(error);
            return error;
        }
    } else {
        //ToDo
    }
}

async function getAssetAlerts(assetId, startDate, endDate, page = 1, size = 100, sortField = 'createdAt', sortOrder = 'DESC') {
    page = page || 1;
    size = size || 100;
    sortField = sortField || 'createdAt';
    sortOrder = sortOrder || 'DESC';
    startDate = startDate || DateTime.now().toISO();
    endDate = endDate || DateTime.now().toISO();
    const encodedStartDate = encodeURIComponent(startDate);
    const encodedEndDate = encodeURIComponent(endDate);
    if (navigator.onLine) {
        try {
            return axios.get(`/api/geo/notification-event/asset/${assetId}/alarm?page=${page}&size=${size}&startDate=${encodedStartDate}&endDate=${encodedEndDate}`);
        } catch (error) {
            console.debug(error);
            return error;
        }
    } else {
        //ToDo
    }
}

async function getAssetEvents(assetId, startDate, endDate, page = 1, size = 100, sortField = 'createdAt', sortOrder = 'DESC') {
    page = page || 1;
    size = size || 100;
    sortField = sortField || 'createdAt';
    sortOrder = sortOrder || 'DESC';
    startDate = startDate || DateTime.now().toISO();
    endDate = endDate || DateTime.now().toISO();
    const encodedStartDate = encodeURIComponent(startDate);
    const encodedEndDate = encodeURIComponent(endDate);
    if (navigator.onLine) {
        try {
            return axios.get(`/api/geo/notification-event/asset/${assetId}/event?page=${page}&size=${size}&startDate=${encodedStartDate}&endDate=${encodedEndDate}`);
        } catch (error) {
            console.debug(error);
            return error;
        }
    } else {
        //ToDo
    }
}
async function getAssetCrashes(assetId, status, startDate, endDate, page = 1, size = 100, sortField = 'createdAt', sortOrder = 'DESC') {
    page = page || 1;
    size = size || 100;
    sortField = sortField || 'createdAt';
    sortOrder = sortOrder || 'DESC';
    startDate = startDate || DateTime.now().toISO();
    endDate = endDate || DateTime.now().toISO();
    const encodedStartDate = encodeURIComponent(startDate);
    const encodedEndDate = encodeURIComponent(endDate);
    if (navigator.onLine) {
        try {
            return axios.get(`/api/geo/crash-event/${assetId}/${status}?page=${page}&size=${size}&startDate=${encodedStartDate}&endDate=${encodedEndDate}`);
        } catch (error) {
            console.debug(error);
            return error;
        }
    } else {
        //ToDo
    }
}

async function updateAssetOdometer(id, data) {
    const res = await axios.put(`api/geo/assets/${id}/odometer`, data);
    return res.data;
}

async function updateAssetEngineHours(id, data) {
    const res = await axios.put(`api/geo/assets/${id}/engine-hours`, data);
    return res.data;
}
async function updateAssetMapIcon(id, data) {
    const res = await axios.put(`api/geo/assets/${id}/map-icon`, data);
    return res.data;
}
