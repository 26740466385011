<script>
import { version } from './../../package.json';
import { build } from './../../build.json';
const buildId = build||'00000';
export default {
    data() {
        return {
            version,
            buildId,
        };
    },
};
</script>

<template>
    <footer class="footer">
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-6">
                    {{ new Date().getFullYear() }} © PlatCorp Holdings Limited.
                </div>
                <div class="col-sm-6">
                    <div class="float-end">
                        <p>v{{ version }} ({{buildId}})</p>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>
