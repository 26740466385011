<script>
import MetisMenu from 'metismenujs';
import simplebar from './SimpleBar';
import { menuItems } from './menu';
import { onBeforeMount, reactive,ref } from 'vue';
import _ from 'lodash';
import { useDark, useToggle,useStorage  } from '@vueuse/core';
import user from '@/mixins/user';
//import phl_logo from "../assets/logo.png";

const theme = ref(useStorage('theme','light')); //default to light

/**
 * Sidebar component
 */

// refresh
export default {
    data() {
        return {
            menuItems: menuItems,
            tenants: [],
            //deferredPrompt: null,
        };
    },
    props: {
        type: {
            type: String,
            required: true,
        },
        width: {
            type: String,
            required: true,
        },
        isCondensed: {
            type: Boolean,
            required: true,
        },
        isDarkMode: {
            type: Boolean,
            required: true,
            default: false,
        },
    },
    components: {
        simplebar,
    },
    mixins: [user],
    mounted: function () {
        if (document.querySelector('#side-menu')) {
            // eslint-disable-next-line no-unused-vars
            const menuRef = new MetisMenu('#side-menu');
        }
        //Go get Customised Menu Items (eg: Filtered Reports)

        this._activateMenuDropdown();
        this.$router.afterEach(() => {
            this._activateMenuDropdown();
        });
        let deferredPrompt = null;
        //handle showing the pwa installation popup
        window.addEventListener('beforeinstallprompt', e => {
            document.getElementById('Install').style.display = 'block';
            deferredPrompt = e;
       

        const installApp = document.getElementById('Install');
        if (installApp) {
            installApp.addEventListener('click', async () => {
                if (deferredPrompt !== null) {
                    deferredPrompt.prompt();
                    const { outcome } = await deferredPrompt.userChoice;
                    if (outcome === 'accepted') {
                        deferredPrompt = null;
                    }
                }
            });
        }
    });
    },
    beforeMount: async function () {
        await this.checkMenuUserPermissions();
    },
    methods: {
        /**
         * Toggle menu
         */
        toggleMenu() {
            this.$parent.toggleMenu();
        },
        toggleTheme(){
            //this.$parent.toggleTheme();
            theme.value = theme.value === 'light' ? 'dark': 'light';
            useStorage('theme', theme.value);
            this.toggleDark(!this.isDark);
        },
        /**
         * Returns true or false if given menu item has child or not
         * @param item menuItem
         */
        hasItems(item) {
            return item.subItems !== undefined ? item.subItems.length > 0 : false;
        },
        /**
         * remove active and mm-active class
         */
        _removeAllClass(className) {
            const els = document.getElementsByClassName(className);
            while (els[0]) {
                els[0].classList.remove(className);
            }
        },
        _activateMenuDropdown() {
            this._removeAllClass('mm-active');
            this._removeAllClass('mm-show');

            var links = document.getElementsByClassName('side-nav-link-ref');
            var matchingMenuItem = null;
            const paths = [];

            for (var i = 0; i < links.length; i++) {
                paths.push(links[i]['pathname']);
            }
            var itemIndex = paths.indexOf(window.location.pathname);
            if (itemIndex === -1) {
                const strIndex = window.location.pathname.lastIndexOf('/');
                const item = window.location.pathname.substr(0, strIndex).toString();
                matchingMenuItem = links[paths.indexOf(item)];
            } else {
                matchingMenuItem = links[itemIndex];
            }

            if (matchingMenuItem) {
                matchingMenuItem.classList.add('active');
                var parent = matchingMenuItem.parentElement;

                /**
                 * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
                 * We should come up with non hard coded approach
                 */
                if (parent) {
                    parent.classList.add('mm-active');
                    const parent2 = parent.parentElement.closest('ul');
                    if (parent2 && parent2.id !== 'side-menu') {
                        parent2.classList.add('mm-show');

                        const parent3 = parent2.parentElement;
                        if (parent3) {
                            parent3.classList.add('mm-active');
                            var childAnchor = parent3.querySelector('.has-arrow');
                            var childDropdown = parent3.querySelector('.has-dropdown');
                            if (childAnchor) childAnchor.classList.add('mm-active');
                            if (childDropdown) childDropdown.classList.add('mm-active');

                            const parent4 = parent3.parentElement;
                            if (parent4 && parent4.id !== 'side-menu') {
                                parent4.classList.add('mm-show');
                                const parent5 = parent4.parentElement;
                                if (parent5 && parent5.id !== 'side-menu') {
                                    parent5.classList.add('mm-active');
                                    const childanchor = parent5.querySelector('.is-parent');
                                    if (childanchor && parent5.id !== 'side-menu') {
                                        childanchor.classList.add('mm-active');
                                    }
                                }
                            }
                        }
                    }
                }
            }
        },
        onRoutechange() {
            setTimeout(() => {
                if (document.getElementsByClassName('mm-active').length > 0) {
                    const currentPosition = document.getElementsByClassName('mm-active')[0].offsetTop;
                    if (currentPosition > 500) this.$refs.currentMenu.SimpleBar.getScrollElement().scrollTop = currentPosition + 300;
                }
            }, 300);
        },
        async hasAnySubItem(menuItem, userPermissions) {
            let hasAny = false;
            if (menuItem.subItems && menuItem.subItems.length > 0) {
                menuItem.subItems.forEach(async subItem => {
                    hasAny = hasAny || (await this.hasAnySubItem(subItem, userPermissions));
                    hasAny =
                        hasAny ||
                        userPermissions.filter(
                            userPermission =>
                                userPermission.hierarchy === subItem.permissions ||
                                (_.isArray(subItem.permissions) &&
                                    subItem.permissions.filter(x => x == userPermission.hierarchy).length > 0),
                        ).length > 0;
                });
            }
            return hasAny;
        },
        async checkSubItemsPermissions(menuItem, userPermissions) {
            // check if user has permissions to the sub items
            // remove each subitem they don't have permissions to
            // do this recursively for each subitem
            //console.log('USER PERMISSIONS', userPermissions)
            let hasAnySubItem = await this.hasAnySubItem(menuItem, userPermissions);
            //console.log(menuItem, hasAnySubItem);
            if (menuItem.subItems && menuItem.subItems.length > 0) {
                menuItem.subItems.forEach(async subItem => {
                    //      console.log('SUBITEM', subItem )
                    if (subItem.permissions) {
                        let userHasPermissions =
                            userPermissions.filter(
                                userPermission =>
                                    userPermission.hierarchy === subItem.permissions ||
                                    (_.isArray(subItem.permissions) &&
                                        subItem.permissions.filter(x => x == userPermission.hierarchy).length > 0),
                            ).length > 0;
                        //console.log(hasAnySubItem, userHasPermissions, subItem.permissions);
                        if (userHasPermissions == false) {
                            if (document.querySelector('li[data-permissions="' + subItem.permissions + '"]')) {
                                document.querySelector('li[data-permissions="' + subItem.permissions + '"]').remove();
                            }
                        }
                    }
                    await this.checkSubItemsPermissions(subItem, userPermissions);
                });
            }
            let userHasPermissions =
                userPermissions.filter(
                    userPermission =>
                        userPermission.hierarchy === menuItem.permissions ||
                        (_.isArray(menuItem.permissions) && menuItem.permissions.filter(x => x == userPermission.hierarchy).length > 0),
                ).length > 0;
            //console.log(userHasPermissions, menuItem.permissions);
            if (userHasPermissions == false) {
                if (document.querySelector('li[data-permissions="' + menuItem.permissions + '"]')) {
                    document.querySelector('li[data-permissions="' + menuItem.permissions + '"]').remove();
                }
            }
        },
        async checkMenuUserPermissions() {
            const permittedMenuItems = [];
            const user = this.user;
            // const userTenant = user && user.tenants[0]; //TODO: need to populate with all allowed tenants.
            // let administrationMenu = _.last(menuItems);
            this.tenants = this.$store.getters['tenant/getTenants'];
            // if (userTenant && userTenant.type === 4) {
            //     _.remove(administrationMenu.subItems, { label: 'Tenants' });
            // }
            // eslint-disable-next-line no-unused-vars

            const userPermissions = (user.permissions && user.rolepermissions) || [];

            menuItems.forEach(async menuItem => {
                //console.log('TEST', menuItem);
                if (menuItem.permissions) {
                    await this.checkSubItemsPermissions(menuItem, userPermissions);
                }
            });
        },
        // TODO: this updated functions breaks the sidebar, to be re-looked at
        // checkMenuUserPermissions() {

        //     menuItems.forEach((menuItem) => {
        //         this.checkMenuItem(menuItem);
        //     });
        // },
    },
    watch: {
        type: {
            immediate: true,
            handler(newVal, oldVal) {
                if (newVal !== oldVal) {
                    switch (newVal) {
                        case 'dark':
                            document.body.setAttribute('data-sidebar', 'dark');
                            document.body.removeAttribute('data-topbar');
                            document.body.removeAttribute('data-sidebar-size');
                            break;
                        case 'light':
                            document.body.removeAttribute('data-sidebar');
                            document.body.removeAttribute('data-sidebar-size');
                            document.body.classList.remove('vertical-collpsed');
                            break;
                        case 'compact':
                            document.body.setAttribute('data-sidebar-size', 'small');
                            document.body.setAttribute('data-sidebar', 'dark');
                            document.body.classList.remove('vertical-collpsed');
                            document.body.removeAttribute('data-topbar', 'dark');
                            break;
                        case 'icon':
                            document.body.setAttribute('data-keep-enlarged', 'true');
                            document.body.classList.add('vertical-collpsed');
                            document.body.setAttribute('data-sidebar', 'dark');
                            document.body.removeAttribute('data-topbar', 'dark');
                            break;
                        case 'colored':
                            document.body.setAttribute('data-sidebar', 'colored');
                            document.body.removeAttribute('data-keep-enlarged');
                            document.body.classList.remove('vertical-collpsed');
                            document.body.removeAttribute('data-sidebar-size');
                            break;
                        default:
                            document.body.setAttribute('data-sidebar', 'dark');
                            break;
                    }
                }
            },
        },
        width: {
            immediate: true,
            handler(newVal, oldVal) {
                if (newVal !== oldVal) {
                    switch (newVal) {
                        case 'boxed':
                            document.body.setAttribute('data-layout-size', 'boxed');
                            break;
                        case 'fluid':
                            document.body.setAttribute('data-layout-mode', 'fluid');
                            document.body.removeAttribute('data-layout-size');
                            break;
                        default:
                            document.body.setAttribute('data-layout-mode', 'fluid');
                            break;
                    }
                }
            },
        },
        $route: {
            handler: 'onRoutechange',
            immediate: true,
            deep: true,
        },
    },
    setup() {
         const isDark = useDark(
            {
                initial: false,
                selector: "body",
                attribute: "theme",
                valueDark: "dark",
                valueLight: "light",
            }
        );
        
        const toggleDark = useToggle(isDark);
        const state = reactive({
            offline: false,
        });

        onBeforeMount(() => {
            window.addEventListener('online', () => {
                state.offline = false;
                document.querySelector('body').classList.remove('offline');
            });
            window.addEventListener('offline', () => {
                state.offline = true;
                //document.querySelector('body').classList.add('offline');
            });
        });

        return {
            state,
            toggleDark,
            isDark,
        };
    },
};
</script>

<template>
    <!-- ========== Left Sidebar Start ========== -->

    <div class="vertical-menu slate-700">
        <!-- LOGO -->
        <div class="navbar-brand-box slate-700">
            <router-link to="/" class="logo" aria-label="Sub's logo and a home link">
                <div v-if="!isCondensed" class="sidebar-logo-outer">
                    <div class="sidebar-logo"></div>
                </div>
                <div v-if="isCondensed" class="sidebar-logo-sm"></div>
            </router-link>
        </div>

            <button 
            type="button" 
            @click="toggleMenu" 
            id="side-menu-toggle-button" 
            class="btn btn-sm px-3 font-size-16 header-item vertical-menu-btn"
            aria-label="Menu Toggle"
            >
                <i class="fa fa-fw fa-bars"></i>
            </button>
        <simplebar class="sidebar-menu-scroll" ref="currentMenu">
            <!--- Sidemenu -->
            <div id="sidebar-menu">
                <!-- Left Menu Start -->
                <ul class="metismenu list-unstyled" id="side-menu">
                    <template v-for="item in menuItems">
                        <li class="menu-title" v-if="item.isTitle" :key="item.label" :id="item.label" :data-testid="item.label">
                            {{ applyTranslation(item.label) }}
                        </li>
                        <li v-if="!item.isTitle && !item.isLayout" :key="item.label" :data-permissions="item.permissions" :id="item.label">
                            <a
                                v-if="hasItems(item)"
                                href="javascript:void(0);"
                                class="is-parent"
                                :class="{
                                    'has-arrow': !item.badge,
                                    'has-dropdown': item.badge,
                                    'hide-when-offline': item.hideWhenOffline === true,
                                }"
                            >
                                <i :class="`${item.icon}`" v-if="item.icon"></i>

                                <span :class="`badge rounded-pill bg-${item.badge.variant} float-end`" v-if="item.badge">{{
                                    $t(item.badge.text)
                                }}</span>
                                <span :data-testid="`${item.label}`">{{ applyTranslation(item.label) }}</span>
                            </a>

                            <router-link :to="item.link" v-if="!hasItems(item)" class="side-nav-link-ref">
                                <i :class="`${item.icon}`" v-if="item.icon"></i>
                                <span>{{ applyTranslation(item.label) }}</span>
                                <span :class="`badge rounded-pill bg-${item.badge.variant} float-end`" v-if="item.badge">{{
                                    $t(item.badge.text)
                                }}</span>
                            </router-link>

                            <ul v-if="hasItems(item)" class="sub-menu" aria-expanded="false">
                                <li v-for="(subitem, index) of item.subItems" :key="index" :data-permissions="subitem.permissions">
                                    <router-link
                                        :to="subitem.link"
                                        v-if="!hasItems(subitem)"
                                        class="side-nav-link-ref"
                                        :data-testid="`${subitem.label}`"
                                        >{{ $t(subitem.label) }}</router-link
                                    >
                                    <a v-if="hasItems(subitem)" class="side-nav-link-a-ref has-arrow" href="javascript:void(0);">{{
                                        applyTranslation(subitem.label)
                                    }}</a>
                                    <ul v-if="hasItems(subitem)" class="sub-menu mm-collapse" aria-expanded="false">
                                        <li
                                            v-for="(subSubitem, index) of subitem.subItems"
                                            :key="index"
                                            :data-permissions="subSubitem.permissions"
                                        >
                                            <router-link :to="subSubitem.link" class="side-nav-link-ref">{{
                                                applyTranslation(subSubitem.label)
                                            }}</router-link>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                    </template>  
                    <li>               
                            <button 
                                 class="btn btn-outline-none togglebtn ms-4" 
                                @click="toggleTheme">
                                <span v-if=isDark>
                                    <i class="uil uil-moon font-size-20"></i> 
                                </span>
                                <span v-else> 
                                    <i class="uil uil-sun font-size-20"></i> 
                                </span>   
                             <span v-if="!isCondensed">
                                Toggle Theme
                             </span>
                                
                        </button> 
                      </li>
                </ul>
  
      
            </div>
   
            <!-- Sidebar -->

        </simplebar>
    </div>
    <!-- Left Sidebar End -->
</template>
