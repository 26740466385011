<template>
    <b-dropdown
        variant="white"
        class="dropdown d-inline-block notification"
        toggle-class="header-item noti-icon"
        right
        menu-class="dropdown-menu-lg p-0 dropdown-menu-end"
    >
        <template v-slot:button-content>
            <i class="uil-bell"></i>
            <span class="badge bg-danger rounded-pill">{{
                notifications.length
            }}</span>
        </template>

        <div class="p-3">
            <div class="row align-items-center">
                <div class="col">
                    <h5 class="m-0 font-size-16">
                        {{ $t('navbar.dropdown.notification.text') }}
                    </h5>
                </div>
                <div class="col-auto">
                    <a href="#!" @click="markAllRead" class="small">{{
                        $t('navbar.dropdown.notification.subtext')
                    }}</a>
                </div>
            </div>
        </div>
        <simplebar style="max-height: 230px; min-height: 200px" data-simplebar>
            <notificationItemVue
                v-for="item in notifications"
                v-bind:key="item"
                :showImage="item.showImage"
                :imageUrl="item.Icon"
                :Header="item.Header"
                :eventText="item.eventText"
                :eventTime="item.eventTime"
                :eventType="item.Icon"
            ></notificationItemVue>
        </simplebar>
        <div class="p-2 border-top">
            <a
                class="btn btn-sm btn-link font-size-14 btn-block text-center"
                href="javascript:void(0)"
            >
                <i class="uil-arrow-circle-right me-1"></i>
                {{ $t('navbar.dropdown.notification.button') }}
            </a>
        </div>
    </b-dropdown>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import simplebar from './../SimpleBar';
import toolBarNotification from '../../helpers/ToolBarNotification';
import notificationItemVue from './notification-item.vue';
import { DateTime } from 'luxon';
import { notificationService } from '@/services/notification.service';
import { useStore } from 'vuex';

// {
//     Header: '',
//     eventText: '',
//     EventDate: DateTime.now(),
// },
export default {
    name: 'notification',
    data() {
        return {
            store: {},
        };
    },
    components: {
        simplebar,
        notificationItemVue,
    },
    computed: {
        ...mapActions('toolBarNotification', {
            add: 'add',
            addRaw: 'addRaw',
        }),
        ...mapState({
            notifications: state => state.toolBarNotification.all,
        }),
    },
    mounted() {
        this.store = useStore();
    },
    methods: {
        formatActionDate(EventDate) {
            if (EventDate) {
                return this.$filters.formatDateTime(
                    EventDate,
                    'yyyy MMM do HH:mm',
                );
            }
            return '';
        },
        async markAllRead() {
            let result = await notificationService.markAllRead();
            this.$store.dispatch('toolBarNotification/clear');
            return;
        },
    },
};
</script>

<style></style>
