import axios from '../helpers/api';

export const notificationService = {
    getUnreadUserNotifications,
    markAllRead,
};

/** Get Agent Actions */
async function getUnreadUserNotifications() {
    if (navigator.onLine) {
        try {
            const res = await axios.get(
                `/api/notification/unreadnotifications`,
            );
            return res.data;
        } catch (error) {
            return error;
        }
    }
}

async function markAllRead() {
    if (navigator.onLine) {
        try {
            const res = await axios.post(`/api/notification/markallread`);
            return res.data;
        } catch (error) {
            return error;
        }
    }
}
