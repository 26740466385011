import { roleService } from '../../services/role.service';

export const state = {
    roles: [],
};

export const getters = {
    getRoles: state => {
        return state.roles;
    },
};

export const actions = {
    async fetchRoles({ commit }) {
        const { data } = await roleService.getAllRoles();
        commit('setRoles', data.roles);
    },
};

export const mutations = {
    setRoles: (state, roles) => {
        state.roles = roles;
    },
};
