import axios from '../helpers/api';

export const configService = {
    getLanguage,
};

async function getLanguage(tenantId) {
    try {
       
        const res = await axios.get(`/api/config/language/${tenantId}`);
        return res.data.data.language || [];
    } catch (error) {
        return  [];
    }
}
