import { emailService } from '../../services/email.service';

export const state = {
    emails: [],
    draft: null,
    reply: null,
    forward: null,
    attachments: [],
};

export const getters = {
    getEmails: state => {
        return state.emails;
    },
    getDraft: state => {
        return state.draft;
    },
    getReply: state => {
        return state.reply;
    },
    getForward: state => {
        return state.forward;
    },
    getAttachments: state => {
        return state.attachments;
    },
};

export const actions = {
    async fetchEmails({ commit }, folder, userId) {
        const emails = await emailService.getEmailsByUserId(folder, userId);
        commit('setEmails', emails);
    },
};

export const mutations = {
    setEmails: (state, emails) => {
        state.emails = emails;
        // TODO, store in Pounch DB
    },
    setDraft: (state, draft) => {
        state.draft = draft;
        // TODO, store in Pounch DB
    },
    setReply: (state, reply) => {
        state.reply = reply;
        // TODO, store in Pounch DB
    },
    setForward: (state, forward) => {
        state.forward = forward;
        // TODO, store in Pounch DB
    },
    setAttachments: (state, attachments) => {
        state.attachments = attachments;
    },
};
