<template>
    <header id="page-topbar" role="banner">
        <div class="navbar-header slate-700">
            <div class="d-flex">
                <button
                    @click="toggleMenu"
                    type="button"
                    class="btn btn-sm font-size-20 header-item vertical-menu-btn"
                    id="vertical-menu-btn"
                >
                    <i class="fa fa-fw fa-bars"></i>
                </button>
                <!-- LOGO -->
                <div class="navbar-brand-box">
                    <router-link to="/" class="logo">
                        <span v-if="themeName === 'phl'">
                            <div class="topbar-logo-sm"></div>
                            <!-- <img class="img-sm" src="@/assets/images/subs-logos/PHL-Sharp.png" alt height="32" /> -->
                        </span>
                        <span v-if="themeName === 'platinum'">
                            <div class="topbar-logo-sm"></div>
                            <!-- <img class="img-sm" src="@/assets/images/subs-logos/platinum-kenya-logo-edit.png" alt height="32" /> -->
                        </span>
                        <span v-if="themeName === 'momentum'">
                            <div class="topbar-logo-sm"></div>
                            <!-- <img class="img-sm" src="@/assets/images/subs-logos/moment-credit-logo-edit.png" alt height="32" /> -->
                        </span>
                        <span v-if="themeName === 'spectrum'">
                            <div class="topbar-logo-sm"></div>
                            <!-- <img class="img-sm" src="@/assets/images/subs-logos/moment-credit-logo-edit.png" alt height="32" /> -->
                        </span>
                        <span v-if="themeName === 'fanikiwa'">
                            <div class="topbar-logo-sm"></div>
                            <!-- <img class="img-sm" src="@/assets/images/subs-logos/moment-credit-logo-edit.png" alt height="32" /> -->
                        </span>
                        <span v-if="themeName === 'ez'">
                            <div class="topbar-logo-sm"></div>
                            <!-- <img class="img-sm" src="@/assets/images/subs-logos/ez-track-logo-edit.png" alt height="32" /> -->
                        </span>
                        <span v-if="themeName === 'eezytrack'">
                            <div class="topbar-logo-sm"></div>
                            <!-- <img class="img-sm" src="@/assets/images/subs-logos/ez-track-logo-edit.png" alt height="32" /> -->
                        </span>
                        <span v-if="themeName === 'tracknav'">
                            <div class="topbar-logo-sm"></div>
                            <!-- <img class="img-sm" src="@/assets/images/subs-logos/ez-track-logo-edit.png" alt height="32" /> -->
                        </span>
                        <span v-if="themeName === 'premier'">
                            <div class="topbar-logo-sm"></div>
                            <!-- <img class="img-sm" src="@/assets/images/subs-logos/premier-credit-logo-edit.png" alt height="32" /> -->
                        </span>
                        <span v-if="themeName === 'premiercredit'">
                            <div class="topbar-logo-sm"></div>
                            <!-- <img class="img-sm" src="@/assets/images/subs-logos/premier-credit-logo-edit.png" alt height="32" /> -->
                        </span>
                        <span v-if="themeName === 'viva'">
                            <div class="topbar-logo-sm"></div>
                            <!-- <img class="img-sm" src="@/assets/images/subs-logos/viva365-logo-edit.png" alt height="32" /> -->
                        </span>
                    </router-link>
                </div>


                <!-- App Search-->
                <form class="app-search d-none d-lg-block">
                    <div class="position-relative">
                        <!--  <input
              type="text"
              class="form-control"
              :placeholder="$t('navbar.search.text')"
            />
            <span class="uil-search"></span> -->
                    </div>
                </form>
            </div>

            <div class="d-flex align-items-center">
                <b-dropdown
                    variant="white"
                    class="d-inline-block d-lg-none ms-2 btn-search"
                    toggle-class="header-item noti-icon"
                    right
                    menu-class="dropdown-menu-lg p-0 dropdown-menu-end"
                >
                    <!-- <template v-slot:button-content>
                        <i class="uil-search"></i>
                    </template> -->
                    <form class="p-3">
                        <div class="form-group m-0">
                            <div class="input-group">
                                <input
                                    type="text"
                                    class="form-control"
                                    :placeholder="$t('navbar.search.text')"
                                    id="top-search"
                                />
                                <div class="input-group-append d-flex slate-700">
                                    <button class="btn btn-primary" type="submit">
                                        <i class="mdi mdi-magnify"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </b-dropdown>

                <template v-if="!(impersonationState)">

                    <b-dropdown
                        class="select-tenant slate-700"
                        variant="white"
                        :text="this.getTenantName(myStore.localTenant)"
                        toggle-class="header-item"
                        auto-close="outside"
                        v-if="!this.isLoanOfficer() && !this.isTeamLeader()"
                    >
                      <div class="slate-700"> 
                        <b-dropdown-item>
                            <div class="reset-tenants" v-if="showResetTenant">
                                <button class="btn btn-sm btn-primary" @click="onResetTenants">Reset</button>
                            </div>
                            <Tree
                                :value="tenantsNodes"
                                :expandedKeys="expandedKeys"
                                v-model:selectionKeys="selectedKey"
                                selectionMode="single"
                                :filter="true"
                                name="tenant-tree"
                                class=" slate-700"
                                @nodeSelect="onNodeSelected"
                            >
                            </Tree>
                        </b-dropdown-item>
                    </div>  
                    </b-dropdown>
     
                    <span v-else>
                        {{ this.getTenantName(this.initialTenant) }}
                    </span>

                </template>

                <template v-else>

                    <b-dropdown
                        class="select-tenant slate-700"
                        variant="white"
                        :text="this.getTenantName(myStore.localTenant)"
                        toggle-class="header-item"
                        auto-close="outside"
                        v-if="!this.isLoanOfficer() && !this.isTeamLeader()"
                    >
                        <b-dropdown-item>
                            <div class="reset-tenants" v-if="showResetTenant">
                                <button class="btn btn-sm btn-primary" @click="onResetTenants">Reset</button>
                            </div>
                            <Tree
                                :value="tenantsNodes"
                                :expandedKeys="expandedKeys"
                                v-model:selectionKeys="selectedKey"
                                selectionMode="single"
                                :filter="true"
                                class="slate-700"
                                :id="tenant-tree"
                                @nodeSelect="onNodeSelected"
                            >
                            </Tree>
                        </b-dropdown-item>
                    </b-dropdown>
                    <span v-else>
                        {{ this.getTenantName(this.initialTenant) }}
                    </span>

                </template>

                <!-- <b-dropdown variant="white" right toggle-class="header-item">
          <template v-slot:button-content>
            <img class :src="flag" alt="Header Language" height="16" />
            {{ text }}
          </template>
          <b-dropdown-item
            class="notify-item"
            v-for="(entry, i) in languages"
            :key="`Lang${i}`"
            :value="entry"
            @click="setLanguage(entry.language, entry.title, entry.flag)"
            :link-class="{ active: entry.language === current_language }"
          >
            <img
              :src="`${entry.flag}`"
              alt="user-image"
              class="me-1"
              height="12"
            />
            <span class="align-middle">{{ entry.title }}</span>
          </b-dropdown-item>
        </b-dropdown> -->

                <!-- <b-dropdown
          variant="white"
          class="d-none d-lg-inline-block ms-1"
          toggle-class="header-item noti-icon"
          right
          menu-class="dropdown-menu-lg dropdown-menu-end"
        >
          <template v-slot:button-content>
            <i class="uil-apps"></i>
          </template>
          <div class="px-lg-2">
            <div class="row no-gutters">
              <div class="col">
                <a class="dropdown-icon-item" href="#">
                  <img src="@/assets/images/brands/github.png" alt="Github" />
                  <span>{{ $t("navbar.dropdown.site.list.github") }}</span>
                </a>
              </div>
              <div class="col">
                <a class="dropdown-icon-item" href="#">
                  <img
                    src="@/assets/images/brands/bitbucket.png"
                    alt="bitbucket"
                  />
                  <span>{{ $t("navbar.dropdown.site.list.bitbucket") }}</span>
                </a>
              </div>
            </div>
          </div>
        </b-dropdown> -->

                <div class="dropdown d-lg-inline-block ms-1" v-if="agentStatus">
                    <span class="text-success">
                        <router-link class="dropdown-item green" to="/agents/landingpage" aria-label="A green clock icon indicating 'Agent online'">
                            <i class="uil uil-clock font-size-18 align-middle me-1"></i>
                        </router-link>
                    </span>
                </div>
                <div class="dropdown d-lg-inline-block ms-1" v-else>
                    <span class="text-danger">
                        <router-link class="dropdown-item red" to="/agents/landingpage" aria-label="A red clock icon indicating 'Agent offline'">
                            <i class="uil uil-clock font-size-18 align-middle me-1"></i>
                        </router-link>
                    </span>
                </div>
                <v-offline ping="https://www.google.com" @detected-condition="handleConnectivityChange">
                    <div class="dropdown d-none d-lg-inline-block ms-1" v-if="onLine">
                        <button type="button" title="Application is currently online." id="onlineIcon" class="btn header-item noti-icon waves-effect green">
                            <i class="uil-wifi"></i>
                        </button>
                    </div>
                    <div class="dropdown d-none d-lg-inline-block ms-1" v-else>
                        <button type="button" title="Application is currently offline." id="offlineIcon" class="btn header-item noti-icon waves-effect red">
                            <i class="uil-wifi"></i>
                        </button>
                    </div>
                </v-offline>

                <!-- <div class="dropdown d-none d-lg-inline-block ms-1">
                    <router-link class="dropdown-item" to="/clients/phone-book">
                        <i class="uil uil-phone font-size-18 align-middle me-1 text-muted"></i>
                    </router-link>
                </div> -->

                <div class="dropdown d-none d-lg-inline-block ms-1">
                    <button 
                    v-if="isFullScreen"
                    type="button" 
                    id="btn-fullscreen-toggle"
                    class="btn header-item noti-icon waves-effect" 
                    data-toggle="fullscreen" 
                    aria-label="Fullscreen Toggle fullscreen"
                    @click="initFullScreen"
                    >
                        <i class="uil-compress"></i>
                    </button>

                    <button 
                    v-else
                    type="button"
                    id="btn_fullscreenToggle"
                    class="btn header-item noti-icon waves-effect" 
                    data-toggle="fullscreen" 
                    aria-label="Fullscreen Toggle small screen"
                    @click="initFullScreen"
                    >
                        <i class="uil-minus-path"></i>
                    </button>
                </div>
                <notification></notification>
                <div v-if="this.$route.path === '/'">
                    <i
                        @click="toggleWidgetSidebar(!isWidgetSidebarOpen)"
                        class="pi pi-align-right close-widgets-sidebar"
                        title="Dashboard Widgets"
                    ></i>
                </div>
                <b-dropdown class="d-inline-block" toggle-class="header-item" right variant="white" menu-class="dropdown-menu-end">
                    <template v-slot:button-content>
                        <vue-gravatar 
                        :email="userEmail" 
                        :size="45" 
                        class="rounded-circle header-profile-user" 
                        alt="User's avatar"
                        />
                        <span class="d-none d-xl-inline-block ms-1 fw-medium font-size-15">{{ userName }}</span>
                        <!-- <i class="uil-angle-down d-none d-xl-inline-block font-size-15"></i> -->
                    </template>

                    <!-- item-->
                    <router-link class="dropdown-item" to="/profile">
                        <i class="uil uil-user-circle font-size-18 align-middle text-muted me-1"></i>
                        <span class="align-middle">{{ $t('navbar.dropdown.user.list.profile') }}</span>
                    </router-link>

                    <!-- conditionally show options to avoid errors-->
                    <template v-if="impersonationState">
                        <router-link class="dropdown-item" to="/end-impersonation">
                            <i class="uil uil-user-times font-size-18 align-middle text-muted me-1"></i>
                            <span class="align-middle">{{ $t('End User Impersonation') }}</span>
                        </router-link>
                    </template>

                    <template v-if="!(impersonationState)">
                        <router-link class="dropdown-item" to="/profile/change-password">
                            <i class="uil uil-lock font-size-18 align-middle text-muted me-1"></i>
                            <span class="align-middle">{{ $t('navbar.dropdown.user.list.changepassword') }}</span>
                        </router-link>
                    </template>
                    <!-- <a class="dropdown-item d-block" href="#">
            <i
              class="uil uil-cog font-size-18 align-middle me-1 text-muted"
            ></i>
            <span class="align-middle">{{
              $t("navbar.dropdown.user.list.settings")
            }}</span>
            <span class="badge bg-soft-success rounded-pill mt-1 ms-2">03</span>
          </a> -->
          
                <template v-if="!(impersonationState)">
                    <router-link class="dropdown-item" data-testid="logout" to="/logout">
                        <i class="uil uil-sign-out-alt font-size-18 align-middle me-1 text-muted"></i>
                        <span class="align-middle">{{ $t('navbar.dropdown.user.list.logout') }}</span>
                    </router-link>
                </template>
                </b-dropdown>
            </div>
            
        </div>
    </header>
</template>

<style lang="scss">
</style>

<script>
import notification from './notification/index.vue';
import { VOffline } from 'v-offline';
import { tenantService } from '@/services/tenant.service';
import { userService } from '@/services/user.service';
import { useStore } from 'vuex';
import { myStore } from './topbarUtil';
import { useRouter } from 'vue-router';
import user from '@/mixins/user';
import _ from 'lodash';
import { agentService } from '@/services/agent.service';
import { notificationService } from '@/services/notification.service';
import { useSocketIO } from '@/use/useSocketIO';
import { mapState, mapActions } from 'vuex';
import { onBeforeMount } from 'vue';
import {jwtDecode} from 'jwt-decode';
import { DateTime } from 'luxon';


export default {
    components: {
        notification,
        VOffline,
    },
    data() {
        return {
            languages: [
                {
                    flag: require('@/assets/images/flags/us.jpg'),
                    lang: 'en',
                    title: 'English',
                },
            ],

            current_language: 'en',
            isFullScreen: false,
            text: null,
            flag: null,
            value: null,
            userName: '',
            myStore,
            userEmail: 'default.user@platcorpgroup.com',
            onLine: true, // properties for dealing with online status
            onlineSlot: 'online',
            offlineSlot: 'offline',
            tenants: [],
            store: {},
            tenantQuery: '',
            initialTenant: '',
            tenantsNodes: [],
            expandedKeys: {},
            selectedKey: {},
            showResetTenant: false,
        };
    },
    mixins: [user],
    computed: {
        themeName() {
            return this.$themeName();
        },
        ...mapState({
            agentStatus: state => state.offline.agent,
            isWidgetSidebarOpen: state => state.layout.isWidgetSidebarOpen,
        }),

        impersonationState(){
            return localStorage.getItem('impersonationStatus') == 'in-progress';
        },

        async tenantName(){
            let tenant;
            tenant = this.getTenantName(myStore.localTenant)
            return tenant;
        },

    },
    async beforeMount() {
        this.myStore.localTenant = await this.currentUser.selectedTenant;
        if (!this.myStore.localTenant && this.user && this.user.tenants && this.user.tenants.length > 0) {
            this.myStore.localTenant = this.user.selectedTenant;
        }

    },
    async mounted() {
   
        this.isAgentActive();
        this.getTenants();
        this.getNotifications();
        this.store = useStore();
        this.value = this.languages.find(x => x.lang === 'en');
        this.text = this.value.title;
        this.flag = this.value.flag;
        this.userName = this.user.firstName;
        this.userEmail = this.user.emailAddress;
        if (!this.myStore.localTenant && this.user && this.user.tenants && this.user.tenants.length > 0) {
            this.myStore.localTenant = this.user.selectedTenant || this.user.tenants[0].Id;
        }

        this.initialTenant = myStore.localTenant;

        // socket io
        const { connectIO } = useSocketIO();
        await connectIO(this.userId);
    
    },
    unmounted() {
        //this.socket.off('connect_error');
    },
    methods: {
        ...mapActions('layout', {
            toggleWidgetSidebar: 'toggleWidgetSidebar',
        }),
       
        async getNotifications() {
            let result = await notificationService.getUnreadUserNotifications();
            this.$store.dispatch('toolBarNotification/clear');
            if (result?.result?.success) {
                let notify = result.data.notifications;

                for (let i = 0; i < notify.length; i++) {
                    let message = {
                        Icon: '',
                        HeaderText: notify[i].itemType,
                        BodyText: notify[i].message,
                        dateCreated: notify[i].dateCreated,
                    };
                    this.$store.dispatch('toolBarNotification/addRaw', message);
                }
            }
        },
        handleConnectivityChange(status) {
            this.onLine = status;
            this.$store.commit('offline/setOfflineStatus', !status);
        },
        async isAgentActive() {
            //TODO: add check on startup to see what last recorded entry was tyo set the initial state.

            try {
                let result = await agentService.getLatestAgentAction();
                if (_.isUndefined(result) || _.isEmpty(result.data.agentAction) || result.data.agentAction[0].action === 0) {
                    this.$store.commit('offline/setAgentStatus', false);
                    return false;
                }
                if (result && result.data.agentAction[0].action === 1) {
                    this.$store.commit('offline/setAgentStatus', true);
                    return true;
                } else {
                    return false;
                }
            } catch (e) {
                return false;
            }
        },
        /**
         * Toggle menu
         */

        /**
         * Toggle menu
         */
        toggleMenu() {
            document.querySelector('body').classList.toggle('sidebar-enable');
            this.$parent.toggleMenu();
        },
        initFullScreen() {
            
            document.body.classList.toggle('fullscreen-enable');
            this.isFullScreen = true;
            if (
                !document.fullscreenElement &&
                /* alternative standard method */
                !document.mozFullScreenElement &&
                !document.webkitFullscreenElement
            ) {
                // current working methods
                if (document.documentElement.requestFullscreen) {
                    document.documentElement.requestFullscreen();
                } else if (document.documentElement.mozRequestFullScreen) {
                    document.documentElement.mozRequestFullScreen();
                } else if (document.documentElement.webkitRequestFullscreen) {
                    document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
                }
            } else {
                this.isFullScreen = false;
                if (document.cancelFullScreen) {
                    document.cancelFullScreen();
                } else if (document.mozCancelFullScreen) {
                    document.mozCancelFullScreen();
                } else if (document.webkitCancelFullScreen) {
                    document.webkitCancelFullScreen();
                }
            }
        },
        async getTenants() {

       
            myStore.localTenant = this.currentUser.selectedTenant;
            let tenant;
            tenant = await this.getTenantName(myStore.localTenant)
 

            try {
                if (!_.isEmpty(this.user)) {

                    //if impersonation ended use localStorage to restore last user tenants
                    if(localStorage.getItem('impersonationStatus') == 'ended'){

                        const { data } = await tenantService.getSpecificTenantDescendantsByTenantId(localStorage.getItem('userTenant'));
                        this.tenants = data.tenants;
                        this.tenantsNodes = data.tenantsArranged;
                        this.setUserTenants(this.tenants);

                    }else{
                        const { data } = await tenantService.getAllTenantDescendantsByUserId();
                      
                        this.tenants = data.tenants;
                        this.tenantsNodes = data.tenantsArranged;
                        this.setUserTenants(this.tenants);

                    };
                } else {
                    console.log('User not logged in, cannot fetch tenants');
                }
            } catch (error) {
                console.log('Error loading topbar tenants.', error);
            }
        },
        closeTreeDropdown() {
            this.expandedKeys = {};
            document.querySelector('.dropdown-toggle').click();
        },
        async onNodeSelected(e) {
            try {
                let keyValue = e.Id;

                if (keyValue) {
                    await this.selectTenant(keyValue);
                    this.closeTreeDropdown();
                }
            } catch (e) {
                console.log('Invalid Tree Node', e);
            }
        },
        // onResetTenants() {
        //     //console.log(this.tenants, this.getDefaultTenantId());
        //     this.selectTenant(this.tenants.find((x) => x.Id === this.getDefaultTenantId()).name);
        //     this.closeTreeDropdown();
        //     this.showResetTenant = false;
        // },
        async selectTenant(id) {
            myStore.localTenant = id;

            //TODO: WORK on Id not name keep a note of which tenant was selected for use throughout the app
            if (!_.isEmpty(this.user)) {
                let selectedTenant = this.tenants.find(x => x.Id === id);
                this.$store.commit('dashboard/setTenant', selectedTenant.Id);
                await this.setSelectedTenantId(selectedTenant.Id);
                await this.saveUser();
            }

            return;
        },
    },
    
};
</script>
