import GeoServer from '../../services/geo.fence.service'
// import GeoServer from '../../helpers/geoserver/GeoServer';

// initial state
export const state = () => ({
    allGeoFences: [],
});

// getters
export const getters = {
    getGeoFence: state => id => {
        //console.log('Looking for ', id, state.allGeoFences.length);
        return state.allGeoFences.find(geoFence => geoFence.id == id);
    },
};

// actions
export const actions = {
    async getAllGeoFences({ commit }) {
        const { Id: userId } = JSON.parse(localStorage.getItem('user'));
        const geoFences = await GeoServer.getAllGeoFences(userId);
        const MaxNum = 1000;
        const fences = geoFences.slice(0, MaxNum);
        commit('setGeoFences', fences);
    },
    async getGeoFencesCash({ commit, getters }, geoFencesID) {
        const dev = getters.getGeoFence(geoFencesID);
        if (!dev) {
            const devDB = await GeoServer.getGeoFence(geoFencesID);
            commit('addGeoFences', [devDB]);
            return devDB;
        }
        return dev;
    },
    async createGeoFence({ commit }, newGeoFenceObj) {
        const geoFence = await GeoServer.createGeoFence(newGeoFenceObj);
        commit('addGeoFences', [geoFence]);
    },
    async updateGeoFenceAsync({ commit }, updateGeoFenceObj) {
        const geoFence = await GeoServer.updateGeoFence(
            updateGeoFenceObj.id,
            updateGeoFenceObj,
        );
        commit('updateGeoFence', [geoFence, updateGeoFenceObj.id]);
    },
    async deleteGeoFenceAsync({ commit }, deleteGeoFenceObj) {
        const geofences = await Promise.all(deleteGeoFenceObj.map(async fence => {
            await GeoServer.deleteGeoFence(fence.id);
            return fence.id;
        }))
        commit('deleteGeoFence', [geofences]);
    },
    async deletePOIAsync({ commit }, deletePOIObj) {
        const geofences = await Promise.all(deletePOIObj.map(async poi => {
            await GeoServer.deletePOI(poi.id);
            return poi.id;
        }))
        commit('deleteGeoFence', [geofences]);
    },
};

// mutations
export const mutations = {
    setGeoFences(state, geoFences) {
        state.allGeoFences = geoFences;
    },
    addGeoFences(state, geoFences) {
        geoFences.forEach(g => {
            state.allGeoFences.push(g);
        });
    },
    updateGeoFence(state, args) {
        const idx = state.allGeoFences.findIndex(
            geoFence => geoFence.id == args[1],
        );
        if (idx != -1) {
            state.allGeoFences[idx] = args[0];
        } else {
            state.allGeoFences.push(args[0]);
        }
    },
    // Not suer if this works properly
    deleteGeoFence(state, ids) {
        for (const id of ids) {
            const idx = state.allGeoFences.findIndex(
                geoFence => geoFence.id == id,
            );
            if (idx != -1) {
                state.allGeoFences.splice(idx, 1);
            }
        }
    },
};

// export default {
//   namespaced: true,
//   state,
//   getters,
//   actions,
//   mutations
// }
