import axios from '../helpers/api';

export const permissionsService = {
    getAllPermissions,
    getPermissionsByUserId,
    getPermissionById,
};

async function getAllPermissions() {
    try {
        const res = await axios('/api/permissions');
        return res.data;
    } catch (error) {
        return error;
    }
}

async function getPermissionsByUserId(id) {
    try {
        const res = await axios(`/api/permissions/byUserId/${id}`);
        return res.data;
    } catch (error) {
        return error;
    }
}

async function getPermissionById(id) {
    try {
        const res = await axios(`/api/permissions/${id}`);
        return res.data;
    } catch (error) {
        return error;
    }
}
