export const state = {
    offline: true,
    agent: false,
};

export const getters = {
    getOfflineStatus: state => {
        return state.offline;
    },
    getAgentStatus: state => {
        return state.agent;
    },
};

export const actions = {
    async updateOfflineStatus({ commit }, status) {
        commit('setOfflineStatus', status);
    },
    async updateAgentStatus({ commit }, status) {
        commit('setAgentStatus', status);
    },
};

export const mutations = {
    setOfflineStatus: (state, status) => {
        state.offline = status;
    },
    setAgentStatus: (state, status) => {
        state.agent = status;
    },
};
