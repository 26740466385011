import axios from '../helpers/api';
import helper from '../helpers/custom/index';
import { uuid } from 'vue-uuid';
import db from '@/services/pouchDb';
import { DateTime } from 'luxon';

export const roleService = {
    getAllRoles,
    getRoleById,
    getRolesByUserId,
    createRole,
    updateRole,
    deleteRole,
};

async function getAllRoles(page, size, sortField, sortOrder, search) {
    page = page || 1;
    size = size || 10000;
    sortField = sortField || 'dateCreated';
    sortOrder = sortOrder || 'DESC';
    search = search || '%%';
    if (navigator.onLine) {
        const res = await axios(`/api/roles?page=${page}&size=${size}&sortField=${sortField}&sortOrder=${sortOrder}&search=${search}`);
        return res.data;
    } else {
        try {
            const res = await db.globalsDB.allDocs({
                include_docs: true,
                attachments: true,
            });
            let roles = [];
            res.rows.forEach(row => {
                if (row.doc.role) roles.push(row.doc.role);
            });
            return {
                data: { roles },
                meta: {
                    order: {
                        sortBy: [
                            {
                                alias: sortField,
                                order: sortOrder,
                            },
                        ],
                    },
                    paging: {
                        pageNumber: page,
                        pageSize: size,
                        total: roles.length,
                    },
                },
                result: {
                    success: true,
                    message: 'Roles retrieved successfully.',
                },
            };
        } catch (error) {
            console.log(error);
        }
    }
}

async function deleteRole(id) {
    try {
        const res = await axios.delete(`/api/roles/${id}`);
        return res.data;
    } catch (error) {
        return error;
    }
}

async function getRoleById(id) {
    try {
        const res = await axios(`/api/roles/${id}`);
        return res.data;
    } catch (error) {
        return error;
    }
}

async function getRolesByUserId(id) {
    try {
        const res = await axios(`/api/roles/users/${id}`);
        return res.data;
    } catch (error) {
        return error;
    }
}

async function createRole(data) {
    data['Id'] = helper.checkId(data.Id) ? data.Id : uuid.v4();
    if (navigator.onLine) {
        const res = await axios.post('/api/roles', data);
        return res.data;
    } else {
        await db.globalsDB.put({
            _id: data['Id'],
            change: {
                entityId: data.Id,
                entityType: 'role',
                userId: helper.getUserId(),
                dateModified: DateTime.now().ToISO(),
                changeType: 'insert',
            },
        });
    }
}

async function updateRole(id, data) {
    const res = await axios.put(`/api/roles/${id}`, data);
    return res.data;
}
