export const state = () => ({
    selected: {},
    position: {},
    assetInfo: {}
});

export const mutations = {
    setSelectedDevice(state, devices) {
        //console.log("New Dispatch ",devices)
        state.selected = devices;
    },
    setSelectedDevicePosition(state, position) {
        state.position = position;
    },
};
