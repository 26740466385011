import GeoServer from '../../services/geo.eventIO.service';
// import GeoServer from '../../helpers/geoserver/GeoServer';
// initial state
export const state = () => ({
    allEvents: [],
});

// getters
export const getters = {
    getEvent: state => id => {
        //console.log('Looking for ', id, state.allEvents.length);
        return state.allEvents.find(event => event.id == id);
    },
};

// actions
export const actions = {
    async getAllEvents({ commit }) {
        const events = await GeoServer.getEvents();
        const MaxNum = 1000;
        const coms = events.slice(0, MaxNum);
        commit('setEvents', coms);
    },
    async getEventsCash({ commit, getters }, EventId) {
        const dev = getters.getEvent(EventId);
        if (!dev) {
            const devDB = await GeoServer.getEvent(EventId);
            commit('addEvents', [devDB]);
            return devDB;
        }
        return dev;
    },
    async createEvent({ commit }, newCommandObj) {
        const event = await GeoServer.createEvent(newCommandObj);
        commit('addEvents', [event]);
    },
    async updateEventAsync({ commit }, updateCommandObj) {
        const event = await GeoServer.updateEvent(updateCommandObj.id, updateCommandObj);
        commit('updateEvent', [event, updateCommandObj.id]);
    },
    async deleteEventAsync({ commit }, deleteCommandObj) {
        const event = await GeoServer.deleteEvent(deleteCommandObj.id, deleteCommandObj);
        commit('deleteEvent', [event, deleteCommandObj.id]);
    },
};

// mutations
export const mutations = {
    setEvents(state, events) {
        state.allEvents = events;
    },
    addEvents(state, events) {
        events.forEach(g => {
            state.allEvents.push(g);
        });
    },
    updateEvent(state, args) {
        const idx = state.allEvents.findIndex(event => event.id == args[1]);
        if (idx != -1) {
            state.allEvents[idx] = args[0];
        } else {
            state.allEvents.push(args[0]);
        }
    },
    deleteEvent(state, args) {
        const idx = state.allEvents.findIndex(event => event.id == args[1]);
        if (idx != -1) {
            state.allEvents.splice(idx, 1);
        }
    },
};

// export default {
//   namespaced: true,
//   state,
//   getters,
//   actions,
//   mutations
// }
