import axios from '../helpers/api';
import db from '@/services/pouchDb';
import _ from 'lodash';
import moment from 'moment';
const { v4: uuidv4 } = require('uuid');
const user = JSON.parse(localStorage.getItem('user'));
import { DateTime } from 'luxon';

export const geoDeviceService = {
    getDevices,
    getAllDevices,
    getAssetDevices,
    getUnlinkedDevices,
    getDeviceById,
    getDeviceLatestPosition,
    updateDevice,
    createDevice,
    deleteDevice,
    getDeviceTrips,
    findDevice,
    getAddress,
    getDevicePermissions,
    sendCommand,
    linkGeoFenceToDevice,
    unlinkGeoFenceFromDevice,
    getDevicePermissionsByDeviceId,
    uniqueIdExsits,
    getDeviceUsageReport,
    getDeviceFullPositionReport,
    getHardwareTypes,
    getDeviceBatteryReport,
    getAllActiveDevices,
    getActiveDevicesByTenant,
    getActiveAssetsByTenant,
    getDeviceStats,
    countSMSUsed,
};

async function getDevices(page, size, sortField, sortOrder, search, filter, date) {
    page = page || 1;
    size = size || 10000;
    sortField = sortField || 'name';
    sortOrder = sortOrder || 'DESC';
    search = search || '';
    search = search.toUpperCase().trim();
    filter = filter || '';
    date = date || ['',''];

    if (navigator.onLine) {
        const res = await axios.get(
            `/api/geo/device/${user.Id}?page=${page}&size=${size}&sortField=${sortField}&sortOrder=${sortOrder}&search=${search}&filter=${filter}&date=${date[0]}&date=${date[1]}`,
        );
        return res.data;
    } else {
        if (!db.userDB || _.isEmpty(db.userDB)) {
            db.userDB = await db.refreshUserDb();
        }
        const { rows } = await db.userDB.allDocs({
            include_docs: true,
            attachments: true,
        });
        const geoDevices = [];
        rows.forEach(row => {
            if (row.doc.mapDevice) {
                geoDevices.push(row.doc.mapDevice);
            }
        });
        return {
            result: {
                success: true,
                message:
                    'Geo Devices loaded and latest position info of active devices',
            },
            data: {
                geoDevices,
            },
        };
    }
}

async function getAllDevices() {
    if (navigator.onLine) {
        const res = await axios(`/api/geo/device/map/${user.Id}`);
        return res.data;
    } else {
        if (!db.userDB || _.isEmpty(db.userDB)) {
            db.userDB = await db.refreshUserDb();
        }
        const { rows } = await db.userDB.allDocs({
            include_docs: true,
            attachments: true,
        });
        const geoDevices = [];
        rows.forEach(row => {
            if (row.doc.mapDevice) {
                geoDevices.push(row.doc.mapDevice);
            }
        });
        return {
            result: {
                success: true,
                message:
                    'Geo Devices loaded and latest position info of active devices',
            },
            data: {
                geoDevices,
            },
        };
    }
}

async function getDeviceById(id) {
    if (navigator.onLine) {
        const res = await axios(`/api/geo/device/details/${id}`);
        return res.data;
    } else {
        console.debug("add this");
        // if (!db.userDB || _.isEmpty(db.userDB)) {
        //     db.userDB = await db.refreshUserDb();
        // }
        // const { rows } = await db.userDB.allDocs({
        //     include_docs: true,
        //     attachments: true,
        // });
        // const devices = [];
        // rows.forEach(row => {
        //     if (row.doc.assetDevices) {
        //         devices.push(row.doc.assetDevices);
        //     }
        // });
        // return {
        //     result: {
        //         success: true,
        //         message:
        //             'Devices loaded and latest position info of active devices',
        //     },
        //     data: {
        //         devices,
        //     },
        // };
    }
}
async function getDeviceLatestPosition(DeviceID) {
    if (navigator.onLine) {
        const res = await axios(`/api/geo/device/details/${DeviceID}/latest-position`);
        return res.data;
    } 
    //Should only work if online
    return null;
}

async function linkGeoFenceToDevice(DeviceId, GeoFenceId) {
    const request = { Id: uuidv4(), deviceId: DeviceId, geofenceId: GeoFenceId };
    const res = await axios.post(`/api/geo/permissions/`, request);
    return res.data;
}

async function unlinkGeoFenceFromDevice(DeviceId, GeoFenceId) {
    const request = {
        data: { deviceId: DeviceId, geofenceId: GeoFenceId },
    };
    const res = await axios.delete(
        `/api/geo/permissions/`,
        request,
    );
    return res.data;
}

async function sendCommand(DeviceId, CommandId, messageObj) {
    const res = await axios.post(
        `/api/geo/device/sendCommand/${DeviceId}/${CommandId}`,
        messageObj
    );
    return res.data;
}

async function getDevicePermissions(Id) {
    const res = await axios.get(`/api/geo/permissions/${Id}`);
    // console.debug('getDevicePermissions', res.data);
    return res.data;
}

async function getDevicePermissionsByDeviceId(Id) {
    const res = await axios.get(`/api/geo/permissions/device/${Id}`);
    // console.debug('getDevicePermissions', res.data);
    return res.data;
}

async function getAddress(positionId) {
    let res = await axios.get(`/api/geo/device/position/${positionId}`);

    // console.log(res.data)
    return res.data;
}

async function findDevice(search) {
    try {
        let res = await axios.get(`/api/geo/device/search/${encodeURI(search)}`);
        // console.log(res.data)
        return res.data;
    } catch (error) {
        console.debug(error);
    }
}

async function getDeviceTrips(DeviceId, FromDate, ToDate) {
    let query = {
        deviceId: DeviceId,
        fromDate: moment(FromDate).format('YYYY-MM-DD'),
        toDate: moment(ToDate).format('YYYY-MM-DD'),
    };
    let res = await axios.get(`/api/geo/device/trip/details`,
        { params: query },
    );
    console.debug(res.data);
    return res.data;
}

async function createDevice(device) {
    device['userId'] = user.Id;
    if (navigator.onLine) {
        const res = await axios.post(`/api/geo/device`, device);
        return res.data;
    } else {
        console.debug("doesn't exist")
    }
}

async function updateDevice(Id, device) {
    if (navigator.onLine) {
        const res = await axios.put(`/api/geo/device/${Id}`, device);
        return res.data;
    } else {
        console.debug("doesn't exist")
    }
}

async function deleteDevice(Id) {
    if (navigator.onLine) {
        const res = await axios.put(`/api/geo/device/delete/${Id}`);
        return res.data;
    } else {
        console.debug("doesn't exist")
    }
}

async function getAssetDevices(assetInfoId) {
    if (navigator.onLine) {
        const res = await axios(`/api/geo/asset/devices/${assetInfoId}`);
        return res.data;
    } else {
        if (!db.userDB || _.isEmpty(db.userDB)) {
            db.userDB = await db.refreshUserDb();
        }
        const { rows } = await db.userDB.allDocs({
            include_docs: true,
            attachments: true,
        });
        const geoDevices = [];
        rows.forEach(row => {
            if (row.doc.assetDevices) {
                geoDevices.push(row.doc.assetDevices);
            }
        });
        return {
            result: {
                success: true,
                message:
                    'Geo Devices loaded and latest position info of active devices',
            },
            data: {
                geoDevices,
            },
        };
    }
}
async function getUnlinkedDevices(page, size, sortField, sortOrder, search) {

    page = page || 1;
    size = size || 10000;
    sortField = sortField || 'dateCreated';
    sortOrder = sortOrder || 'DESC';
    search = search || '';
    search = search.toUpperCase().trim();

    

    if (navigator.onLine) {

        const res = await axios.get(
            `/api/geo/device/unlinked-device?page=${page}&size=${size}&sortField=${sortField}&sortOrder=${sortOrder}&search=${search}`
        );
        return res.data;
    } else {
        //ToDo

    }
}

async function uniqueIdExsits(uniqueId) {
    if (navigator.onLine) {
        const res = await axios.get(`/api/geo/device/exists/${uniqueId}`);
        return res.data;
    }
}

async function getDeviceUsageReport(deviceId, startDate, endDate, page, size, 
    sortField = 'dateCreated', sortOrder = 'DESC',  search = '%%') {
    page = page || 1;
    size = size || 10000;
    sortField = sortField || 'dateCreated';
    sortOrder = sortOrder || 'DESC';
    startDate = startDate || DateTime.now().plus({ days: -1 }).toISO();
    endDate = endDate || DateTime.now().plus({ days: 7 }).toISO();
    if (navigator.onLine) {
        try {
            const res = await axios.get(`/api/geo/device-asset-history/?deviceId=${deviceId}&startDate=${startDate}&endDate=${endDate}&page=${page}&size=${size}&sortField=${sortField}&sortOrder=${sortOrder}&search=${search}`);
            console.log(res)
            return res;
        } catch (error) {
            console.debug(error);
            return error;
        }
    } else {
        //ToDo
    }
}
async function getDeviceFullPositionReport(deviceId, startDate, endDate, page, size,
    sortField = 'deviceTime', sortOrder = 'ASC',  search = '%%') {
    page = page || 1;
    size = size || 10000;
    sortField = sortField || 'deviceTime';
    sortOrder = sortOrder || 'ASC';
    startDate = startDate || DateTime.now().plus({ days: -1 }).toISO();
    endDate = endDate || DateTime.now().plus({ days: 7 }).toISO();
    if (navigator.onLine) {
        try {
            const res = await axios.get(`/api/geo/position/device/${deviceId}/${startDate}/${endDate}/?&page=${page}&size=${size}&sortField=${sortField}&sortOrder=${sortOrder}&search=${search}`);
            return res;
        } catch (error) {
            console.debug(error);
            return error;
        }
    } else {
        //ToDo
    }
}

async function getHardwareTypes(page, size, sortField, sortOrder, search) {
    page = page || 1;
    size = size || 10000;
    sortField = sortField || 'make';
    sortOrder = sortOrder || 'ASC';
    search = search || '%%';
    const res = await axios.get(
        `/api/geo/device-hardware?page=${page}&size=${size}&sortField=${sortField}&sortOrder=${sortOrder}&search=${search}`,
    );
    return res.data;
}

async function getDeviceBatteryReport(startDate, endDate, page, size, sortField = 'deviceTime',
                                      sortOrder = 'ASC', search = '%%') {
    page = page || 1;
    size = size || 10000;
    sortField = sortField || 'deviceTime';
    sortOrder = sortOrder || 'ASC';
    startDate = startDate || DateTime.now().plus({ days: -1 }).toISO();
    endDate = endDate || DateTime.now().plus({ days: 7 }).toISO();
    if (navigator.onLine) {
        try {
            const res = await axios.get(`/api/geo/assets/battery/${startDate}/${endDate}/?&page=${page}&size=${size}&sortField=${sortField}&sortOrder=${sortOrder}&search=${search}`);
            // console.log(res);
            return res;
        } catch (error) {
            console.debug(error);
            return error;
        }
    } else {
        //ToDo
    }
}

async function getAllActiveDevices() {
    if (navigator.onLine) {
        const res = await axios(`/api/geo/device/active`);
        return res.data;
    }
    return null;
}


async function getActiveDevicesByTenant() {
    if (navigator.onLine) {
        const res = await axios(`/api/geo/device/active/by-tenant`);
        return res.data;
    }
    return null;
}
async function getActiveAssetsByTenant() {
    if (navigator.onLine) {
        const res = await axios(`/api/geo/asset/reports/active`);
        return res.data;
    }
    return null;
}

async function getDeviceStats() {
    if (navigator.onLine) {
        const res = await axios(`/api/geo/device/dashboard/stats`);
        return res.data;
    }
    return null;
}

async function countSMSUsed() {
    if (navigator.onLine) {
        const res = await axios(`/api/geo/device/dashboard/sms-used`);
        return res.data;
    }
    return null;
}
