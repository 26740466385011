import toolBarNotification from '../../helpers/ToolBarNotification';

export const state = () => ({
    all: [],
    devices: {},
    Position: {},
    Position_Update: [],
    EventList: [],
    // Notifications for Loan Officers and Branch Manager
    ActivityAssigned: [], // LO
    ActivityStatusChanged: [], // LO & BM
    ActivityCommented: [], // LO & BM
});

export const mutations = {
    activity(state, activities) {
        const { commit } = this;
        activities.forEach(element => {
            if (activities(element.id) != null) {
                // console.log('devices=>Find Device',device(element.id));
                commit('activity/updateActivity', [element, element.id]);
                state.activities[element.id] = element;
            }
        });
    },
    devices(state, devices) {
        const device = this.getters['device/getDevice'];
        const { commit } = this;
        devices.forEach(element => {
            if (device(element.id) != null) {
                // console.log('devices=>Find Device',device(element.id));
                commit('device/updateDevice', [element, element.id]);
                state.devices[element.id] = element;
            }
        });
    },
    positions(state, position) {
        state.Position_Update = [];
        const device = this.getters['device/getDevice'];
        const { commit } = this;
        position.forEach(s => {
            const deviceInfo = device(s.deviceId);
            if (deviceInfo != null) {
                deviceInfo.position = s;
                commit('device/updateDevice', [deviceInfo, deviceInfo.id]);
                // console.log('positions=>Find Device',deviceInfo)
                state.Position[s.deviceId] = s;
                state.Position_Update.push(convertPos(s, deviceInfo));
            }
        });
    },
    events__olded(state, _events) {
        const { commit } = this;
        const device = this.getters['device/getDevice'];
        const geofence = this.getters['geofence/getGeoFence'];
        _events.forEach(ev => {
            const deviceInfo = device(ev.deviceId);
            const geofenceInfo = geofence(ev.geofenceId);

            if (deviceInfo != null && geofenceInfo != null) {
                let textHeader = '';
                switch (ev.type) {
                    case 'geofenceExit':
                        textHeader = 'Exit';
                        break;
                    case 'geofenceEnter':
                        textHeader = 'Enter';
                        break;
                    default:
                        break;
                }
                commit(
                    'toolBarNotification/add',
                    toolBarNotification.createNotification(
                        '',
                        deviceInfo.name,
                        `${geofenceInfo.name} ${textHeader}`,
                    ),
                );
            }
            state.EventList.push(ev);
        });
    },
};
function convertPos(Position, device) {
    return {
        deviceId: Position.deviceId,
        latitude: Position.latitude,
        longitude: Position.longitude,
        altitude: Position.altitude,
        speed: Position.speed,
        course: Position.course,
        accuracy: Position.accuracy,
        category: device.category,
    };
}
export const getters = {
    getDevice: state => id => {
        // console.log('Looking for ',id,state.all.length)
        return state.all.find(device => device.id == id);
    },
};
export const actions = {
    async events(_, _events) {
        const RootCommit = this.commit;
        // commit('events',events)
        const { dispatch } = this;
        //  console.log(' Action Events ',_events);
        for (let i = 0; i < _events.length; i++) {
            const ev = _events[i];
            // console.log(' Action Event item ',_events[i]);
            const deviceInfo = await dispatch(
                'device/getDevicesCash',
                ev.deviceId,
            );
            const geofenceInfo = await dispatch(
                'geofence/getGeoFencesCash',
                ev.geofenceId,
            );
            if (deviceInfo != null && geofenceInfo != null) {
                let textHeader = '';
                switch (ev.type) {
                    case 'geofenceExit':
                        textHeader = 'Exit';
                        break;
                    case 'geofenceEnter':
                        textHeader = 'Enter';
                        break;
                    default:
                        break;
                }
                RootCommit(
                    'toolBarNotification/add',
                    toolBarNotification.createNotification(
                        '',
                        deviceInfo.name,
                        `${geofenceInfo.name} ${textHeader}`,
                    ),
                );
            }
        }
    },
};

// export default {
//   namespaced: true,
//   state,
//   getters,
//   actions,
//   mutations
// }
