import axios from '../helpers/api';
import db from '@/services/pouchDb';
const user = JSON.parse(localStorage.getItem('user'));

export const templateService = {
    getAllTemplates,
    getTemplateById,
    createTemplate,
    updateTemplate,
    deleteTemplate,
};

async function getAllTemplates(page, size, sortField = 'dateCreated', sortOrder = 'DESC', search) {
    page = page || 1;
    size = size || 10000;
    sortField = sortField || 'dateCreated';
    sortOrder = sortOrder || 'DESC';
    search = search || '%%';
    if (navigator.onLine) {
        const res = await axios(
            `/api/templates/userId/${user.Id}?page=${page}&size=${size}&sortField=${sortField}&sortOrder=${sortOrder}&search=${search}`,
        );
        return res.data;
    } else {
        try {
            const res = await db.globalsDB.allDocs({
                include_docs: true,
                attachments: true,
            });
            let templates = [];
            res.rows.forEach(row => {
                if (row.doc.template) templates.push(row.doc.template);
            });
            return {
                data: { templates },
                meta: {
                    order: {
                        sortBy: [
                            {
                                alias: sortField,
                                order: sortOrder,
                            },
                        ],
                    },
                    paging: {
                        pageNumber: page,
                        pageSize: size,
                        total: templates.length,
                    },
                },
                result: {
                    success: true,
                    message: 'Templates retrieved successfully.',
                },
            };
        } catch (error) {
            console.log(error);
        }
    }
}

async function getTemplateById(id) {
    try {
        const res = await axios(`/api/templates/${id}`);
        return res.data;
    } catch (error) {
        return error;
    }
}

async function createTemplate(data) {
    const headers = {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    };

    const form = new FormData();

    if (data.doc && data.doc.length > 0) {
        form.append('document', data.doc);
    }

    try {
        let res;
        if (data.doc && data.doc.length > 0) {
            res = await axios.post('/api/templates', form, headers);
        } else {
            res = await axios.post('/api/templates', data);
        }
        return res.data;
    } catch (error) {
        return error;
    }
}

async function updateTemplate(id, data) {
    const headers = {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    };

    const form = new FormData();

    if (data.doc && data.doc.length > 0) {
        form.append('document', data.doc);
    }

    try {
        let res;
        if (data.doc && data.doc.length > 0) {
            res = await axios.put(`/api/templates/${id}`, form, headers);
        } else {
            res = await axios.put(`/api/templates/${id}`, data);
        }
        return res.data;
    } catch (error) {
        return error;
    }
}

async function deleteTemplate(id) {
    try {
        const res = await axios.delete(`/api/templates/${id}`);
        return res.data;
    } catch (error) {
        return error;
    }
}
