import axios from '../helpers/api';

export default {
    async getEvents(page, size, sortField, sortOrder, search) {
        page = page || 1;
        size = size || 10000;
        sortField = sortField || 'name';
        sortOrder = sortOrder || 'DESC';
        search = search || '';
        search = search.toUpperCase().trim();

        const res = await axios.get(
            `/api/geo/eventIos?page=${page}&size=${size}&sortField=${sortField}&sortOrder=${sortOrder}&search=${search}`,
        );
        //console.log('List of all events', res.data)
        return res.data;
    },
    async deleteEvent(EventId, EventObj) {
        const res = await axios.delete(`/api/geo/eventIos/${EventId}`, EventObj);

        return res.data;
    },
    async updateEvent(EventId, EventObj) {
        const res = await axios.put(`/api/geo/eventIos/${EventId}`, EventObj);

        // console.log(res.data)
        return res.data;
    },
    async createEvent(EventObj) {
        EventObj.type = 'event';
        const res = await axios.post(`/api/geo/eventIos`, EventObj);

        return res.data;
    },
    async getEventById(id) {
        const res = await axios.get(`/api/geo/eventIos/${id}`);

        // console.log(res.data)
        return res.data;
    },
};
