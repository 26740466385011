import axios from '../helpers/api';
import _ from 'lodash';
import { DateTime } from 'luxon';
// import db from '@/services/pouchDb';
import db from './pouchDb';
import helper from '../helpers/custom/index';
import { userActivityLoggerService } from '@/services/userActivityLogger.service';
import { errorLogService } from '@/services/error_log.service';
const { version } = require('./../../package.json');
import { v4 as uuidv4 } from 'uuid';
import Toastify from 'toastify-js';

export const agentService = {
    insertAgentAction,
    getAgentAction,
    getAgentActionReport,
    getAgentActionLocation,
    getLatestAgentAction,
    getManagerAgentActionsReport,
};

async function insertAgentAction(data) {
    try {
        data.Id = helper.checkId(data.Id) ? data.Id : uuidv4();
      
        if (navigator.onLine) {
            try {
                const res = await axios.post('/api/agent/action', data);
                let track = await userActivityLoggerService.createTracking(uuidv4(), data.Id, 'agentAction');
      
                if (!track.success) {
                    errorLogService.insertErrorLog(
                        uuidv4(),
                        data.performedByUserId,
                        data.Id,
                        'agentAction',
                        JSON.stringify(track.error),
                        DateTime.now().toISO(),
                    );

                    Toastify({
                        text: 'Unable to log GPS Position! Please activate your GPS.',
                        className: 'info',
                        style: {
                            background: 'linear-gradient(to right, #00b09b, #96c93d)',
                        },
                    }).showToast();
                }

                return res.data;
            } catch (error) {
                console.log('update agent action error ', error);
                return error;
            }
        } else {
            if (!db.userDB || _.isEmpty(db.userDB)) {
                await db.refreshUserDb();
            }

            //SAVE actionData
            let doc = {
                _id: data.Id,
                agentAction: data,
            };

            let track = await userActivityLoggerService.createTracking(uuidv4(), data.Id, 'agentAction');

            if (track && track.success) {
                doc.track = track;
            } else {
                doc.track = { success: false };
            }
            doc.from = 'offline';
            await db.userDB.put(doc);

            let change_id = uuidv4();

            await db.globalsDB.put({
                _id: change_id,
                change: {
                    version: version,
                    entityId: data.Id,
                    userId: helper.getUserId(),
                    entityType: 'agentAction',
                    dateModified: DateTime.now().toISO(),
                    changeType: 'INSERT',
                },
            });
            return {
                success: true,
                data: {
                    agentAction: data,
                },
            };
        }
    } catch (error) {
        console.log(error);
        return {
            success: false,
            data: {
                agentAction: { Id: '' },
            },
            error: error,
        };
    }
}

/** Get Agent Actions */
async function getLatestAgentAction() {
    if (navigator.onLine) {
        try {
            const res = await axios.get(`/api/agent/action/latest`);
            return res.data;
        } catch (error) {
            return error;
        }
    } else {
        try {
            if (!db.userDB || _.isEmpty(db.userDB)) {
                db.userDB = await db.refreshUserDb();
            }
            const res = await db.userDB.allDocs({
                include_docs: true,
                attachments: true,
            });
            let agentActions = [];
            let latestAction = null;
            res.rows.forEach(row => {
                if (row.doc.agentAction) {
                    if (_.isNull(latestAction)) {
                        latestAction = row.doc.agentAction;
                    }
                    if (new Date(latestAction.dateCreated) < new Date(row.doc.agentAction.dateCreated)) {
                        latestAction = row.doc.agentAction;
                    }
                }
            });

            let success = !_.isNull(latestAction);

            if (success) {
                agentActions.push(latestAction);
            }

            let temp = {
                data: {
                    agentAction: agentActions,
                },
                meta: {
                    paging: {
                        pageNumber: 1,
                        pageSize: 1,
                        total: agentActions.length,
                    },
                },
                result: {
                    success: success,
                    message: 'Latest agent actions retrieved successfully.',
                },
            };
            return temp;
        } catch (error) {
            return {
                data: {
                    agentAction: [],
                },
                meta: {
                    paging: { pageNumber: 1, pageSize: 0, total: 0 },
                },
                result: {
                    success: false,
                    message: 'Unable to retrieve latest agent actions retrieved successfully.',
                },
            };
        }
    }
}

/** Get Agent Actions */
async function getAgentAction(userId, startDate, page, size, sortField, sortOrder) {
    page = page || 1;
    size = size || 10000;
    sortField = sortField || 'dateCreated';
    sortOrder = sortOrder || 'DESC';

    if (navigator.onLine) {
        try {
            if (_.isNull(startDate)) {
                startDate = DateTime.now().toISO();
            }
            const res = await axios.get(
                `/api/agent/${userId}/${startDate}?page=${page}&size=${size}&sortField=${sortField}&sortOrder=${sortOrder}`,
            );
            return res.data;
        } catch (error) {
            return error;
        }
    } else {
        try {
            if (!db.userDB || _.isEmpty(db.userDB)) {
                db.userDB = await db.refreshUserDb();
            }
            const res = await db.userDB.allDocs({
                include_docs: true,
                attachments: true,
            });
            let agentActions = [];

            res.rows.forEach(row => {
                if (row.doc.agentAction) {
                    agentActions.push(row.doc.agentAction);
                }
            });

            let temp = {
                data: {
                    agentAction: agentActions,
                },
                meta: {
                    paging: {
                        pageNumber: 1,
                        pageSize: 1,
                        total: agentActions.length,
                    },
                },
                result: {
                    success: true,
                    message: 'Agent actions retrieved successfully.',
                },
            };
            return temp;
        } catch (error) {
            return error;
        }
    }
}

async function getAgentActionLocation(locationId) {
    if (navigator.onLine) {
        try {
            const res = await axios.get(`/api/agent/action/location/${locationId}`);
            return res.data;
        } catch (error) {
            return error;
        }
    } else {
        return {
            data: [],
        };
    }
}

/** Get the actions of all the agents a user (team leader/ branch leader has access too */
async function getAgentActionReport(startDate, endDate, page, size, sortField, sortOrder, search, download = false) {
    page = page || 1;
    size = size || 1000000;
    sortField = sortField || 'dateCreated';
    sortOrder = sortOrder || 'DESC';
    search = search || '%%';
    if (navigator.onLine) {
        try {
            if (_.isNull(startDate)) startDate = DateTime.now().toISO();

            const res = await axios.get(
                `/api/agent/actions/${startDate}/${endDate}?page=${page}&size=${size}&sortField=${sortField}&sortOrder=${sortOrder}&search=${search}&download=${download}`,
            );
            return res.data;
        } catch (error) {
            return error;
        }
    } else {
        try {
            if (!db.userDB || _.isEmpty(db.userDB)) {
                db.refreshUserDb();
            }
            const res = await db.userDB.allDocs({
                include_docs: true,
                attachments: true,
            });
            let agentActions = [];
            res.rows.forEach(row => {
                if (row.doc.agentAction) {
                    agentActions.push(row.doc.agentAction);
                }
            });
            let temp = {
                data: {
                    agentAction: agentActions,
                },
                meta: {
                    paging: {
                        pageNumber: page,
                        pageSize: size,
                        total: agentActions.length,
                    },
                    order: {
                        sortBy: [
                            {
                                alias: sortField,
                                order: sortOrder,
                            },
                        ],
                    },
                },
                result: {
                    success: true,
                    message: 'Agent actions retrieved successfully.',
                },
            };
            return temp;
        } catch (error) {
            let temp = {
                data: {
                    agentAction: [],
                },
                meta: {
                    paging: {
                        pageNumber: 0,
                        pageSize: 1,
                        total: 0,
                    },
                    order: {
                        sortBy: [
                            {
                                alias: sortField,
                                order: sortOrder,
                            },
                        ],
                    },
                },
                result: {
                    success: false,
                    message: 'Agent actions error: ' + error,
                },
            };
            return temp;
        }
    }
}
async function getManagerAgentActionsReport(startDate, endDate, page, size, sortField, sortOrder) {
    page = page || 1;
    size = size || 10000;
    sortField = sortField || 'dateCreated';
    sortOrder = sortOrder || 'DESC';
    if (navigator.onLine) {
        try {
            if (_.isNull(startDate)) startDate = DateTime.now().toISO();

            const res = await axios.get(
                `/api/agent/actions/${startDate}/${endDate}?page=${page}&size=${size}&sortField=${sortField}&sortOrder=${sortOrder}`,
            );
            return res.data;
        } catch (error) {
            return error;
        }
    } else {
        try {
            if (!db.userDB || _.isEmpty(db.userDB)) {
                db.refreshUserDb();
            }

            const res = await db.userDB.allDocs({
                include_docs: true,
                attachments: true,
            });
            let agentActions = [];
            res.rows.forEach(row => {
                if (row.doc.agentAction) {
                    agentActions.push(row.doc.agentAction);
                }
            });
            let temp = {
                data: {
                    agentAction: agentActions,
                },
                meta: {
                    paging: {
                        pageNumber: page,
                        pageSize: size,
                        total: agentActions.length,
                    },
                },
                result: {
                    success: true,
                    message: 'Agent actions retrieved successfully.',
                },
            };
            // console.log("offline activities: ",temp);
            return temp;
        } catch (error) {
            let temp = {
                data: {
                    agentAction: [],
                },
                meta: {
                    paging: {
                        pageNumber: 0,
                        pageSize: 1,
                        total: 0,
                    },
                    order: {
                        sortBy: [
                            {
                                alias: sortField,
                                order: sortOrder,
                            },
                        ],
                    },
                },
                result: {
                    success: false,
                    message: 'Agent actions error: ' + error,
                },
            };
            return temp;
        }
    }
}
