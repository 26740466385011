export default {
    data() {
        return {
            // refresh variables
            refreshing: false,
            registration: null,
            updateExists: false,
            updated: false,
        };
    },

    created() {
        // Listen for our custom event from the SW registration
        document.addEventListener('swUpdated', this.updateAvailable, {
            once: true,
        });

        // Prevent multiple refreshes
        // navigator.serviceWorker.addEventListener('controllerchange', () => {
        //   if (this.refreshing) return
        //   this.refreshing = true
        //   // Here the actual reload of the page occurs
        //   window.location.reload()
        // })
    },

    methods: {
        // Store the SW registration so we can send it a message
        // We use `updateExists` to control whatever alert, toast, dialog, etc we want to use
        // To alert the user there is an update they need to refresh for
        updateAvailable(event) {
            this.registration = event.detail;
            this.updateExists = true;

            if (!this.updated) {
                this.$swal({
                    titleText: 'Update Available',
                    text: "A new update is available. Click 'OK' to reload now, or cancel to save your work!",
                    showConfirmButton: true,
                    showCancelButton: true,
                    showCloseButton: false,
                }).then(result => {
                    //console.log(result)
                    if (result.isConfirmed) {
                        this.refreshCacheAndReload();
                    }
                });
            }

        },
        // Called when the user accepts the update
        refreshCacheAndReload() {
            if ('serviceWorker' in navigator) {
                if (navigator.serviceWorker) {
                    navigator.serviceWorker
                        .getRegistrations()
                        .then(async function (registrations) {
                            for (let registration of registrations) {
                                console.log('Current SW unregistered');
                                registration.unregister();
                            }
                            let cacheNames = await caches.keys();
                            for (let i = 0; i < cacheNames.length; i++) {
                                console.log('Deleting Cache', cacheNames[i]);
                                await caches.delete(cacheNames[i]);
                                console.log('Cache Deleted', cacheNames[i]);
                            }
                        })
                        .then(() => {
                            console.log('Registering new SW');
                            navigator.serviceWorker.register(
                                '/service-worker.js',
                            );
                        });
                }
            }
        },
    },
};
