export const state = {
    tenant: '',
};

export const getters = {
    getTenant: state => {
        return state.tenant;
    },
};

export const mutations = {
    setTenant: (state, id) => {
        state.tenant = id;
    },
};
