<template>
    <div>
        <component :is="this.$route.meta.layout || 'div'">
            <Toast position="top-right" class="toast-wrapper">
                <template #message="slotProps">
                    <div class="w-100">
                        <span class="p-toast-summary">{{
                            slotProps.message.summary
                        }}</span>
                        <div
                            class="p-toast-detail"
                            v-html="slotProps.message.detail"
                        ></div>
                    </div>
                </template>
            </Toast>
            <router-view />
        </component>
    </div>
</template>

<script>
import appConfig from '@/app.config';
import { notificationMethods } from '@/state/helpers';
import { firebaseService } from '@/services/firebase.service';

import Toast from 'primevue/toast';
import update from './mixins/update';

export default {
    name: 'app',
    components: {
        Toast,
    },
    page: {
        // All subcomponent titles will be injected into this template.
        titleTemplate(title) {
            title = typeof title === 'function' ? title(this.$store) : title;
            return title ? `${title} | ${appConfig.title}` : appConfig.title;
        },
    },
    data() {
        return {
            boundaries: [],
            locationWatchId: 0,
            noLocation: true,
            isDevice: false,
            firebase: {
                error: null,
                message: null,
            },
        };
    },
    mixins: [update],
    methods: {
        clearNotification: notificationMethods.clear,
        setupLocationWatch() {
            // this.locationWatchId = this.$watchLocation({
            //     enableHighAccuracy: true,
            //     timeout: 5000,
            //     maximumAge: 15000,
            // }).then((coordinates) => {
            //     //get the logged in user details.
            //     //record the position change for the user in the DB...
            //     //first try post to the live API if connectivity is available
            //     //if not, post to PouchDB and wait for backend Sync...
            //     //console.log('Watching', coordinates);
            // });
        },
        onGeolocationSuccess(position) {
            // const { latitude, longitude } = position.coords;
            // TODO: console.log to be removed when values are in use.
            //console.log('GEO POSITION', { latitude, longitude });
        },
        onGeolocationError(error) {
            //  const toast = useToast();

            switch (error.code) {
                case error.PERMISSION_DENIED:
                    console.error('User denied the request for Geolocation.');
                    break;

                case error.POSITION_UNAVAILABLE: {
                    // toast.add({
                    // text: "Unable to log GPS Position! Please activate your GPS to enable tracking.",
                    // className: "info",
                    // style: {
                    //     background: "linear-gradient(to right, #00b09b, #96c93d)",
                    // }
                    // });
                    console.error('Location information is unavailable.');
                    break;
                }

                case error.TIMEOUT:
                    console.error(
                        'The request to get user location timed out.',
                    );
                    break;

                case error.UNKNOWN_ERROR:
                    console.error('An unknown error occurred.');
                    break;
            }
            // TODO: console.log to be removed when values are in use.
            //console.log('GEO ERROR', error);
        },
        async onFirebaseGetToken(token) {
            try {
                await firebaseService.registerDevice({ token });
            } catch (error) {
                this.firebase.error = error;
            }
        },
        onFirebaseMessageReceived(message) {
            this.firebase.message = message;
        },
        onDeviceReady() {
            this.isDevice = true;
            // if ("geolocation" in navigator || navigator.geolocation){
            //     navigator.geolocation.getCurrentPosition(this.onGeolocationSuccess, this.onGeolocationError,{
            //         enableHighAccuracy: true,
            //         timeout: 15000,
            //         maximumAge: 15000
            //     });
            // }

            if (window.FirebasePlugin && localStorage.getItem('token')) {
                window.FirebasePlugin.getToken(this.onFirebaseGetToken);
                window.FirebasePlugin.onMessageReceived(
                    this.onFirebaseMessageReceived,
                );
            }
        },
    },
    watch: {
        /**
         * Clear the alert message on route change
         */
        // eslint-disable-next-line no-unused-vars
        $route(to, from) {
            // clear alert on location change
            this.clearNotification();
        },
    },
    mounted() {
        // document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");

        this.setupLocationWatch();
    },
    destroyed() {},
};
</script>
