import axios from '../helpers/api';
import db from '@/services/pouchDb';

export const clientsService = {
    get,    
    getPersonByMobilePhone,
    getClients,
    getClientsForAffordability,
    searchClients,
    createLocalClient,
    createClient,
    deleteClient, 
    deregisterClientUSSDSecurity,   
    upsertPerson,
    init,
  

};

let isOffline = false;

async function init(_isOffline) {
    isOffline = _isOffline;
}

async function getClients(page, size, sortField, sortOrder, search, filter) {
    page = page || 1;
    size = size || 10000;
    sortField = sortField || 'dateCreated';
    sortOrder = sortOrder || 'DESC';
    search = search || '%%';
    filter = filter || '';
    const res = await axios.get(
        `/api/clients?page=${page}&size=${size}&sortField=${sortField}&sortOrder=${sortOrder}&search=${search}&filter=${filter}`,
    );
    return res.data;
}

async function getClientsForAffordability(page, search) {
    search = search || '%%';
    const res = await axios.get(
        `/api/clients/affordability?search=${search}`,
    );
    return res.data;
}

// async function updateClient(data) {
//     try {
//         const res = await axios.put(`/api/clients/${data.id}`, data);
//         return res.data;
//     } catch (error) {
//         return error;
//     }
// }
async function upsertPerson(data) {
    try {
        const res = await axios.post('/api/clients', data);
        return res.data;
    } catch (error) {
        return error;
    }
}
async function deleteClient(data) {
    try {
        const res = await axios.post('/api/clients/delete', { id: data });
        return res.data;
    } catch (error) {
        return error;
    }
}

async function searchClients(name) {
    const res = await axios.post(`/api/clients/search`, { name });
    return res.data;
}
/**
 * Get a single client
 * @param {*} id
 * @returns clientdetail
 */
async function get(id) {
    const res = await axios.get(`/api/clients/${id}`);
    return res.data;
}

async function getPersonByMobilePhone(msisdn) {   
    const res = await axios.get(`/api/clients/get-person-by-mobile-phone/${msisdn}`);
    return res.data;
}

async function createClient(data) {
    const res = await axios.post('/api/clients', data);
    return res.data;
}

async function createLocalClient(modelId, versionId, data) {
    if (isOffline) {
        return await db.post({
            model: { modelId: modelId, versiondId: versionId },
            data: data,
        });
    } else {
        const res = await axios.post('/api/clients', data);
        return res.data;
    }
}

async function deregisterClientUSSDSecurity(personId) {
    const res = await axios.delete(`/api/clients/deregister-client-ussd-security/${personId}`);
    return res.data;
}
