import GeoServer from '../../services/geo.eventAction.service';
// import GeoServer from '../../helpers/geoserver/GeoServer';
// initial state
export const state = () => ({
    allEventActions: [],
});

// getters
export const getters = {
    getEventAction: state => id => {
        //console.log('Looking for ', id, state.allEventActions.length);
        return state.allEventActions.find(eventAction => eventAction.id == id);
    },
};

// actions
export const actions = {
    async getAllEventActions({ commit }) {
        const eventActions = await GeoServer.getEventActions();
        const MaxNum = 1000;
        const coms = eventActions.slice(0, MaxNum);
        commit('setEventActions', coms);
    },
    async getEventActionsCash({ commit, getters }, eventActionsID) {
        const dev = getters.getEventAction(eventActionsID);
        if (!dev) {
            const devDB = await GeoServer.getEventAction(eventActionsID);
            commit('addEventActions', [devDB]);
            return devDB;
        }
        return dev;
    },
    async createEventAction({ commit }, newEventActionObj) {
        const eventAction = await GeoServer.createEventAction(newEventActionObj);
        commit('addEventActions', [eventAction]);
    },
    async updateEventActionAsync({ commit }, updateEventActionObj) {
        const eventAction = await GeoServer.updateEventAction(updateEventActionObj.id, updateEventActionObj);
        commit('updateEventAction', [eventAction, updateEventActionObj.id]);
    },
    async deleteEventActionAsync({ commit }, deleteEventActionObj) {
        const eventAction = await GeoServer.deleteEventAction(deleteEventActionObj.id, deleteEventActionObj);
        commit('deleteEventAction', [eventAction, deleteEventActionObj.id]);
    },
};

// mutations
export const mutations = {
    setEventActions(state, eventActions) {
        state.allEventActions = eventActions;
    },
    addEventActions(state, eventActions) {
        eventActions.forEach(g => {
            state.allEventActions.push(g);
        });
    },
    updateEventAction(state, args) {
        const idx = state.allEventActions.findIndex(eventAction => eventAction.id == args[1]);
        if (idx != -1) {
            state.allEventActions[idx] = args[0];
        } else {
            state.allEventActions.push(args[0]);
        }
    },
    deleteEventAction(state, args) {
        const idx = state.allEventActions.findIndex(eventAction => eventAction.id == args[1]);
        if (idx != -1) {
            state.allEventActions.splice(idx, 1);
        }
    },
};

// export default {
//   namespaced: true,
//   state,
//   getters,
//   actions,
//   mutations
// }
