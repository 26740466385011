<script>
import router from '@/router';
import { layoutComputed } from '@/state/helpers';
import { useToggle } from "@vueuse/core";
import Topbar from '@/components/topbar';
import Sidebar from '@/components/side-bar';
import Footer from '@/components/footer';

import('../../assets/scss/theme.scss');

export default {
    data() {
        return {
            isMenuCondensed: false,
            isDarkMode: false,
        };
    },
    components: {
        Topbar,
        Sidebar,
        Footer,
    },
    computed: {
        ...layoutComputed,
    },
    name: 'vertical',
    created() {
        document.body.removeAttribute('data-layout');
        document.body.removeAttribute('data-topbar');
    },
    methods: {
        toggleMenu() {
            if (window.screen.width >= 992) {
                // eslint-disable-next-line no-unused-vars
                router.afterEach((routeTo, routeFrom) => {
                    document.body.classList.remove('sidebar-enable');
                    document.body.classList.remove('vertical-collpsed');
                    this.isMenuCondensed = false;
                });
                document.body.classList.toggle('vertical-collpsed');
            } else {
                // eslint-disable-next-line no-unused-vars
                router.afterEach((routeTo, routeFrom) => {
                    document.body.classList.remove('sidebar-enable');
                    this.isMenuCondensed= true;
                });
                document.body.classList.remove('vertical-collpsed');
            }
            this.isMenuCondensed = !this.isMenuCondensed;
        },
        toggleTheme(){
            const body = document.body;

                // Checking if the body element exists (to avoid errors in SSR or edge cases)
                if (body) {

                    // Get the classList of the body element
                    const bodyElement = document.body;
                    const themeValue = bodyElement.getAttribute('theme');

                    // Check if the theme class exists in the body's classList
                    if (themeValue != "dark") {
                        // Perform actions for dark theme
                       
                        this.isDarkMode=true;
                    } else {
                        // Perform actions for light theme
                       
                        this.isDarkMode=false;
                    
                    } 
                }
                this.isDarkMode = this.isDarkMode;
              
            
        },

    },
    mounted: function () {
        if (this.isMenuCondensed == true && window.screen.width >= 992) {
            // eslint-disable-next-line no-unused-vars
            router.afterEach((routeTo, routeFrom) => {
                document.body.classList.remove('sidebar-enable');
                document.body.classList.remove('vertical-collpsed');
            });
            document.body.classList.toggle('vertical-collpsed');
        }
    },
};
</script>

<template>
    <!-- Begin page -->
    <div id="layout-wrapper" :class="$themeName()">
        <Topbar />
        <Sidebar
            :isCondensed="isMenuCondensed"
            :type="leftSidebarType"
            :width="layoutWidth"
            :isDarkMode="isDarkMode"
        />
        <!-- ============================================================== -->
        <!-- Start right Content here -->
        <!-- ============================================================== -->
        <div class="main-content">
            <div class="page-content" id="main-page-content">
                <div class="container-fluid" id="main-container-fluid">
                    <slot />
                </div>
            </div>
            <!-- End Page-content -->
            <Footer />
        </div>
        <!-- end main content-->
    </div>
</template>
