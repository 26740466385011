import axios from '../helpers/api';

export const statsService = {
    getAgentActivityStats,
    getTeamActivityStats,
    getActivityStats,
};

async function getAgentActivityStats(userId) {
    if (userId != '') {
        const res = await axios.get(`/api/stats/agent/${userId}`);
        return res.data;
    } else {
        return {
            data: [
                {
                    assignedActivities: 0,
                    unassignedActivities: 0,
                },
            ],
        };
    }
}

async function getTeamActivityStats(userId) {
    if (userId != '') {
        const res = await axios.get(`/api/stats/team/${userId}`);

        return res.data;
    } else {
        return {
            data: [
                {
                    assignedAgents: 0,
                    unassignedAgents: 0,
                },
            ],
        };
    }
}

async function getActivityStats(userId) {
    if (userId != '') {
        const res = await axios.get(`/api/stats/${userId}`);
        return res.data;
    } else {
        return {
            data: [
                {
                    assignedAgents: 0,
                    unassignedAgents: 0,
                },
            ],
        };
    }
}
