import axios from '../helpers/api';
import _ from 'lodash';
import { DateTime } from 'luxon';
// import db from '@/services/pouchDb';
import db from './pouchDb';
import helper from '../helpers/custom/index';
const { version } = require('./../../package.json');
const { v4: uuidv4 } = require('uuid');

export const errorLogService = {
    getErrorLogId,
    insertErrorLog,
    getErrorLogReport,
}

async function insertErrorLog(Id, userId, entityId, entityType, errorDetail, dateCreated) {
    try {
        Id = helper.checkId(Id) ? Id : uuidv4();


        if (navigator.onLine){
            try{
                
                const res = await axios.post('/api/error_log', {"Id": Id, "userId": userId, "entityId": entityId, "entityType":entityType, "errorDetail":errorDetail, "dateCreated": dateCreated});
                return res.data;
            }
            catch(error)
            {    console.log("update  error ", error);
                return error; 
            }
        
        // } else {

        //     if (!db.userDB || _.isEmpty(db.userDB)) {
        //         await db.refreshUserDb();
        //     }

        //     //SAVE actionData
        //     let doc = {
        //         _id: Id,
        //         errorLog:  {"Id": Id, "userId": userId, "entityId": entityId, "entityType":entityType, "errorDetail":errorDetail, "dateCreated": dateCreated},
        //     };

        //     await db.userDB.put(doc);
           
        //     let change_id = uuidv4();

        //     await db.globalsDB.put({
        //         _id: change_id,
        //         change: {
        //             version: version,
        //             entityId: data.Id,
        //             userId: helper.getUserId(),
        //             entityType: 'errorLog',
        //             dateModified: DateTime.now().toISO(),
        //             changeType: 'INSERT'
        //         }
        //     });
        //     return {
        //         success: true,
        //         data:{
        //             errorLog:  {"Id": Id, "userId": userId, "entityId": entityId, "entityType":entityType, "errorDetail":errorDetail, "dateCreated": dateCreated}
        //         }
        //     }
        // 

    }
    } catch (error) {
        console.log(error);
        return {
            success: false,
            data:{
                errorLog: { Id: ''}
            },
            error: error
        }
    }
    return 'offline';;
};

/** Get Error Log */
async function getErrorLogId(errorLogId) {
    if (navigator.onLine) {
        try {
            const res = await axios.get(`/api/error_log/${errorLogId}`);
            return res.data;
        } catch (error) {
            return error;
        }
    }
    return 'offline';

}

async function getErrorLogReport(startDate, endDate) {
    startDate = startDate || DateTime.now().plus({days:-1}).toISO();
    endDate = endDate || DateTime.now().plus({days:30}).toISO();
    if (navigator.onLine) {
        try {
            const res = await axios.get(`/api/error_log/error-log-report?startDate=${startDate}&endDate=${endDate}`);
            return res.data;
        } catch (error) {
            return error;
        }
    }
    return 'offline';

}

