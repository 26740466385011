import { tenantService } from '@/services/tenant.service';

export const state = {
    tenants: JSON.parse(localStorage.getItem('tenants')),
};

export const getters = {
    getTenants: state => {
        return state.tenants;
    },
};

export const actions = {
    async fetchTenants({ commit }) {
        const res = await tenantService.getAllTenantDescendantsByUserId();
        commit('setTenants', res.data);
    },
};

export const mutations = {
    setTenants: (state, tenants) => {
        state.tenants = tenants;
        localStorage.setItem('tenants', JSON.stringify(tenants));
    },
};
