import { configService } from '@/services/config.service';

export const state = {
    language: [],
};

export const getters = {
    getLanguage: state => {
        return state.language;
    },
};

export const actions = {
    async fetchLanguage({ commit }) {
        const lang = await configService.getLanguage();
        commit('setLanguage', lang.data.language || []);
    },
};

export const mutations = {
    setLanguage: (state, language) => {
        state.language = language;
        localStorage.setItem('language', JSON.stringify(language));
    },
};
