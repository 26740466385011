import { leadsService } from '../../services/leads.service';

export const state = {
    leads: [],
    searchResult: null,
};

export const getters = {
    getLeads: state => {
        return state.leads;
    },
    getSearchResult: state => {
        return state.searchResult;
    },
};

export const actions = {
    async fetchLeads({ commit }) {
        const leads = await leadsService.getLeadsByUserId();
        commit('setLeads', leads);
    },
    async searchLeads({ commit }, payload) {
        const { name } = payload;
        const leads = await leadsService.searchLeads(name);
        commit('setSearchResult', leads);
    },
};

export const mutations = {
    setLeads: (state, leads) => {
        state.leads = leads;
    },
    setSearchResult: (state, leads) => {
        state.searchResult = leads;
    },
};
