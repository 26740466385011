import axios from '../helpers/api';

export default {
    /* Commands */
    async getCommands(page, size, sortField, sortOrder, search) {
        page = page || 1;
        size = size || 10000;
        sortField = sortField || 'name';
        sortOrder = sortOrder || 'DESC';
        search = search || '';
        search = search.toUpperCase().trim();

        const res = await axios.get(
            `/api/geo/commands?page=${page}&size=${size}&sortField=${sortField}&sortOrder=${sortOrder}&search=${search}`,
        );
        return res.data;
    },
    async getCommandById(id) {
        const res = await axios.get(`/api/geo/commands/${id}`);

        return res.data;
    },
    async deleteCommand(CommandId, CommandObj) {
        const res = await axios.delete(`/api/geo/commands/${CommandId}`, CommandObj);

        return res.data;
    },
    async updateCommand(CommandId, CommandObj) {
        const res = await axios.put(`/api/geo/commands/${CommandId}`, CommandObj);

        return res.data;
    },
    async createCommand(CommandObj) {
        CommandObj.type = 'command';
        const res = await axios.post(`/api/geo/commands`, CommandObj);

        return res.data;
    },
    async getAllCommands() {
        const res = await axios.get(`/api/geo/commands/command/all`);
        return res.data;
    },
    async getByDeviceHardwareID(deviceHardwareId, page, size, sortField, sortOrder, search){
        page = page || 1;
        size = size || 10000;
        sortField = sortField || 'name';
        sortOrder = sortOrder || 'DESC';
        search = search || '';
        search = search.toUpperCase().trim();


        const res = await axios.get(
            `/api/geo/commands/${deviceHardwareId}?page=${page}&size=${size}&sortField=${sortField}&sortOrder=${sortOrder}&search=${search}`,
        );
        return res.data;
    }


};
