import { activitiesService } from '@/services/activities.service';

// function sleep(ms) {
//     return new Promise(resolve => setTimeout(resolve, ms));
// }

// initial state
export const state = () => ({
    all: [],
});

// getters
export const getters = {
    getActivity: state => id => {
        // console.log("Looking for ",id,state.all.length)
        return state.all.find(activity => activity.id == id);
    },
};

// actions
export const actions = {
    async getAllAssignedActivities({ commit }) {
        // startDate,endDate, this.state.filter.status || 'ALL'
        const { data } = await activitiesService.getAllActivities();
        commit('setActivities', data.activities || []);
        return;
    },
    async getActivity({ getters }, activityId) {
        const dev = getters.getActivity(activityId);
        return dev;
    },
    async addActivities({ commit }, activity) {
        commit('addActivities', [activity]);
    },
    async updateActivityStatus({ commit }, updatedActivity) {
        commit('updateActivityStatus', [updatedActivity, updatedActivity.id]);
    },
    async deleteActivity({ commit }, activity) {
        commit('deleteActivity', [activity, activity.id]);
    },
};

// mutations
export const mutations = {
    setAcivities(state, activities) {
        state.all = Object.freeze(activities);
    },
    addActivities(state, activities) {
        activities.forEach(d => {
            if (!state.all.find(activity => activity.id == d.id)) {
                state.all.push(d);
            }
        });
    },
    updateActivityStatus(state, args) {
        let idx = state.all.findIndex(activity => activity.id == args[0]);
        if (idx != -1) {
            state.all[idx].status = args[1];
        }
    },
    updateActivity(state, args) {
        const idx = state.all.findIndex(activity => activity.id == args[1]);
        if (idx != -1) {
            state.all[idx] = args[0];
        } else {
            state.all.push(args[0]);
        }
    },
    deleteActivity(state, args) {
        const idx = state.all.findIndex(activity => activity.id == args[1]);
        if (idx != -1) {
            state.all.splice(idx, 1);
        }
    },
};
