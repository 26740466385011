import axios from '../helpers/api';
import _ from 'lodash';
import db from '@/services/pouchDb';
const user = JSON.parse(localStorage.getItem('user'));

export const bidsService = {
    getAllBids,
};

async function getAllBids(page, size, sortField, sortOrder, search) {
    page = page || 1;
    size = size || 10000;
    sortField = sortField || 'receivedDate';
    sortOrder = sortOrder || 'DESC';
    search = search || '';
    if (navigator.onLine) {
        const res = await axios(
            `/api/vehicles/bids/${user.Id}?page=${page}&size=${size}&sortField=${sortField}&sortOrder=${sortOrder}&search=${search}`,
        );
        return res.data;
    } else {
        if (!db.userDB || _.isEmpty(db.userDB)) {
            db.userDB = await db.refreshUserDb();
        }
        const { rows } = await db.userDB.allDocs({
            include_docs: true,
            attachments: true,
        });
        const bids = [];
        rows.forEach(row => {
            if (row.doc.vehicleBid) {
                bids.push(row.doc.vehicleBid);
            }
        });
        return {
            result: {
                success: true,
                message: 'Vehicle bids retrieved successfully',
            },
            meta: {},
            data: {
                bids,
            },
        };
    }
}
