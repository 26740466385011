export const state = {
    activityPlan: { id: '', activities: [] },
};

export const getters = {
    getActivityPlan: state => {
        return state.activityPlan;
    },
};

export const actions = {
    async setActivities({ commit, activities }) {
        commit('setActivities', activities);
    },
};

export const mutations = {
    setActivities: (state, activities) => {
        state.activityPlan.activities = activities;
    },
};
