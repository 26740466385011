import axios from '../helpers/api';
import helper from '../helpers/custom/index';
import db from './pouchDb';
import _ from 'lodash';
import store from '@/state/store';
import { v4 as uuidv4 } from 'uuid';

export const tenantService = {
    getTenantCurrency,
    getTenantCompany,
    getAllTenants,
    getTenantTypes,
    getAllTenantDescendantsByTenantId,
    getSpecificTenantDescendantsByTenantId,
    getAllTenantDescendantsByUserId,
    insertTenant,
    getTenantById,
    updateTenant,
    deleteTenant,
    getUserTenants,
    countSubTenants,
    fetchLocalTenants,
    fetchLocalTenantById,
};

/** This function will get all tenants from the local store avoiding the need for rest-api access */
async function fetchLocalTenants() {
    let tenantList =  store.getters['auth/getCurrentUser'].tenants;
    return tenantList;
}
/** This function will get a tenant from the local store avoiding the need for rest-api access */
async function fetchLocalTenantById(tenantId) {
    let tenantList =  store.getters['auth/getCurrentUser'].tenants;
    let tenant = _.find(tenantList,  tenant => tenant.Id === tenantId);
    return tenant || null;
}


async function getTenantTypes() {
    const res = await axios.get(`/api/tenants/tenanttypes`);
    return res.data;
}

// get offline tenant
async function getTenant(tenantId) {
    try {
        if (!db.userDB || _.isEmpty(db.userDB)) {
            db.refreshUserDb();
        }

        const res = await db.globalsDB.allDocs({
            include_docs: true,
        });

        let tenant = {};
        // run through all the tenant documents and find the current tenants parent tenant
        // the tenant company is the first parent tenant on that branch where the tenant.type = 2
        // first find the current tenant
        res.rows.forEach(row => {
            if (row.doc.tenant != undefined && row.doc.tenat.Id == tenantId) {tenant = row.doc.tenant};
        });

        return tenant;
    }
    catch (error) {
        return null;
    }
}

async function getTenantCompany(tenantId) {
    try {
        if (navigator.onLine){
            const res = await axios(`/api/tenants/tenantCompany/${tenantId}`);
            return res.data;
        }
        else {
            if (!db.userDB || _.isEmpty(db.userDB)) {
                db.refreshUserDb();
            }
    
            const res = await db.globalsDB.allDocs({
                include_docs: true,
            });

            let tenant = {};
            // run through all the tenant documents and find the current tenants parent tenant
            // the tenant company is the first parent tenant on that branch where the tenant.type = 2
            // first find the current tenant
            tenant = await this.getTenant(tenantId);
            while (tenant && (tenant.type != 2 || tenant.parentId != null) ) {
                tenant = await this.getTenant(tenant.parentId);
            }
            //console.log('offline company', tenant);
            return {
                data: { tenant: tenant || null },
                result: {
                    success: true,
                    message: 'Tenant Company retrieved successfully.',
                },
            };
        }
    }
     catch (error) {
        console.log(error);
        return {
            data: { tenant: null },
            result: {
                success: false,
                message: 'Unable to retrieve Tenant Company. ' + error,
            },
        };
    }
}

async function getTenantCurrency(tenantId) {
    try {
        const res = await axios(`/api/tenants/tenantCurrency/${tenantId}`);
        return res.data;
    } catch (error) {
        return error;
    }
}

async function getUserTenants(userid) {
    try {
        const res = await axios(`/api/tenants/usertenants/${userid}`);
        return res.data;
    } catch (error) {
        return error;
    }
}

async function insertTenant(data) {
    try {
        if (data && (data.location == '' || data.location == null)) {
            const parentTenant = await getTenantById(data.parentId);
            if (parentTenant && parentTenant.data && parentTenant.data.tenant && parentTenant.data.tenant[0].location != '') {
                data.location = parentTenant.data.tenant[0].location;
                data.lat = parentTenant.data.tenant[0].lat;
                data.lng = parentTenant.data.tenant[0].lng;
            }
        }
        data['Id'] = helper.checkId(data.Id) ? data.Id : uuidv4();
        const res = await axios.post('/api/tenants/', data);
        return res.data;
    } catch (error) {
        return error;
    }
}

async function deleteTenant(data) {
    try {
        const res = await axios.post('/api/tenants/delete', { id: data });
        return res.data;
    } catch (error) {
        return error;
    }
}

async function getAllTenants(page, size, sortField, sortOrder, search) {
    page = page || 1;
    size = size || 10000;
    sortField = sortField || 'parentId';
    sortOrder = sortOrder || 'DESC';
    search = search || '%%';
    const res = await axios.get(`/api/tenants?page=${page}&size=${size}&sortField=${sortField}&sortOrder=${sortOrder}&search=${search}`);
    return res.data;
}

async function getTenantById(id) {
    try {
        const res = await axios.get(`/api/tenants/${id}`);
        if (res && res.data && res.data.data && res.data.data.tenant && (res.data.data.tenant[0].location == null || res.data.data.tenant[0].location == '')) {
            const parentTenant = await getTenantById(res.data.data.tenant[0].parentId);
            if (parentTenant && parentTenant.data && parentTenant.data.tenant && parentTenant.data.tenant[0].location != '') {
                res.data.data.tenant[0].location = parentTenant.data.tenant[0].location;
                res.data.data.tenant[0].lat = parentTenant.data.tenant[0].lat;
                res.data.data.tenant[0].lng = parentTenant.data.tenant[0].lng;
            }
        }
        return res.data;
    } catch (error) {
        return error;
    }
}
/** This function uses embedded tenantId ie the currenctly selected tenant */
async function getAllTenantDescendantsByTenantId(page, size, sortField, sortOrder, search) {
    page = page || 1;
    size = size || 10000;
    sortField = sortField || 'dateCreated';
    sortOrder = sortOrder || 'DESC';
    search = search || '%%';
    try{
        const res = await axios.get(
            `/api/tenants/descendantsByTenantId/?page=${page}&size=${size}&sortField=${sortField}&sortOrder=${sortOrder}&search=${search}`,
        ).catch((e) =>{
            console.log(e);
        });
        return res.data;
    }catch(err){
        console.log(err);
    }
}
/** This function will get a specific Tenants children */
async function getSpecificTenantDescendantsByTenantId(tenantId, page, size, sortField, sortOrder, search) {
    page = page || 1;
    size = size || 10000;
    sortField = sortField || 'dateCreated';
    sortOrder = sortOrder || 'DESC';
    search = search || '%%';
    const res = await axios.get(
        `/api/tenants/descendants-by-specific-tenantid/?tenantId=${tenantId}&page=${page}&size=${size}&sortField=${sortField}&sortOrder=${sortOrder}&search=${search}`,
    );
    return res.data;
}

const getTenantsDescendants = (data, tenantId) =>
    data.filter(tenant => tenant.parentId === tenantId);

const sortTenants = tenants => {
    // copy tenants array to avoid
    const data = _.cloneDeep(tenants);
    // variable to hold end results
    let results = [];
    const childrenIds = [];

    data.forEach(tenant => {
        const newTenant = tenant;
        // attach children
        newTenant.children = getTenantsDescendants(data, tenant.Id);
        newTenant.key = tenant.Id;
        newTenant.label = tenant.name;
        // get children ids
        newTenant.children = _.orderBy(newTenant.children, ['name'], ['ASC']);
        tenant.children.forEach(item => {
            childrenIds.push(item.Id);
        });
        results.push(newTenant);
    });

    // exclude tenant children from main results
    childrenIds.forEach(id => {
        results = results.filter(tenant => tenant.Id !== id);
    });
    results = _.reverse(results);

    return results;
};

async function getAllTenantDescendantsByUserId(page, size, sortField, sortOrder, search) {
    page = page || 1;
    size = size || 10000;
    sortField = sortField || 'dateCreated';
    sortOrder = sortOrder || 'DESC';
    search = search || '%%';
    if (navigator.onLine){
  
        const res = await axios.get(
            `/api/tenants/descendantsByUserId?page=${page}&size=${size}&sortField=${sortField}&sortOrder=${sortOrder}&search=${search}`
        );
    return res.data;
    } else {
        if (!db.userDB || _.isEmpty(db.userDB)) {
            db.refreshUserDb();
        }

        // I think offline we should just return the selected tenant and you shouldn't beable to change tenants offline
        // so we should just return the tenant from localstorage that matches selectedTenantId
        const user = JSON.parse(localStorage.getItem('user'));
        const selectedTenantId = user.selectedTenant;

        const res = await db.globalsDB.allDocs({
            include_docs: true,
        });
        let tenants = [];

        res.rows.forEach(row => {
            if (row.doc.tenant && row.doc.tenant.Id == selectedTenantId) {
                tenants.push(row.doc.tenant);
            }
        });
        //console.log('offline tenants', tenants);
        let response = {
            result: {
                success: true,
                message: 'Successfully retrieved tenants.',
            },
            data: {
                tenantsArranged: tenants,
                tenants: tenants,
            },
        };
        
        return response;
    }
}

async function countSubTenants() {
    const res = await axios.get('/api/tenants/count-sub-tenants');
    return res.data;
}

async function updateTenant(data) {
    try {
        if (data && data.location == '') {
            const parentTenant = await getTenantById(data.parentId);
            if (parentTenant && parentTenant.data && parentTenant.data.tenant && parentTenant.data.tenant[0].location != '') {
                data.location = parentTenant.data.tenant[0].location;
                data.lat = parentTenant.data.tenant[0].lat;
                data.lng = parentTenant.data.tenant[0].lng;
            }
        }
       
        data['Id'] = helper.checkId(data.Id) ? data.Id : uuidv4();        
        const res = await axios.put(`/api/tenants/${data.Id}`, data);
        return res.data;
    } catch (error) {
        return error;
    }
}
