import { userService } from '@/services/user.service';
import { configService } from '@/services/config.service';
import router from '../../router/index';
import { lang } from 'moment';
import helper from '@/helpers/custom';

export const state = {
    user: JSON.parse(localStorage.getItem('user')),
    impersonateUser : {},
    impersonation : false,
    token: localStorage.getItem('token'),
    impersonationToken: {},
    language: JSON.parse(localStorage.getItem('language') || '[]'),
    status: {},
};
export const actions = {

    // Logs in the user.
    // eslint-disable-next-line no-unused-vars
    async login({ dispatch, commit }, userResult) {
        commit('loginRequest', { email: userResult.email });

        try {
            const { offline } = userResult;
            if (offline) {
                await commit('loginSuccess', userResult, 'offline');
                setTimeout(() => {
                    if (getters.isLoanOfficer() == true || getters.isTeamLeader() == true) {
                        router.go('/agents/landingpage').catch(() => {});
                    } else {
                        router.push('/').catch(() => {});
                    }
                }, 50);
            }

            const { token, refreshToken } = userResult;

            await userService.authenticateApi({
                token: token,
                refreshToken: refreshToken,
            });
            delete userResult.token;
            delete userResult.refreshToken;

            await commit('loginSuccess', userResult, token);
            await configService.getLanguage(userResult.tenants[0].Id).then(async (lang) => { 
                   
                await commit('setLanguage', lang);
            });
            // this fetch tenants must be done by username
            //await dispatch('tenant/fetchTenants', Id, { root: true });

            //
            if (userResult.isOAuth) {
                window.location.href = `${userResult.oauth.redirectUri}?success=true&token=${token}`;
            } else {
                setTimeout(() => {     
                    // console.log('here',(getters.isLoanOfficer() == true || getters.isTeamLeader() == true), router);
                    // process.exit();          
                    if (getters.isLoanOfficer() == true || getters.isTeamLeader() == true) {
                       
                        try{ 
                            router.go('/agents/landingpage');
                        }
                        catch (error)
                        { console.log('error',error);
                        }
                    } else {
                        //handle domain redirect here
                        //if (window.location.indexOf('platcorpgroup.com') && user.te)
                        router.go('/');
                    }
                }, 900);
            }
        } catch (error) {
            commit('loginFailure', error);
            dispatch('notification/error', error, { root: true });
        }
    },

    //impersonate user mimicks login function
    async impersonateUser({dispatch, commit}, userResult){
        commit('impersonationRequest', userResult);

        const { offline } = userResult;
        if (offline) {
            await commit('loginSuccess', userResult, 'offline');
            setTimeout(() => {
                if (getters.isLoanOfficer() == true || getters.isTeamLeader() == true) {
                    router.go('/agents/landingpage').catch(() => {});
                } else {
                    router.push('/').catch(() => {});
                }
            }, 50);
        }

        const { token, refreshToken } = userResult;

        await userService.authenticateApi({
            token: token,
            refreshToken: refreshToken,
        });

        await commit('impersonationSuccess', userResult, token);
    
    },

    // Logout the user
    logout({ commit }) {
        userService.logout();
        commit('logout');
    },
    async fetchLanguage({ commit }) {
        const lang = await configService.getLanguage();
        commit('setLanguage', lang.data.language || []);
    },
    // register the user
    registeruser({ dispatch, commit }, user) {
        commit('registerRequest', user);
        userService.register(user).then(
            user => {
                commit('registerSuccess', user);
                dispatch('notification/success', 'Registration successful', {
                    root: true,
                });
                router.push('/login').catch(() => {});
            },
            error => {
                commit('registerFailure', error);
                dispatch('notification/error', error, { root: true });
            },
        );
    },
};

export const getters = {
    // Whether the user is currently logged in.
    loggedIn() {
        const token = localStorage.getItem('token');
        const user = _.isNull(localStorage.getItem('user'));
        if (token == null || user) {
            return false;
        }
        if (helper.isTokenExpired(token)) {
            localStorage.removeItem('token');
        }

        return token != null;
    },

    getCurrentUser() {
        return state.user || false;
    },
    isLoanOfficer: () => {
        let user = {};
        if (localStorage.getItem('user')) {
            user = JSON.parse(localStorage.getItem('user'));
        }
        if (!_.isEmpty(user)) {           
            return (!_.isUndefined (user.roles.find(
                    role => role.name.toLowerCase().trim() == 'loan officer',
                )) 
                ) 
        }
        return false;
    },
    isCreditOfficer: () => {
        let user = {};
        if (localStorage.getItem('user')) {
            user = JSON.parse(localStorage.getItem('user'));
        }
        if (!_.isEmpty(user)) {            
                return (!_.isUndefined (user.roles.find(
                    role => role.name.toLowerCase().trim() == 'credit officer',
                )) 
            ) 
        }
        return false;
    },
    isTeamLeader: () => {
        let user = {};
        if (localStorage.getItem('user')) {
            user = JSON.parse(localStorage.getItem('user'));
        }
        if (!_.isEmpty(user)) {
            return (!_.isUndefined (user.roles.find(
                role => role.name.toLowerCase().trim() == 'team leader',
            )) 
            ) 
        }
        return false;
    },
};

export const mutations = {
    loginRequest(state, user) {
        state.status = { loggingIn: true };
        state.user = user;
    },
    impersonationRequest(state, user) {
        state.status = { 
            loggingIn: true,
            impersonationInProgress: true, 
                        };
        state.impersonateUser = user;
    },
    async loginSuccess(state, user, token) {
        state.user = user;
        state.token = token;

        localStorage.setItem('user', JSON.stringify(user));
        return;
    },
    async impersonationSuccess(state, user, token) {

        state.impersonation = true;
        const temp = state.user;
        state.user = user;
        state.impersonateUser = temp;
        state.token = token; 
        return;
    },
    loginFailure(state) {
        state.user = null;
    },
    impersonationFailure(state){
        state.impersonateUser = null;
    },
    logout(state) {
        state.user = null;
    },
    endImpersonation (state){
        state.impersonateUser = null;
        state.impersonation = false;
    },
    registerRequest(state) {
        state.status = { registering: true };
    },
    registerSuccess(state) {
        state.status = {};
    },
    registerFailure(state) {
        state.status = {};
    },
    setLanguage: (state, language) => {
        state.language = language;
        localStorage.setItem('language', JSON.stringify(language));
    },
};
