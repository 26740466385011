import { vehicleService } from '../../services/vehicle.service';

export const state = {
    vehicles: [],
};

export const getters = {
    getVehicles: state => {
        return state.vehicles;
    },
};

export const actions = {
    async fetchVehicles({ commit }) {
        const vehicles = await vehicleService.getAllVehicles();
        commit('setVehicles', vehicles);
    },
};

export const mutations = {
    setVehicles: (state, vehicles) => {
        state.vehicles = vehicles;
    },
};
