import {geoAssetService} from "@/services/geo.asset.service";
import {DateTime} from "luxon";

export const state = () => ({
    selectedAssets: [],
    tripsData: [],
    eventsData: [],
    alertsData: [],
    approvedCrashesData: [],
    rejectedCrashesData : [],
    automaticCrashesData: [],

});

export const actions = {
    addSelectedAsset({ commit }, asset) {
        commit('addAsset', asset);
    },
    removeSelectedAssets({ commit }, asset) {
        commit('removeAsset', asset)
    },
    clearSelectedAssets({ commit }) {
        commit('clearAssets')
    },
    fetchAlertsByAsset({ commit }, payload) {
        const { selectedAsset, startDate, endDate } = payload;
        return geoAssetService.getAssetAlerts(selectedAsset, startDate, endDate)
            .then(response => {
                commit('setSelectedAssetAlerts', response.data.data.notification_event);
            })
            .catch(error => {
                console.error('Error fetching event:', error);
            });
    },
    fetchEventsByAsset({ commit },payload) {
        const { selectedAsset, startDate, endDate } = payload;
        return geoAssetService.getAssetEvents(selectedAsset, startDate, endDate)
            .then(response => {
                commit('setSelectedAssetEvents', response.data.data.notification_event);
            })
            .catch(error => {
                console.error('Error fetching event:', error);
            });
    },
    fetchCrashesByAsset({ commit },payload) {
        const { selectedAsset,status, startDate, endDate } = payload;
        return geoAssetService.getAssetCrashes(selectedAsset,status, startDate, endDate)
            .then(response => {

                if(status.toUpperCase() === 'NEW'){
                    commit('setSelectedAssetAutomaticCrashes', response.data.data.crashEvent);
                }else if (status.toUpperCase() === 'REJECTED') {
                    commit('setSelectedAssetRejectedCrashes', response.data.data.crashEvent);
                }else if (status.toUpperCase() === 'APPROVED') {
                    commit('setSelectedAssetApprovedCrashes', response.data.data.crashEvent);
                }
            })
            .catch(error => {
                console.error('Error fetching event:', error);
            });
    },
};

export const mutations = {
    addAsset(state, asset) {
        // const index = state.selectedAssets.findIndex(x => x.assetId == asset.assetId);
        // if (index == -1) state.selectedAssets.push(asset);
        state.selectedAssets = [asset];
    },
    removeAsset(state, asset) {
        const idx = state.selectedAssets.findIndex(x => x.assetId == asset.assetId);
        if (idx != -1) {
            state.selectedAssets.splice(idx, 1);
        }
    },
    clearAssets(state) {
        state.selectedAssets = [];
    },
    setSelectedAssetEvents(state, eventData) {
        state.eventsData = eventData;
    },
    setSelectedAssetAlerts(state, alertData) {
        state.alertsData = alertData;
    },

    setSelectedAssetAutomaticCrashes(state, crashData) {
        state.automaticCrashesData = crashData;
    },
    setSelectedAssetRejectedCrashes(state, crashData) {
        state.rejectedCrashesData = crashData;
    },

    setSelectedAssetApprovedCrashes(state, crashData) {
        state.approvedCrashesData = crashData;
    },
}
