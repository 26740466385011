import axios from '../helpers/api';

export const firebaseService = {
    registerDevice,
};

async function registerDevice(data) {
    try {
        const res = await axios.post('/api/firebase/register-device', data);
        return res.data;
    } catch (error) {
        return error;
    }
}
