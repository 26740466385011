import axios from '../helpers/api';

export default {
    async getAllEventActions(page, size, sortField, sortOrder, search) {
        page = page || 1;
        size = size || 10000;
        sortField = sortField || 'name';
        sortOrder = sortOrder || 'DESC';
        search = search || '';
        search = search.toUpperCase().trim();

        let res = await axios.get(
            `/api/geo/eventActions?page=${page}&size=${size}&sortField=${sortField}&sortOrder=${sortOrder}&search=${search}`,
        );
        //console.log(res.data)
        return res.data;
    },
    async getEventActionById(id) {
        let res = await axios.get(`/api/geo/eventActions/${id}`);
        //console.log(res.data)
        return res.data;
    },
    async deleteEventAction(EventActionId, EventActionObj) {
        const res = await axios.delete(`/api/geo/eventActions/${EventActionId}`, EventActionObj);

        return res.data;
    },
    async updateEventAction(EventActionId, EventActionObj) {
        const res = await axios.put(`/api/geo/eventActions/${EventActionId}`, EventActionObj);

        // console.log(res.data)
        return res.data;
    },
    async createEventAction(EventActionObj) {
        EventActionObj.type = 'eventAction';
        const res = await axios.post(`/api/geo/eventActions`, EventActionObj);

        // console.log(res.data)
        return res.data;
    },
    async getAddress(positionId) {
        let res = await axios.get(`/api/geo/geocoding/position/${positionId}`);

        // console.log(res.data)
        return res.data;
    },
    async getEvents(userId) {
        let res = await axios.get(`/api/geo/eventActions/eventios/${userId}`);
        return res.data;
    },
};
