import { bidsService } from '@/services/bid.service';

export const state = {
    bids: [],
};

export const getters = {
    getBids: state => {
        return state.bids;
    },
};

export const actions = {
    async fetchBids({ commit }) {
        const bids = await bidsService.getAllBids();
        commit('setBids', bids);
    },
};

export const mutations = {
    setBids: (state, bids) => {
        state.bids = bids;
    },
};
