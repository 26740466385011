import { statsService } from '../../services/stats.service';
export const state = {
    agentActivityStats: {},
    agentTeamStats: {},
    activityStats: {},
    dateRetrieved: null,
    userId: JSON.parse(localStorage.getItem('user') || '{ "Id": "" }').Id,
};


export const getters = {
    getAgentActivityStats: async state => {
        return state.agentActivityStats;
    },
    getTeamActivityStats: async state => {
        return state.agentTeamStats;
    },
    getActivityStats: async state => {
        return state.activityStats;
    },
};

export const actions = {
    async fetchAgentActivityStats({ commit }) {
        const stats = await statsService.getAgentActivityStats(state.userId);
        //console.log('AGENT STATS', stats)
        commit('setAgentActivityStats', stats.data[0]);
    },
    async fetchTeamActivityStats({ commit }) {
        const stats = await statsService.getTeamActivityStats(state.userId);

        commit('setTeamActivityStats', stats.data[0]);
    },
    async fetchActivityStats({ commit }) {
        const stats = await statsService.getActivityStats(state.userId);
        commit('setActivityStats', stats.data[0]);
    },
};

export const mutations = {
    setAgentActivityStats: (state, stats) => {
        state.agentActivityStats = stats;
    },
    setTeamActivityStats: (state, stats) => {
        state.teamActivityStats = stats;
    },
    setActivityStats: (state, stats) => {
        state.activityStats = stats;
    },
};
