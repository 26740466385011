/* eslint-disable no-console */

import { register } from 'register-service-worker';
var updated = false;
if (process.env.NODE_ENV === 'production') {
    register(`${process.env.BASE_URL}service-worker.js`, {
        ready() {
            console.log('App is being served from cache.');
        },
        registered(registration) {
             console.log('Service worker has been registered and now polling for updates.', registration);
        },
        cached() {
            console.log('Content has been cached for offline use.');
        },
        updatefound(registration) {
            console.log('Dispatching update event');
            if (!updated) {
                document.dispatchEvent(
                    new CustomEvent('swUpdated', { detail: registration }),
                );
                updated = true;
            }
        },
        updated(registration) {
            console.log('New content is available; please refresh.')
             document.dispatchEvent(
                 new CustomEvent('swUpdated', { detail: registration })
             )
        },
        offline() {
            console.log('No internet connection found. App is running in offline mode.');
        },
        error(error) {
            console.error('Error during service worker registration:', error);
        },
    });
}
