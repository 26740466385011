import { templateService } from '../../services/template.service';

export const state = {
    templates: [],
};

export const getters = {
    getTemplates: state => {
        return state.templates;
    },
};

export const actions = {
    async fetchTemplates({ commit }) {
        const { data } = await templateService.getAllTemplates();
        commit('setTemplates', data.templates);
    },
};

export const mutations = {
    setTemplates: (state, templates) => {
        state.templates = templates;
    },
};
