import { clientsService } from '../../services/clients.service';

export const state = {
    clients: [],
    searchResult: null,
};

export const getters = {
    getClients: state => {
        return state.clients;
    },
    getSearchResult: state => {
        return state.searchResult;
    },
};

export const actions = {
    async fetchClients({ commit }, payload) {
        const { page, size, sortField, sortOrder } = payload;
        const clients = await clientsService.getClients(
            page,
            size,
            sortField,
            sortOrder,
        );
        commit('setClients', clients);
    },
    async searchClients({ commit }, payload) {
        const { name } = payload;
        const clients = await clientsService.searchClients(name);
        commit('setSearchResult', clients);
    },
};

export const mutations = {
    setClients: (state, clients) => {
        state.clients = clients;
    },
    setSearchResult: (state, clients) => {
        state.searchResult = clients;
    },
};
