import { userService } from '../../services/user.service';
import _ from 'lodash';

export const state = {
    users: [],
    gpsCoords: {},
};

export const getters = {
    getUsers: state => {
        return state.users;
    },
    getUser: () => {
        let user = {};
        if (localStorage.getItem('user')) {
            user = JSON.parse(localStorage.getItem('user'));
        }
        return user;
    },
    isAdmin: () => {
        let user = {};
        if (localStorage.getItem('user')) {
            user = JSON.parse(localStorage.getItem('user'));
        }
        if (!_.isEmpty(user)) {
            return (!_.isUndefined (user.roles?.find(
                role =>
                role.name.toLowerCase().trim() == 'global administrator' ||
                role.name.toLowerCase().trim() == 'administrator' ||
                role.name.toLowerCase().trim() == 'admin',
            )) 
            ) 
        }
        return false;
    },
    isGlobalAdmin: () => {
        let user = {};
        if (localStorage.getItem('user')) {
            user = JSON.parse(localStorage.getItem('user'));
        }
        if (!_.isEmpty(user)) {
            return (!_.isUndefined (user.roles?.find(
                role => role.name.toLowerCase().trim() == 'global administrator',
            )) 
            ) 
        }
        return false;
    },
    isLoanOfficer: () => {
        let user = {};
        if (localStorage.getItem('user')) {
            user = JSON.parse(localStorage.getItem('user'));
        }
        if (!_.isEmpty(user)) {           
            return (!_.isUndefined (user.roles?.find(
                    role => role.name.toLowerCase().trim() == 'loan officer',
                )) 
                ) 
        }
        return false;
    },
    isCreditOfficer: () => {
        let user = {};
        if (localStorage.getItem('user')) {
            user = JSON.parse(localStorage.getItem('user'));
        }
        if (!_.isEmpty(user)) {            
                return (!_.isUndefined (user.roles?.find(
                    role => role.name.toLowerCase().trim() == 'credit officer',
                )) 
            ) 
        }
        return false;
    },
    isTeamLeader: () => {
        let user = {};
        if (localStorage.getItem('user')) {
            user = JSON.parse(localStorage.getItem('user'));
        }
        if (!_.isEmpty(user)) {
            return (!_.isUndefined (user.roles?.find(
                role => role.name.toLowerCase().trim() == 'team leader',
            )) 
            ) 
        }
        return false;
    },
    isBranchManager: () => {
        let user = {};
        if (localStorage.getItem('user')) {
            user = JSON.parse(localStorage.getItem('user'));
        }
        if (!_.isEmpty(user)) {
            return (!_.isUndefined (user.roles?.find(
                role => role.name.toLowerCase().trim() == 'branch manager',
            )) 
            ) 
        }
        return false;
    },
};

export const actions = {
    async fetchUsers({ commit }) {
        const users = await userService.getAll();
        commit('setUsers', users);
    },
};

export const mutations = {
    setUsers: (state, users) => {
        state.users = users;
    },
    setLastGpsCoordinates: (state, gpsCoords) => {
        state.gpsCoords = gpsCoords;
    },
};
