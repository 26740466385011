import axios from '../helpers/api';
import db from '@/services/pouchDb';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import helper from '../helpers/custom';
import { DateTime } from 'luxon';
const user = JSON.parse(localStorage.getItem('user'));

export const vehicleService = {
    getAvailableVehicleFeatures,
    getAvailableVehicleAttributes,
    getAvailableVehicleCategories,
    getVehicleAttributesById,
    getVehicleFeaturesById,
    insertVehicle,
    getAllVehicles,
    getVehicleById,
    getVehiclesByStatus,
    updateVehicle,
    deleteVehicle,
    createVehicleRepossession,
    getVehicleRepossessions,
    getVehicleRepossessionsById,
    getVehicleRepossessionsDispatchById,
    updateVehicleRepossessionDispatchStatus,
    updateVehicleRepossessionStatus,
    updateVehicleRepossession,
    deleteVehicleRepossessions,
    deleteVehiclePhoto,
    updateVehicleRepossessionPublishStatus,
    updateVehiclePublicListingPhoto,
    getVehicleSightings,
    getVehicleSightingById,
    createVehicleSighting,
    updateVehicleSighting,
    deleteVehicleSightings,
    vehicleExists,
    getReposessionsByHero,
    getSpotters,
    getSpottersById,
    updateSpotters,
    getThirdParty,
    getThirdPartiesById,
    updateThirdParty,
    getVehicleByRegistrationNumber,
    insertBluebook,
    getBluebook,
    getBluebookId,
    UpdateBluebook
};

async function getAllVehicles(page, size, sortField, sortOrder, search) {
    page = page || 1;
    size = size || 10000;
    sortField = sortField || 'dateCreated';
    sortOrder = sortOrder || 'DESC';
    search = search || '';
    search = search.toUpperCase().trim();

    if (navigator.onLine) {
        const res = await axios.get(
            `/api/vehicles/?page=${page}&size=${size}&sortField=${sortField}&sortOrder=${sortOrder}&search=${search}`
        );
        return res.data;
    } else {
        if (!db.userDB || _.isEmpty(db.userDB)) {
            db.userDB = await db.refreshUserDb();
        }
        const { rows } = await db.userDB.allDocs({
            include_docs: true,
            attachments: true,
        });
        const vehicles = [];
        rows.forEach(row => {
            if (row.doc.vehicle) {
                vehicles.push(row.doc.vehicle);
            }
        });
        return {
            result: {
                success: true,
                message: 'Vehicles retrieved successfully',
            },
            meta: {
                paging: {
                    pageNumber: page,
                    pageSize: size,
                    total: vehicles.length,
                },
            },
            data: {
                vehicles,
            },
        };
    }
}
async function getVehiclesByStatus(page, size, sortField, sortOrder, search, status) {
    page = page || 1;
    size = size || 10000;
    sortField = sortField || 'dateCreated';
    sortOrder = sortOrder || 'DESC';
    search = search || '';
    search = search.toUpperCase().trim();

    
    if (navigator.onLine) {
        const res = await axios.get(
            `/api/vehicles/?page=${page}&size=${size}&sortField=${sortField}&sortOrder=${sortOrder}&search=${search}&status=${status}`
        );
        return res.data;
    } else {
        if (!db.userDB || _.isEmpty(db.userDB)) {
            db.userDB = await db.refreshUserDb();
        }
        const { rows } = await db.userDB.allDocs({
            include_docs: true,
            attachments: true,
        });
        const vehicles = [];
        rows.forEach((row) => {
            if (row.doc.vehicle) {
                vehicles.push(row.doc.vehicle);
            }
        });
        return {
            result: {
                success: true,
                message: 'Vehicles retrieved successfully',
            },
            meta: {
                paging: { pageNumber: page, pageSize: size, total: vehicles.length },
            },
            data: {
                vehicles,
            },
        };
    }
}
async function getVehicleRepossessions(page, size, sortField, sortOrder, search) {
    page = page || 1;
    size = size || 10000;
    sortField = sortField || 'dateCreated';
    sortOrder = sortOrder || 'DESC';
    search = search || '';
    search = search.toUpperCase().trim();

    if (navigator.onLine) {
        const res = await axios.get(
            `/api/vehicles/repossessions/${user.Id}?page=${page}&size=${size}&sortField=${sortField}&sortOrder=${sortOrder}&search=${search}`
        );

        return res.data;
    } else {
        if (!db.userDB || _.isEmpty(db.userDB)) {
            db.userDB = await db.refreshUserDb();
        }

        const { rows } = await db.userDB.allDocs({
            include_docs: true,
            attachments: true,
        });
        const repossessions = [];
        rows.forEach(row => {
            if (row.doc.vehicleRepossession) {
                repossessions.push(row.doc.vehicleRepossession);
            } else if (row.doc.vehicleRepossesion) {
                repossessions.push(row.doc.vehicleRepossesion);
            }

        });

        return {
            result: {
                success: true,
                message: 'Vehicle repossessions retrieved successfully',
            },
            meta: {
                paging: {
                    pageNumber: page,
                    pageSize: size,
                    total: repossessions.length,
                },
            },
            data: {
                repossessions,
            },
        };
    }
}

async function getVehicleByRegistrationNumber(number) {
    if (navigator.onLine) {
        const res = await axios.get(`/api/vehicles/regNumber/${number}`);
        return res.data;
    }
    else {
        console.debug("No offline capability yet");
        return;
    }
}

async function getReposessionsByHero(sortField, sortOrder, search) {
    sortField = sortField || 'hero';
    sortOrder = sortOrder || 'DESC';
    search = search || '';
    search = search.toUpperCase().trim();



    if (navigator.onLine) {

        const res = await axios.get(
            `/api/vehicles/repossessions/public-listing`
        );

        return res.data;
    } else {
        if (!db.userDB || _.isEmpty(db.userDB)) {
            db.userDB = await db.refreshUserDb();
        }
        const { rows } = await db.userDB.allDocs({
            include_docs: true,
            attachments: true,
        });

        const repossessions = [];
        rows.forEach(row => {
            if (row.doc.vehicleRepossession) {
                repossessions.push(row.doc.vehicleRepossession);
            }
            else if (row.doc.vehicleRepossesion) {
                repossessions.push(row.doc.vehicleRepossesion);
            }

        });
        return {
            result: {
                success: true,
                message: 'Highlighted vehicle repossessions retrieved successfully',
            },
            meta: {
            },
            data: {
                repossessions,
            },
        };
    }
}

async function getVehicleSightings(page, size, sortField, sortOrder, search) {
    page = page || 1;
    size = size || 10000;
    sortField = sortField || 'dateCreated';
    sortOrder = sortOrder || 'DESC';
    search = search || '';
    search = search.toUpperCase().trim();

    if (navigator.onLine) {
        const res = await axios(
            `/api/vehicles/sightings?page=${page}&size=${size}&sortField=${sortField}&sortOrder=${sortOrder}&search=${search}`
        );
        return res.data;
    } else {
        if (!db.userDB || _.isEmpty(db.userDB)) {
            db.userDB = await db.refreshUserDb();
        }
        const { rows } = await db.userDB.allDocs({
            include_docs: true,
            attachments: true,
        });
        const sightings = [];
        rows.forEach(row => {
            if (row.doc.vehicleSighting) {
                sightings.push(row.doc.vehicleSighting);
            }
        });
        return {
            result: {
                success: true,
                message: 'Vehicle sightings retrieved successfully',
            },
            meta: {
                paging: {
                    pageNumber: page,
                    pageSize: size,
                    total: sightings.length,
                },
            },
            data: {
                sightings,
            },
        };
    }
}

async function getVehicleById(id) {
    if (navigator.onLine) {
        const res = await axios(`/api/vehicles/details/${id}`);
        return res.data;
    } else {
        if (!db.userDB || _.isEmpty(db.userDB)) {
            db.userDB = await db.refreshUserDb();
        }
        const res = await db.userDB.allDocs({
            include_docs: true,
            attachments: true,
        });
        let response = {};
        res.rows.forEach(row => {
            if (row.doc.vehicle !== undefined && row.doc.vehicle.Id === id) {
                response = {
                    data: { vehicle: row.doc.vehicle },
                    result: {
                        success: true,
                        message: 'Vehicle retrieved successfully.',
                    },
                };
            }
        });
        return response;
    }
}

async function getVehicleRepossessionsById(id) {
    if (navigator.onLine) {
        // console.debug(data)

        const res = await axios(`/api/vehicles/repossession/${id}`);
        return res.data;
    } else {
        if (!db.userDB || _.isEmpty(db.userDB)) {
            db.userDB = await db.refreshUserDb();
        }
        const res = await db.userDB.allDocs({
            include_docs: true,
            attachments: true,
        });
        let response = {};
        res.rows.forEach(row => {
            if (
                row.doc.vehicleRepossession !== undefined &&
                row.doc.vehicleRepossession.Id === id
            ) {
                response = {
                    data: { repossession: row.doc.vehicleRepossession },
                    result: {
                        success: true,
                        message: 'Vehicle repossession retrieved successfully.',
                    },
                };
            } else if (
                row.doc.vehicleRepossesion !== undefined &&
                row.doc.vehicleRepossesion.Id === id
            ) {
                response = {
                    data: { repossession: row.doc.vehicleRepossesion },
                    result: {
                        success: true,
                        message: 'Vehicle repossession retrieved successfully.',
                    },
                };
            }
        });
        return response;
    }
}

async function getVehicleRepossessionsDispatchById(id) {
    if (navigator.onLine) {
        const res = await axios(`/api/vehicles/repossessionsdispatch/${id}`);
        return res.data;
    } else {
        if (!db.userDB || _.isEmpty(db.userDB)) {
            db.userDB = await db.refreshUserDb();
        }
        const res = await db.userDB.allDocs({
            include_docs: true,
            attachments: true,
        });
        let response = {};
        res.rows.forEach(row => {
            if (
                row.doc.vehicleRepossession !== undefined &&
                row.doc.vehicleRepossessionDispatch.Id === id
            ) {
                response = {
                    data: { vehicleRepossession: row.doc.vehicleRepossession },
                    result: {
                        success: true,
                        message:
                            'Vehicle repossession dispatch retrieved successfully.',
                    },
                };
            }
        });
        return response;
    }
}

async function getVehicleSightingById(id) {
    if (navigator.onLine) {
        const res = await axios(`/api/vehicles/sightings/${id}`);
        return res.data;
    } else {
        if (!db.userDB || _.isEmpty(db.userDB)) {
            db.userDB = await db.refreshUserDb();
        }
        const res = await db.userDB.allDocs({
            include_docs: true,
            attachments: true,
        });
        let response = {};
        res.rows.forEach(row => {
            if (
                row.doc.vehicleSighting !== undefined &&
                row.doc.vehicleSighting.Id === id
            ) {
                response = {
                    data: { vehicleSighting: row.doc.vehicleSighting },
                    result: {
                        success: true,
                        message: 'Vehicle sighting retrieved successfully.',
                    },
                };
            }
        });
        return response;
    }
}

async function getVehicleAttributesById(id) {
    if (navigator.onLine) {
        const res = await axios(`/api/vehicles/attributes/${id}`);
        return res.data;
    } else {
        if (!db.userDB || _.isEmpty(db.userDB)) {
            db.userDB = await db.refreshUserDb();
        }
        const res = await db.userDB.allDocs({
            include_docs: true,
            attachments: true,
        });
        let response = {};
        res.rows.forEach(row => {
            if (
                row.doc.vehicleAttribute !== undefined &&
                row.doc.vehicleAttribute.Id === id
            ) {
                response = {
                    data: { vehicleAttribute: row.doc.vehicleAttribute },
                    result: {
                        success: true,
                        message: 'Vehicle attribute retrieved successfully.',
                    },
                };
            }
        });
        return response;
    }
}

async function getVehicleFeaturesById(id) {
    const res = await axios(`/api/vehicles/features/${id}`);
    return res.data;
}

async function getSpotters(page, size, sortField, sortOrder, search) {
    page = page || 1;
    size = size || 10000;
    sortField = sortField || '';
    sortOrder = sortOrder || 'DESC';
    search = search || '%%';
    const res = await axios.get(
        `/api/vehicles/agent?page=${page}&size=${size}&sortField=${sortField}&sortOrder=${sortOrder}&search=${search}`,
    );
    return res.data;
}


async function getSpottersById(id) {
    try {
        if (navigator.onLine) {
            const res = await axios(`/api/vehicles/agent/${id}`);
            console.debug('Online: HTTP Request Successful');
            console.debug('Response data:', res.data);
            return res.data;
        } else {
            if (!db.userDB || _.isEmpty(db.userDB)) {
                db.userDB = await db.refreshUserDb();
                console.debug('Offline: Refreshed User Database');
            }
            const res = await db.userDB.allDocs({
                include_docs: true,
                attachments: true,
            });
            let response = {};
            res.rows.forEach(row => {
                if (row.doc.spotters !== undefined && row.doc.spotters.Id === id) {
                    response = {
                        data: { spotters: row.doc.spotters },
                        result: {
                            success: true,
                            message: 'Spotter retrieved successfully.',
                        },
                    };
                    console.debug('Offline: Found Spotter in Local Database');
                }
            });
            console.debug('Offline: Completed Local Database Search');
            return response;
        }
    } catch (error) {
        console.error('Error in getSpottersById:', error);
        throw error; 
    }
}


async function getThirdParty(page, size, sortField, sortOrder, search) {
    page = page || 1;
    size = size || 10000;
    sortField = sortField || '';
    sortOrder = sortOrder || 'DESC';
    search = search || '%%';
    const res = await axios.get(
        `/api/vehicles/third-party?page=${page}&size=${size}&sortField=${sortField}&sortOrder=${sortOrder}&search=${search}`,
    );
    return res.data;
}

async function getThirdPartiesById(id) {
    try {
        if (navigator.onLine) {
            const res = await axios(`/api/vehicles/third-party/${id}`);
            console.debug('Online: HTTP Request Successful');
            console.debug('Response data:', res.data);
            return res.data;
        } else {
            if (!db.userDB || _.isEmpty(db.userDB)) {
                db.userDB = await db.refreshUserDb();
                console.debug('Offline: Refreshed User Database');
            }
            const res = await db.userDB.allDocs({
                include_docs: true,
                attachments: true,
            });
            let response = {};
            res.rows.forEach(row => {
                if (row.doc.spotters !== undefined && row.doc.spotters.Id === id) {
                    response = {
                        data: { spotters: row.doc.spotters },
                        result: {
                            success: true,
                            message: 'third party retrieved successfully.',
                        },
                    };
                    console.debug('Offline: Found third party in Local Database');
                }
            });
            console.debug('Offline: Completed Local Database Search');
            return response;
        }
    } catch (error) {
        console.error('Error in getThirdPartiesById:', error);
        throw error;
    }
}

async function updateThirdParty(data) {
    if (navigator.onLine) {
        const res = await axios.put(`/api/vehicles/third-party/${data.Id}`, data);
        return res.data;
    } else {
        await db.userDB.put({
            _Id: data.Id,
            spotters: data,
        },
            { force: true },
        );
        let changeId = uuidv4();
        await db.globalsDb.put({
            _id: changeId,
            change: {
                entityId: data.Id,
                userId: helperNameMap.getUserId(),
                entityType: 'third-party',
                dateModified: DateTime.now().toISO(),
                changeType: 'UPDATE',
            },
        });
        return {
            result: {
                success: true,
                message: 'Third party updated successfully',
            },
            data: {},
        };
    }

}


/** Function to insert a new vehicle.
 *  this only inserts a vehicle.
 */
async function insertVehicle(data) {
    if (navigator.onLine) {
        const res = await axios.post('/api/vehicles', data);
        return res.data;
    } else {
        if (!db.userDB || _.isEmpty(db.userDB)) {
            db.userDB = await db.refreshUserDb();
        }
        await db.userDB.put({
            _id: data.id,
            vehicle: data,
        });

        let changeId = uuidv4();
        await db.globalsDB.put({
            _id: changeId,
            change: {
                entityId: data.Id,
                userId: helper.getUserId(),
                entityType: 'vehicle',
                dateModified: DateTime.now().toISO(),
                changeType: 'INSERT',
            },
        });
        return {
            result: {
                success: true,
                message: 'Vehicle updated successfully',
            },
            data: {},
        };
    }
}

async function updateVehicle(data) {
    if (navigator.onLine) {
        try {
            const res = await axios.put(`/api/vehicles/${data.id}`, data);
            return res.data;
        } catch (error) {
        }
    } else {
        if (!db.userDB || _.isEmpty(db.userDB)) {
            db.userDB = await db.refreshUserDb();
        }
        await db.userDB.put(
            {
                _id: data.id,
                activity: data,
            },
            { force: true },
        );
        return {
            result: {
                success: true,
                message: 'Vehicle updated successfully',
            },
            data: {},
        };
    }
}

async function createVehicleRepossession(data) {
    if (navigator.onLine) {
        // console.debug(data)
        const res = await axios.post(`/api/vehicles/repossessions`, data);
        return res.data;
    } else {
        await db.userDB.put({
            _id: data.Id,
            vehicleRepossession: data,
        });

        let changeId = uuidv4();
        await db.globalsDB.put({
            _id: changeId,
            change: {
                entityId: data.Id,
                userId: helper.getUserId(),
                entityType: 'vehicleRepossession',
                dateModified: DateTime.now().toISO(),
                changeType: 'INSERT',
            },
        });

        return {
            result: {
                success: true,
                message: 'Vehicle created successfully',
            },
            data: {},
        };
    }
}

async function updateVehicleRepossession(data) {
    if (navigator.onLine) {
        const res = await axios.put(
            `/api/vehicles/repossessions/${data.id}`,
            data,
        );
        return res.data;
    } else {
        await db.userDB.put(
            {
                _id: data.id,
                vehicleRepossession: data,
            },
            { force: true },
        );
        let changeId = uuidv4();
        await db.globalsDB.put({
            _id: changeId,
            change: {
                entityId: data.Id,
                userId: helper.getUserId(),
                entityType: 'vehicleRepossession',
                dateModified: DateTime.now().toISO(),
                changeType: 'UPDATE',
            },
        });
        return {
            result: {
                success: true,
                message: 'Vehicle repossession updated successfully',
            },
            data: {},
        };
    }
}

async function createVehicleSighting(data) {
    if (navigator.onLine) {
        const res = await axios.post(`/api/vehicles/sightings`, data);
        return res.data;
    } else {
        await db.userDB.put({
            _id: data.Id,
            vehicleSighting: data,
        });

        let changeId = uuidv4();
        await db.globalsDB.put({
            _id: changeId,
            change: {
                entityId: data.Id,
                userId: helper.getUserId(),
                entityType: 'vehicleSighting',
                dateModified: DateTime.now().toISO(),
                changeType: 'INSERT',
            },
        });

        return {
            result: {
                success: true,
                message: 'Vehicle sighting created successfully',
            },
            data: {},
        };
    }
}

async function updateVehicleSighting(data) {
    if (navigator.onLine) {
        const res = await axios.put(`/api/vehicles/sightings/${data.id}`, data);
        return res.data;
    } else {
        await db.userDB.put(
            {
                _id: data.id,
                vehicleSighting: data,
            },
            { force: true },
        );
        let changeId = uuidv4();
        await db.globalsDB.put({
            _id: changeId,
            change: {
                entityId: data.Id,
                userId: helper.getUserId(),
                entityType: 'vehicleSighting',
                dateModified: DateTime.now().toISO(),
                changeType: 'UPDATE',
            },
        });
        return {
            result: {
                success: true,
                message: 'Vehicle sighting updated successfully',
            },
            data: {},
        };
    }
}

async function updateVehicleRepossessionDispatchStatus(data) {
    if (navigator.onLine) {
        const res = await axios.put(
            '/api/vehicles/repossessions-dispatch-status',
            {
                dispatchId: data.dispatchId,
                success: data.success,
            },
        );
        return res.data;
    } else {
        await db.userDB.put(
            {
                _id: data.id,
                vehicleRepossessionDispatchStatus: data,
            },
            { force: true },
        );
        let changeId = uuidv4();
        await db.globalsDB.put({
            _id: changeId,
            change: {
                entityId: data.Id,
                userId: helper.getUserId(),
                entityType: 'vehicleRepossessionDispatchStatus',
                dateModified: DateTime.now().toISO(),
                changeType: 'UPDATE',
            },
        });
        return {
            result: {
                success: true,
                message: 'Vehicle updated successfully',
            },
            data: {},
        };
    }
}

async function updateVehicleRepossessionStatus(data) {
    if (navigator.onLine) {
        const res = await axios.put('/api/vehicles/repossessions-status', {
            id: data.id,
            status: data.status,
        });
        return res.data;
    } else {
        await db.userDB.put(
            {
                _id: data.id,
                vehicleRepossessionStatus: data,
            },
            { force: true },
        );
        let changeId = uuidv4();
        await db.globalsDB.put({
            _id: changeId,
            change: {
                entityId: data.Id,
                userId: helper.getUserId(),
                entityType: 'vehicleRepossessionStatus',
                dateModified: DateTime.now().toISO(),
                changeType: 'UPDATE',
            },
        });
        return {
            result: {
                success: true,
                message: 'Vehicle updated successfully',
            },
            data: {},
        };
    }
}

async function updateVehicleRepossessionPublishStatus(data) {
    const res = await axios.put('/api/vehicles/repossessions-publish', {
        rewardId: data.id,
        success: data.success,
    });
    return res.data;
}

async function updateVehiclePublicListingPhoto(data) {
    const res = await axios.put(
        '/api/vehicles/repossessions-publiclistingphoto',
        {
            repossessionId: data.repossessionId,
            vehiclePhotoId: data.vehiclePhotoId,
        },
    );
    return res.data;
}

async function updateSpotters(data) {
    if (navigator.onLine) {
        const res = await axios.put(`/api/vehicles/agent/${data.Id}`, data);
        return res.data;
    } else {
        await db.userDB.put({
            _Id: data.Id,
            spotters: data,
        },
            { force: true },
        );
        let changeId = uuidv4();
        await db.globalsDb.put({
            _id: changeId,
            change: {
                entityId: data.Id,
                userId: helperNameMap.getUserId(),
                entityType: 'spotters',
                dateModified: DateTime.now().toISO(),
                changeType: 'UPDATE',
            },
        });
        return {
            result: {
                success: true,
                message: 'Vehicle spotters updated successfully',
            },
            data: {},
        };
    }

}

async function getAvailableVehicleFeatures() {
    const res = await axios('/api/vehicles/features');
    return res.data;
}

async function getAvailableVehicleAttributes() {
    const res = await axios('/api/vehicles/attributes');
    return res.data;
}

async function getAvailableVehicleCategories() {
    const res = await axios.get('/api/vehicles/categories');
    return res.data;
}

async function deleteVehicle(data) {
    if (navigator.onLine) {
        const res = await axios.post('/api/vehicles/delete', data);
        return res.data;
    } else {
        for (const id of data) {
            const doc = await db.userDB.get(data);
            await db.userDB.remove(doc);
            let changeId = uuidv4();
            await db.globalsDB.put({
                _id: changeId,
                change: {
                    entityId: id,
                    entityType: 'vehicle',
                    userId: helper.getUserId(),
                    dateModified: DateTime.now().toISO(),
                    changeType: 'DELETE',
                },
            });
        }
    }
}

async function deleteVehicleRepossessions(data) {
    if (navigator.onLine) {
        const res = await axios.post('/api/vehicles/repossessions-delete', data);
        return res.data;
    } else {
        for (const id of data) {
            const doc = await db.userDB.get(data);
            await db.userDB.remove(doc);
            let changeId = uuidv4();
            await db.globalsDB.put({
                _id: changeId,
                change: {
                    entityId: id,
                    entityType: 'vehicleRepossession',
                    userId: helper.getUserId(),
                    dateModified: DateTime.now().toISO(),
                    changeType: 'DELETE',
                },
            });
        }
    }
}

async function deleteVehicleSightings(data) {
    if (navigator.onLine) {
        const res = await axios.post('/api/vehicles/sightings-delete', {
            id: data,
        });
        return res.data;
    } else {
        for (const id of data) {
            const doc = await db.userDB.get(data);
            await db.userDB.remove(doc);
            let changeId = uuidv4();
            await db.globalsDB.put({
                _id: changeId,
                change: {
                    entityId: id,
                    entityType: 'vehicleSighting',
                    userId: helper.getUserId(),
                    dateModified: DateTime.now().toISO(),
                    changeType: 'DELETE',
                },
            });
        }
    }
}
async function deleteVehiclePhoto(data) {
    if (navigator.onLine) {
        const res = await axios.post('/api/vehicles/photo-delete', data);
        return res.data;
    } else {
        for (const Id of data) {
            // console.debug: need to check this
            const doc = await db.userDB.get(data);
            await db.userDB.remove(doc);
            let changeId = uuidv4();
            await db.globalsDB.put({
                _id: changeId,
                change: {
                    entityId: Id,
                    entityType: 'vehiclePhoto',
                    userId: helper.getUserId(),
                    dateModified: DateTime.now().toISO(),
                    changeType: 'DELETE',
                },
            });
        }
    }
}

async function vehicleExists(
    registrationNumber,
    engineNumber,
    vehicleIdentificationNumber,
) {
    if (navigator.onLine) {
        try {
            const res = await axios.post('/api/vehicles/exist', {
                registrationNumber,
                engineNumber,
                vehicleIdentificationNumber,
            });
            return res.data;
        } catch (error) {
            return error;
        }
    } else {
        return {
            success: false,
        };
    }
}
async function insertBluebook(data) {
    if (navigator.onLine) {
        const res = await axios.post(`/api/vehicles/bluebook`, data);
        return res.data;
    } else {
        if (!db.userDB || _.isEmpty(db.userDB)) {
            db.userDB = await db.refreshUserDb();
        }
        await db.userDB.put({
            _id: data.id,
            vehicleBluebook: data,
        });

        let changeId = uuidv4();
        await db.globalsDB.put({
            _id: changeId,
            change: {
                entityId: data.Id,
                userId: helper.getUserId(),
                entityType: 'vehicleBluebook',
                dateModified: DateTime.now().toISO(),
                changeType: 'INSERT',
            },
        });
        return {
            result: {
                success: true,
                message: 'Vehicle updated successfully',
            },
            data: {},
        };
    }
}

async function getBluebook(page, size, sortField, sortOrder, search) {
    page = page || 1;
    size = size || 10000;
    sortField = sortField || '';
    sortOrder = sortOrder || 'DESC';
    search = search || '%%';
    const res = await axios.get(
        `/api/vehicles/bluebook?page=${page}&size=${size}&sortField=${sortField}&sortOrder=${sortOrder}&search=${search}`,
    );
    return {
        result: {
            success: true,
            message: 'Vehicle bluebook retrieved successfully'
        },
        meta: {
            meta: {
                paging: {
                    pageNumber: page,
                    pageSize: size,
                    total: vehicles.length,
                },
            },
        },
        data: {
            bluebooks: res.data
        }
    };
}


// async function getBluebook(page = 1, size = 10000, sortField = 'make', sortOrder = 'ASC', search = '') {
//     if (navigator.onLine) {
//         const { data } = await axios.get(`/api/vehicles/bluebook`, {
//             params: {
//                 page,
//                 size,
//                 sortField,
//                 sortOrder,
//                 search,
//             },
//         });
//         return data;
//     } else {
//         if (!db.userDB || _.isEmpty(db.userDB)) {
//             db.userDB = await db.refreshUserDb();
//         }
//         const { rows } = await db.userDB.allDocs({
//             include_docs: true,
//             attachments: true,
//         });
//         const vehicles = rows
//             .filter(row => row.doc.vehicle)
//             .map(row => row.doc.vehicle);

//         return {
//             result: {
//                 success: true,
//                 message: 'Bluebook retrieved successfully',
//             },
//             meta: {
//                 paging: {
//                     pageNumber: page,
//                     pageSize: size,
//                     total: vehicles.length,
//                 },
//             },
//             data: {
//                 vehicles,
//             },
//         };
//     }
// }

async function getBluebookId(id) {
    if (navigator.onLine) {
        const res = await axios(`/api/vehicles/bluebook/${id}`);
        return res.data;
    } else {
        if (!db.userDB || _.isEmpty(db.userDB)) {
            db.userDB = await db.refreshUserDb();
        }
        const res = await db.userDB.allDocs({
            include_docs: true,
            attachments: true,
        });
        let response = {};
        res.rows.forEach(row => {
            if (row.doc.vehicles !== undefined && row.doc.vehicles.Id === id) {
                response = {
                    data: { vehicles: row.doc.vehicles },
                    result: {
                        success: true,
                        message: 'Bluebook retrieved successfully.',
                    },
                };
            }
        });
        return response;
    }
}

async function UpdateBluebook(data) {
    if (navigator.onLine) {
        const res = await axios.put(`/api/vehicles/bluebook/${data.Id}`, data);
        return res.data;
    } else {
        await db.userDB.put({
            _Id: data.Id,
            vehicles: data,
        },
            { force: true },
        );
        let changeId = uuidv4();
        await db.globalsDb.put({
            _id: changeId,
            change: {
                entityId: data.Id,
                userId: helperNameMap.getUserId(),
                entityType: 'bluebook',
                dateModified: DateTime.now().toISO(),
                changeType: 'UPDATE',
            },
        });
        return {
            result: {
                success: true,
                message: 'Vehicle bluebook updated successfully',
            },
            data: {},
        };
    }

}
