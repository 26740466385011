import { permissionsService } from '../../services/permissions.service';

export const state = {
    permissions: [],
};

export const getters = {
    getPermissions: state => {
        return state.permissions;
    },
};

export const actions = {
    async fetchPermissions({ commit }, Id) {
        const permissions = await permissionsService.getPermissionsByUserId(Id);
        commit('setPermissions', permissions);
    },
};

export const mutations = {
    setPermissions: (state, permissions) => {
        state.permissions = permissions;
    },
};
