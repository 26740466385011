import GeoServer from '../../services/geo.command.service';
// import GeoServer from '../../helpers/geoserver/GeoServer';

// initial state
export const state = () => ({
    allCommands: [],
});

// getters
export const getters = {
    getCommand: state => id => {
        //console.log('Looking for ', id, state.allCommands.length);
        return state.allCommands.find(command => command.id == id);
    },
};

// actions
export const actions = {
    // async getAllCommands({ commit }) {
    //     const { data: temp, meta } = await GeoServer.getAllCommands();
    //     const MaxNum = 1000;
    //     const data = temp.commands.slice(0, MaxNum);

    //     commit('setCommands', { data, meta });
    // },
    async getAllCommands({ commit }) {
        const { rows: geoCommands } = await GeoServer.getAllCommands();
        commit('setCommands', geoCommands);
        return;
    },
    async getCommandsCash({ commit, getters }, commandsID) {
        const dev = getters.getCommand(commandsID);
        if (!dev) {
            const devDB = await GeoServer.getCommand(commandsID);
            commit('addCommands', [devDB]);
            return devDB;
        }
        return dev;
    },
    async createCommand({ commit }, newCommandObj) {
        const command = await GeoServer.createCommand(newCommandObj);
        commit('addCommands', [command]);
    },
    async updateCommandAsync({ commit }, updateCommandObj) {
        const command = await GeoServer.updateCommand(updateCommandObj.id, updateCommandObj);
        commit('updateCommand', [command, updateCommandObj.id]);
    },
    async deleteCommandAsync({ commit }, deleteCommandObj) {
        const command = await GeoServer.deleteCommand(deleteCommandObj.id, deleteCommandObj);
        commit('deleteCommand', [command, deleteCommandObj.id]);
    },
};

// mutations
export const mutations = {
    setCommands(state, commands) {
        state.allCommands = commands;
    },
    addCommands(state, commands) {
        commands.forEach(g => {
            state.allCommands.push(g);
        });
    },
    updateCommand(state, args) {
        const idx = state.allCommands.findIndex(command => command.id == args[1]);
        if (idx != -1) {
            state.allCommands[idx] = args[0];
        } else {
            state.allCommands.push(args[0]);
        }
    },
    deleteCommand(state, args) {
        const idx = state.allCommands.findIndex(command => command.id == args[1]);
        if (idx != -1) {
            state.allCommands.splice(idx, 1);
        }
    },
};

// export default {
//   namespaced: true,
//   state,
//   getters,
//   actions,
//   mutations
// }
